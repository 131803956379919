import React from "react";
import { useSelector } from "react-redux";

import FundraiserHeader from "../common/FundraiserHeader";
import Loader from "../../common/Loader";
import { ReactComponent as FailureImg } from "../../../Assets/Images/subscrptionFailureImg.svg";

const SubscriptionFailure = () => {
  const loading = useSelector((state) => state.subscriptionReducer.loading);

  return (
    <div className="w-full h-full">
      <FundraiserHeader />
      {loading && <Loader />}
      {!loading && (
        <>
          <div className="py-10 h-[90vh] flex flex-col items-center justify-center gap-0 md:gap-6 lg:gap-12">
            <FailureImg className="w-[270px] h-[220px] sm:w-[55%] sm:h-[55%]"/>
            <div className="font-poppins font-[600] text-[14px] sm:text-[16px] md:text-[18px] flex flex-col items-center justify-center">
              <p className="text-[#000000]">Something‘s gone wrong</p>
              <p className="font-[400] text-[#8C8C8C] text-center w-[90%] sm:w-[100%]">Unable to process the payment because of the subscription failed. </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SubscriptionFailure;
