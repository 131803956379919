import React, {useState, useEffect} from 'react'
import TimelineCircleInActive from "../../../../Assets/Images/Timeline-Circle-InActive.svg"
import TimelineCircleActive from "../../../../Assets/Images/Timeline-Circle-Active.svg"
import CircleActive from "../../../../Assets/Images/Circle-Active.svg"
import CircleInActive from "../../../../Assets/Images/Circle-InActive.svg"

const TimelineSidebar = ({titles}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className='bg-[#FFFFFF] sm:w-[30%] md:w-[20%] lg:w-[20%] xl:w-[20%]'>
      <div className='mt-[20%] ml-[8%]'>
        {titles?.map((title, index) => 
        <div className='flex justify-start' key={index}>
          <div className='flex flex-col mt-[2%]'>
            <img src={title?.completed == true ? TimelineCircleActive : TimelineCircleInActive}/>
            {index!== titles?.length -1 ? <div className={`lg:w-[2px] sm:w-[1px] h-[20px] ${title?.completed === true ? 'bg-[#00ADEF]': 'bg-[#8C8C8C]'} sm:ml-[0.45rem] my-1`}></div>: ''}
          </div>
          <span className={`ml-[8%] md:text-[12px] lg:text-[16px] font-[400] ${title?.completed == true ? 'text-[#00ADEF]': 'text-[#8C8C8C]'} `}>{title?.name}</span>
        </div>)}
      </div>
    </div>
  )
}

export default TimelineSidebar;