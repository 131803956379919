import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./redemptionform.scss";
import BreadCrumsHeader from "../../common/BreadCrums";
import FormLayout from "../../common/FormLayout";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { redemptionSchema } from "../../../utils/validationSchemas";
import Loader from "../../common/Loader";
import PrimaryButton from "../../common/PrimaryButton";
import Input from "../../common/FormInputs";
import { ReactComponent as RefreshIcon } from "../../../Assets/Icons/refreshIcon.svg";
import { redeemOffer } from "../../../services/merchantService";
import GenericPopup from "../../common/GenericPopup";
import PrimaryOutlineButton from "../../common/PrimaryOutlineButton";
import cautionIcon from "../../../Assets/Icons/cautionIcon.svg";

const RedemptionFormPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.merchantReducer?.loading);
  const [verifyClicked, setVerifyClicked] = useState(false);
  const [invalidCode, setInvalidCode] = useState(false);
  const [offer, setOffer] = useState(null);

  const formik = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: redemptionSchema,
    onSubmit: async (values) => {
      const redeemPromise = await dispatch(
        redeemOffer({ code: values?.code?.toUpperCase() })
      )?.unwrap();
      if (redeemPromise?.success === true) {
        setVerifyClicked(true);
        setOffer(redeemPromise?.data?.offer);
      } else {
        setInvalidCode(true);
      }
    },
  });

  const { code } = formik.values;

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    setValues,
    setFieldTouched,
  } = formik;

  const items = [
    {
      title: "Redemption",
    },
    {
      title: "Redemption Code",
    },
  ];

  const handleRefreshClick = () => {
    setVerifyClicked(false);
    setFieldTouched("code", false);
    setFieldValue("code", "");
  };

  return (
    <>
      {loading === true && <Loader />}
      {invalidCode === true && (
        <GenericPopup
          icon={cautionIcon}
          content={
            "The redemption code is either expired or is invalid. Please try again with a valid code."
          }
          popupClassName={"w-[335px] sm:w-[470px] pb-8"}
          continueBtn={
            <PrimaryOutlineButton
              label={"Close"}
              className={
                "sm:w-[30%] md:w-[35%] lg:w-[35%] font-poppins sm:text-[12px] md:text-[14px] text-center font-[500] sm:h-[35px] md:h-[40px] rounded-[10px] mt-5"
              }
              onClick={() => setInvalidCode(false)}
            />
          }
        />
      )}
      <BreadCrumsHeader heading="Redemption Code" items={items} form={true} />
      <FormLayout
        title={""}
        content={
          <form className="flex justify-center items-center w-full" onSubmit={handleSubmit}>
            <div className="flex flex-col justify-center items-center w-full md:w-[80%] lg:w-[60%] xl:w-[50%]">
              {verifyClicked && (
                <>
                  <div className="w-[80%] h-[164px]">
                    <img
                      src={offer?.thumbnailImage}
                      className="w-full h-full object-contain"
                    />
                  </div>
                  <p className="font-poppins font-[600] text-[18px] items-center text-[#00ADEF] my-4">
                    {offer?.category?.title}
                  </p>
                  <p className="font-poppins font-[600] text-[18px] items-center text-[#303030] mb-2">
                    {offer?.title}
                  </p>
                  <p className="font-poppins font-[400] text-[14px] items-center text-[#303030] text-center mb-4">
                    {offer?.description}
                  </p>
                </>
              )}
              <p className="font-poppins font-[400] text-[18px] items-center">
                Enter Redeem Code
              </p>
              {/* <div className="w-[40%] border border-[#D9D9D9] rounded-[5px] h-[50px]"></div> */}
              <div className="w-full relative">
                <Input
                  type="text"
                  label=""
                  placeholder=""
                  width="100%"
                  radius="5px"
                  inputClass="text-center text-transform: uppercase !font-[500] !text-[28px]"
                  InputDivClass="w-[100%] my-8 text-center"
                  name="code"
                  value={code}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  error={errors.code}
                  touched={touched.code}
                  readonly={verifyClicked === true ? true : false}
                />
                {verifyClicked && (
                  <RefreshIcon
                    className="absolute top-10 right-2 cursor-pointer"
                    onClick={handleRefreshClick}
                  />
                )}
              </div>
              <PrimaryButton
                type={"submit"}
                className={
                  "w-[40%] font-montserrat text-[14px] text-center font-[500] h-[45px] rounded-[5px]"
                }
                label={verifyClicked ? "Verified" : "Verify"}
                disabled={verifyClicked ? true : false}
              />
            </div>
          </form>
        }
      />
    </>
  );
};

export default RedemptionFormPage;
