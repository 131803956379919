import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import toast from "../../utils/toast";
import {
  createAsset,
  deleteAsset,
  editAsset,
  getAssets,
  toggleAssetStatus,
} from "../../services/assetsService";

const initialState = {
  createdAsset: null,
  allPromotions: null,
  toggledAsset: null,
  updatedAsset: null,
  deletedAsset: null,
  loading: false,
};

const assetsSlice = createSlice({
  name: "asset",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createAsset?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.createdAsset = action?.payload?.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(getAssets?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.allPromotions = action?.payload?.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(toggleAssetStatus?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.toggledAsset = action?.payload?.data;
        toast.success(action?.payload?.message);
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(editAsset?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.updatedAsset = action?.payload?.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(deleteAsset?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.deletedAsset = action?.payload?.data;
        toast.success(action?.payload?.data);
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder
      .addMatcher(
        isAnyOf(
          createAsset?.pending,
          getAssets?.pending,
          toggleAssetStatus?.pending,
          editAsset?.pending,
          deleteAsset?.pending
        ),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          createAsset.rejected,
          getAssets.rejected,
          toggleAssetStatus.rejected,
          editAsset.rejected,
          deleteAsset.rejected
        ),
        (state, action) => {
          state.loading = false;
          toast.error(action?.payload?.message);
        }
      );
  },
});

// export const {} = assetsSlice?.actions;
export default assetsSlice?.reducer;
