import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getCategories,
  createOffer,
  getOffers,
  deleteOffer,
  editOffer,
  toggleOfferStatus,
  createSubCategory,
  createRule,
  getRules,
  getOfferById,
  getNearestOffersByMerchant,
  getCategoryById,
  getOffersByMerchant,
  approveOrRejectResources,
  getNonDraftedOffers,
} from "../../services/offerService";
import toast from "../../utils/toast";

const initialState = {
  categories: null,
  createdOffer: null,
  offers: null,
  deletedOffer: null,
  updatedOffer: null,
  toggledOffer: null,
  newSubCategory: null,
  newRule: null,
  allRules: null,
  offerById: null,
  merchantDetails: null,
  categoryById: null,
  offersByMerchant: null,
  approveOrReject: null,
  nonDraftedoffers: null,
  loading: false,
};

const offerSlice = createSlice({
  name: "offer",
  initialState: initialState,
  reducers: {
    resetOfferState: (state, action) => {
      state.createdOffer = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCategories?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.loading = false;
        state.categories = action?.payload?.data;
      } else {
        state.loading = false;
        toast.error(action?.payload?.message);
      }
    });

    builder.addCase(createOffer?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.loading = false;
        state.createdOffer = action?.payload?.data;
      } else {
        state.loading = false;
        toast.error(action?.payload?.message);
      }
    });

    builder.addCase(getOffers?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.loading = false;
        state.offers = action?.payload?.data;
      } else {
        state.loading = false;
        toast.error(action?.payload?.message);
      }
    });

    builder.addCase(editOffer?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.loading = false;
        state.updatedOffer = action?.payload?.data;
      } else {
        state.loading = false;
        toast.error(action?.payload?.message);
      }
    });

    builder.addCase(deleteOffer?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.loading = false;
        state.deletedOffer = action?.payload?.data;
        toast.success(action?.payload?.data);
      } else {
        state.loading = false;
        toast.error(action?.payload?.message);
      }
    });

    builder.addCase(toggleOfferStatus?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.loading = false;
        state.toggledOffer = action?.payload?.data;
        toast.success(action?.payload?.message);
      } else {
        state.loading = false;
        toast.error(action?.payload?.message);
      }
    });

    builder.addCase(createSubCategory?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.loading = false;
        state.newSubCategory = action?.payload?.data;
      } else {
        state.loading = false;
        toast.error(action?.payload?.message);
      }
    });

    builder.addCase(createRule?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.loading = false;
        state.newRule = action?.payload?.data;
      } else {
        state.loading = false;
        toast.error(action?.payload?.message);
      }
    });

    builder.addCase(getRules?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.loading = false;
        state.allRules = action?.payload?.data;
      } else {
        state.loading = false;
        toast.error(action?.payload?.message);
      }
    });

    builder.addCase(getOfferById?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.loading = false;
        state.offerById = action?.payload?.data;
        toast.success(action?.payload?.data);
      } else {
        state.loading = false;
        toast.error(action?.payload?.message);
      }
    });

    builder.addCase(getNearestOffersByMerchant?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.loading = false;
        state.merchantDetails = action?.payload?.data;
        toast.success(action?.payload?.data);
      } else {
        state.loading = false;
        toast.error(action?.payload?.message);
      }
    });

    builder.addCase(getCategoryById?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.loading = false;
        state.categoryById = action?.payload?.data;
        toast.success(action?.payload?.data);
      } else {
        state.loading = false;
        toast.error(action?.payload?.message);
      }
    });

    builder.addCase(getOffersByMerchant?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.loading = false;
        state.offersByMerchant = action?.payload?.data;
        toast.success(action?.payload?.data);
      } else {
        state.loading = false;
        toast.error(action?.payload?.message);
      }
    });

    builder.addCase(approveOrRejectResources?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.loading = false;
        state.approveOrReject = action?.payload?.data;
        toast.success(action?.payload?.data);
      } else {
        state.loading = false;
        toast.error(action?.payload?.message);
      }
    });

    builder.addCase(getNonDraftedOffers?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.loading = false;
        state.nonDraftedoffers = action?.payload?.data;
        toast.success(action?.payload?.data);
      } else {
        state.loading = false;
        toast.error(action?.payload?.message);
      }
    });

    builder
      .addMatcher(
        isAnyOf(
          getCategories?.pending,
          createOffer?.pending,
          getOffers?.pending,
          editOffer?.pending,
          deleteOffer?.pending,
          toggleOfferStatus?.pending,
          createSubCategory?.pending,
          createRule?.pending,
          getRules?.pending,
          getOfferById?.pending,
          getNearestOffersByMerchant?.pending,
          getCategoryById?.pending,
          getOffersByMerchant?.pending,
          approveOrRejectResources?.pending,
          getNonDraftedOffers?.pending
        ),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          getCategories?.rejected,
          createOffer?.rejected,
          getOffers?.rejected,
          editOffer?.rejected,
          deleteOffer?.rejected,
          toggleOfferStatus?.rejected,
          createSubCategory?.rejected,
          createRule?.rejected,
          getRules?.rejected,
          getOfferById?.rejected,
          getNearestOffersByMerchant?.rejected,
          getCategoryById?.rejected,
          getOffersByMerchant?.rejected,
          approveOrRejectResources?.rejected,
          getNonDraftedOffers?.rejected
        ),
        (state, action) => {
          state.loading = false;
          toast.error(action?.payload?.message);
        }
      );
  },
});

export const { resetOfferState } = offerSlice?.actions;
export default offerSlice?.reducer;
