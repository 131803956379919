// LocationPicker.js
import React, { useEffect, useRef, useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import location from "../../Assets/Icons/Location.svg";
import { noop } from "antd/es/_util/warning";
import SearchBar from "./SearchBar";

const GoogleMaps = ({ onLocationSelect, searchContainerClass }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
    libraries: ["geometry", "drawing", "places"],
  });
  const [position, setPosition] = useState({ lat: 37.7749, lng: -122.4194 });
  const [searchInput, setSearchInput] = useState("");
  const [placeSelected, setPlaceSelected] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const mapRef = useRef(null);
  const placesService = useRef(null);
  const debounceTimerRef = useRef(null);

  const mapStyles = {
    height: "400px",
    width: "100%",
    borderRadius: "8px",
    marginTop: "0.5rem",
    position: "relative",
  };

  const handleMarkerDragEnd = async (e) => {

    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    clearTimeout(debounceTimerRef.current);
    debounceTimerRef.current = setTimeout(() => {
      setPosition({ lat, lng });

      // Get place information using the Google Places API
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ location: { lat, lng } }, (results, status) => {
        if (status === "OK" && results[0]) {
          onLocationSelect({
            lat,
            lng,
            placeId: results[0].place_id,
            placeName: results[0].formatted_address,
          });
        }
      });
    }, 1500);
  };

  const handleUserSearch = () => {
    const request = {
      input: searchInput,
      fields: ["place_id", "description", "structured_formatting", "terms", "types"],
    };
    const autocompleteService =
      new window.google.maps.places.AutocompleteService();
    autocompleteService.getPlacePredictions(
      request,
      (results, status) => {
        if (status === "OK" && results) {
          setSearchResults(results);
        }
      }
    );
  };

  const handlePlaceSelect = (place) => {
    const map = mapRef.current;
    if (map) {
      const service = new window.google.maps.places.PlacesService(map);
      service.getDetails(
        {
          placeId: place.place_id,
          fields: ["geometry", "formatted_address"],
        },
        (result, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            const { geometry, formatted_address } = result;
            if (geometry) {
              const { location } = geometry;
              map.panTo(location);
              setPosition({ lat: location.lat(), lng: location.lng() });
              onLocationSelect({
                lat: location.lat(),
                lng: location.lng(),
                placeId: place.place_id,
                placeName: formatted_address,
              });
              setSearchInput(formatted_address);
              setPlaceSelected(true);
              setSearchResults([]);
            }
          }
        }
      );
    }
  };

  const handleInputChange = (e) => {
    setSearchInput(e.target.value);
    setPlaceSelected(false);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleUserSearch();
    }
  };

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (placeSelected === false) {
        handleUserSearch();
      }
    }, 2000);
    return () => clearTimeout(debounceTimer);
  }, [searchInput, placeSelected]);

  return (
    <>
      {isLoaded && (
        <>
          <GoogleMap
            // mapContainerStyle={mapStyles}
            mapContainerClassName="h-[260px] sm:h-[400px] w-full rounded-[8px] mt-[0.5rem] relative"
            zoom={8}
            center={position}
            onLoad={(map) => {
              mapRef.current = map;
              placesService.current =
                new window.google.maps.places.PlacesService(map);
            }}
          >
            {position && (
              <Marker
                icon={{
                  url: location,
                  scaledSize: new window.google.maps.Size(40, 40),
                }}
                position={position}
                draggable={true}
                onDragEnd={handleMarkerDragEnd}
              />
            )}
            <SearchBar
              parentDivClass={`absolute zIndex-[1000] top-[10px] ${searchContainerClass}`}
              divClassName="w-full flex items-center h-[40px] relative"
              inputClassName="navSearchBar h-full w-[100%] ps-[4%] pe-[14%] text-[12px] font-[400] font-poppins outline-none bg-white text-[#303030]"
              iconClassName="absolute right-[5%]"
              placeholder="Search here"
              value={searchInput}
              handleChange={handleInputChange}
              handleKeyDown={handleKeyPress}
              searchResults={searchResults}
              handleResultClick={handlePlaceSelect}
            />
            {/* <div style={searchContainerStyles}>
              <input
                type="text"
                placeholder="Search for a location"
                value={searchInput}
                onChange={handleInputChange}
                onKeyDown={handleKeyPress}
                style={{
                  border: "1px solid #00ADEF",
                  outline: "none",
                  borderRadius: "5px",
                  padding: "5px"
                }}
              />
              {searchResults.map((result, index) => (
                <div
                  key={index}
                  onClick={() => handlePlaceSelect(result)}
                  className="cursor-pointer"
                  onmouseover="!bg-blue-100"
                >
                  {result?.structured_formatting?.main_text}{" "}
                </div>
              ))}
            </div> */}
          </GoogleMap>
        </>
      )}
    </>
  );
};

export default GoogleMaps;
