import React from "react";
import EditIcon from "../../Assets/Icons/Edit.svg";
import { Tooltip } from "antd";

const Edit = ({ onClick }) => {
  return (
    <Tooltip title={"edit offer"} color="#00ADEF">
      <div
        className="bg-[#00ADEF]/[20%] w-[25px] h-[25px] rounded-[100%] flex justify-center items-center cursor-pointer"
        style={{
          border: "0.3px solid #00ADEF",
          boxShadow: "0px 2px 2px 0px #EC826E29",
        }}
        onClick={onClick}
      >
        <img src={EditIcon}></img>
      </div>
    </Tooltip>
  );
};

export default Edit;
