import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const ProgressBarChart = ({ className, barThickness }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 2,
    plugins: {
      legend: {
        display: false,
        position: "top"
      },
      title: {
        display: false,
        text: "Chart.js Bar Chart",
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
          offset: false,
          drawOnChartArea: false,
          color: "#655B96",
        },
        ticks: {
          color: "#838383",
          font: {
            size: 9
          }
        },
        stacked: true,
        border: {
            display: false
        }
      },
      y: {
        grid: {
          display: false,
          drawOnChartArea: false,
          color: "#655B96",
        },
        ticks: {
          color: "rgba(148, 148, 148, 1)",
          stepSize: 20,
          font: {
            size: 9
            },
          callback: function(value, index, ticks) {
            return value + 'k';
        }
        },
        border: {
            display: false
        }
      },
    },
  };

  const labels = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  const data = {
    labels,
    datasets: [
      {
        label: "Dataset 1",
        data: [65, 59, 80, 81, 56, 55, 40, 34, 20, 10, 27, 87],
        backgroundColor: "rgba(0, 173, 239, 1)",
        barThickness: 8,
        borderRadius: 100,
        borderSkipped: false
      },
      {
        backgroundColor: '#F2F7FF',
        hoverBackgroundColor: '#F2F7FF',
        data:  [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
        datalabels: {
          display: false
        },
        barThickness: 8,
        borderRadius: 100,
        borderSkipped: false
      }
    ],
  };

  return (
    <div className={className} style={{ width: "99%" }}>
      <Bar options={options} data={data} />
    </div>
  );
};

export default ProgressBarChart;
