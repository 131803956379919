import React from "react";

const SelectOption = ({ icon, title, handleClick, clicked, postIcon }) => {
  
  return (
    <div
      className={`relative border ${
        clicked ? "border-[#00ADEF]" : "border-[#D9D9D9]"
      } rounded-[5px] h-[45px] w-full flex items-center justify-between mb-4 cursor-pointer`}
      onClick={handleClick}
    >
      <div className="flex items-center justify-start gap-4 ml-4">
        {icon !== null && <img src={icon} className="w-[25px] h-[25px] grayscale-[1]" />}
        <p className="font-poppins font-[400] sm:text-[12px] md:text-[14px] leading-[21px]">
          {title}
        </p>
      </div>
      {postIcon !== null && <img src={postIcon} className="w-[70px] absolute top-2 right-2" />}
    </div>
  );
};

export default SelectOption;
