import React, { useEffect, useState } from "react";
import Swoodle from "../../../Assets/Images/Onboard Swoodle Logo.svg";
import Profile from "../../../Assets/Images/Profile.svg";
import person from "../../../Assets/Images/Person.svg";
import emailIcon from "../../../Assets/Icons/emailIcon.svg";
import passwordIcon from "../../../Assets/Icons/passwordIcon.svg";
import "./signup.scss";
import TextInput from "./TextInput";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import GlassEffect from "../../../Assets/Images/blueGlass.svg";
import { useFormik } from "formik";
import {
  fundraiserSignUpSchema,
  signUpSchema,
} from "../../../utils/validationSchemas";
import { ReactComponent as CheckedBox } from "../../../Assets/Icons/checkedBox.svg";
import { ReactComponent as UncheckedBox } from "../../../Assets/Icons/uncheckedBlackBox.svg";
import { useDispatch } from "react-redux";
import { setSignUpData } from "../../../redux/slices/authSlice";
import { signUp } from "../../../services/authService";
import { roleEnum } from "../../../utils/utilFunctions";
import { getFundraiserCategories } from "../../../services/fundraiserService";
import { ReactComponent as DownArrow } from "../../../Assets/Icons/Down Arrow.svg";
import Selector from "../Selector";
import { getFcmToken } from "../../../utils/connectStore";
import PrimaryButton from "../PrimaryButton";

const SignUpSection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPath = location.pathname;

  const [showPassword, setShowPassword] = useState(false);
  const [checked, setChecked] = useState(false);
  const [fundraiserCategories, setFundraiserCategories] = useState([]);
  const [categoryObject, setCategoryObject] = useState();

  const getCategories = async () => {
    try {
      const result = await dispatch(getFundraiserCategories()).unwrap();
      setFundraiserCategories(result?.data);
    } catch (error) {
      console.error("Error fetching categories", error);
      throw error; // You might want to throw the error or handle it appropriately
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const onChecked = () => {
    setChecked(!checked);
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const navigation = () => {
    navigate("/");
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      category: undefined,
    },
    validationSchema: currentPath?.includes(roleEnum.fundraiser)
      ? fundraiserSignUpSchema
      : signUpSchema,
    onSubmit: async (values) => {
      console.log(values);
      try {
        if (currentPath?.includes(roleEnum.merchant)) {
          const merchantPromise = await dispatch(
            signUp({
              email: email,
              name: firstName + " " + lastName,
              password: password,
              phone: "03123458765",
              role: roleEnum.merchant.slice(0, -1),
              fcmToken: getFcmToken(),
            })
          ).unwrap();
          if (merchantPromise?.success === true) {
            navigation();
            dispatch(
              setSignUpData({
                email: email,
                name: firstName + " " + lastName,
                password: password,
                phone: "03123457698765",
                role: roleEnum.merchant.slice(0, -1),
                fcmToken: getFcmToken(),
              })
            );
          }
        } else if (currentPath?.includes(roleEnum.fundraiser)) {
          const fundraiserPromise = await dispatch(
            signUp({
              email: email,
              name: firstName + " " + lastName,
              password: password,
              phone: "03123458765",
              role: roleEnum.fundraiser.slice(0, -1),
              category: categoryObject,
              fcmToken: getFcmToken(),
            })
          ).unwrap();
          if (fundraiserPromise?.success === true) {
            navigation();
            dispatch(
              setSignUpData({
                email: email,
                name: firstName + " " + lastName,
                password: password,
                phone: "03123457698765",
                role: roleEnum.fundraiser.slice(0, -1),
                category: categoryObject,
                fcmToken: getFcmToken(),
              })
            );
          }
        } else {
          const adminPromise = await dispatch(
            signUp({
              email: email,
              name: firstName + " " + lastName,
              password: password,
              phone: "03123458765",
              role: roleEnum.merchant.slice(0, -1),
              fcmToken: getFcmToken(),
            })
          ).unwrap();
          if (adminPromise?.success === true) {
            navigation();
            dispatch(
              setSignUpData({
                email: email,
                name: firstName + " " + lastName,
                password: password,
                phone: "03123457698765",
                role: roleEnum.merchant.slice(0, -1),
                fcmToken: getFcmToken(),
              })
            );
          }
        }
      } catch (error) {
        throw error;
      }
    },
  });

  const { firstName, lastName, email, password, category } = formik.values;

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = formik;

  const handleCategoryChange = (e) => {
    setFieldValue("category", e);
    fundraiserCategories?.map(async (fc) => {
      if (fc?.title === e) {
        setCategoryObject(fc);
      }
    });
  };

  const fundraiserCategoriesOptions = fundraiserCategories?.map((op) => ({
    value: op?.title ?? "abc",
    label: op?.title ?? "dcs",
  }));

  return (
    <form className=" bg-[#00ADEF80] py-10 h-screen" onSubmit={handleSubmit}>
      <div className="flex flex-col items-center">
        <div className="pb-[5%]">
          <img src={Swoodle} alt="swoodle"></img>
        </div>
        {currentPath?.includes(roleEnum.fundraiser) ? (
          <div className="relative sm:mt-14 md:mt-12 lg:mt-4 2xl:mt-3">
            <img
              src={GlassEffect}
              className="sm:w-[500px] md:w-[520px]"
              alt="GlassEffect"
            ></img>
            <div className="w-full absolute z-[1] top-0">
              <div className="flex justify-center mt-[-12%]">
                <img
                  src={Profile}
                  className="w-[26%] h-[26%]"
                  alt="profile"
                ></img>
              </div>

              <div>
                <p className="sm:text-[18px] md:text-[20px] font-[600] text-center text-white font-poppins">
                  Register
                </p>
              </div>
              <div className="w-[90%] m-auto ">
                <div className={`mt-[2%] w-full flex justify-between h-full`}>
                  <TextInput
                    Icon={person}
                    name={"firstName"}
                    value={firstName}
                    placeholder={"First Name"}
                    inputClass={
                      "w-full rounded-tr-[10px] rounded-br-[10px] border-none"
                    }
                    iconAreaClass={
                      "w-[30%] bg-[#E6E6E6] rounded-tl-[10px] rounded-bl-[10px] border-none"
                    }
                    inputDivClass={"h-[55px] sm:h-[65px]"}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    error={errors.firstName}
                    touched={touched.firstName}
                  />
                  <div className="w-[10%]"></div>
                  <TextInput
                    Icon={person}
                    name={"lastName"}
                    value={lastName}
                    placeholder={"Last Name"}
                    inputClass={
                      "w-full rounded-tr-[10px] rounded-br-[10px] border-none"
                    }
                    iconAreaClass={
                      "w-[30%] bg-[#E6E6E6] rounded-tl-[10px] rounded-bl-[10px] border-none"
                    }
                    inputDivClass={"h-[55px] sm:h-[65px]"}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    error={errors.lastName}
                    touched={touched.lastName}
                  />
                </div>

                <div className={`mt-[1%]`}>
                  <TextInput
                    Icon={emailIcon}
                    name={"email"}
                    value={email}
                    placeholder={"Email ID"}
                    inputClass={
                      "w-full rounded-tr-[10px] rounded-br-[10px] border-none"
                    }
                    iconAreaClass={
                      "w-[12%] bg-[#E6E6E6] rounded-tl-[10px] rounded-bl-[10px] border-none"
                    }
                    inputDivClass={"h-[55px] sm:h-[65px]"}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    error={errors.email}
                    touched={touched.email}
                  />
                </div>

                <div className={`mt-[1%]`}>
                  <TextInput
                    Icon={passwordIcon}
                    type={showPassword ? "text" : "password"}
                    name={"password"}
                    value={password}
                    placeholder={"Password"}
                    inputClass={
                      "w-full rounded-tr-[10px] rounded-br-[10px] border-none"
                    }
                    iconAreaClass={
                      "w-[12%] bg-[#E6E6E6] rounded-tl-[10px] rounded-bl-[10px] border-none"
                    }
                    inputDivClass={"h-[55px] sm:h-[65px]"}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    error={errors.password}
                    touched={touched.password}
                    showPassword={showPassword}
                    togglePassword={togglePassword}
                    autoComplete={"new-password"}
                  />
                </div>
                <div className="flex sm:flex-col md:flex-row justify-between w-[100%] offerform">
                  <Selector
                    label={""}
                    value={category}
                    divClassName="signup w-[100%]"
                    selectClassName="w-full rounded-[10px] bg-[#FFFFFF] text-[#303030] text-[14px] font-[400] !font-poppins !focus:outline-none"
                    errorClassName="mt-[3%]"
                    flexClassName="h-[45px]"
                    options={fundraiserCategoriesOptions}
                    suffixIcon={<DownArrow />}
                    placeholder={"Select Category"}
                    onChange={handleCategoryChange}
                    handleBlur={handleBlur}
                    error={errors.category}
                    touched={touched.category}
                  />
                </div>
                <div className={`sm:mt-[4%] md:mt-[4%] flex items-center`}>
                  {checked === true ? (
                    <CheckedBox onClick={onChecked} className="inputCheckbox" />
                  ) : (
                    <UncheckedBox
                      onClick={onChecked}
                      className="inputCheckbox"
                    />
                  )}
                  <p className="sm:text-[10px] md:text-[12px] font-[400] text-white font-poppins">
                    {" "}
                    I Accept{" "}
                    <span className="sm:text-[10px] md:text-[12px] font-[700] text-white font-poppins">
                      Terms And Conditions
                    </span>
                  </p>
                </div>

                <div className={`text-center mt-[2%] mb-[1%] md:mt-[2%]`}>
                  <PrimaryButton
                    type="submit"
                    className={
                      "w-[60%] sm:text-[16px] md:text-[18px] font-[500] h-[50px] sm:h-[45px] md:h-[50px] rounded-[10px]"
                    }
                    label="Register"
                  />
                </div>

                <div>
                  <p className="text-white text-center sm:text-[10px] md:text-[11px] font-[500] font-poppins">
                    Already have an account?{" "}
                    <NavLink
                      to={"/"}
                      className={
                        "sm:text-[10px] md:text-[11px] ml-1 font-[700] font-poppins"
                      }
                    >
                      Sign In
                    </NavLink>
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="relative sm:mt-14 md:mt-12 lg:mt-4 2xl:mt-3">
            <img
              src={GlassEffect}
              className="sm:w-[450px] md:w-[480px]"
              alt="GlassEffect"
            ></img>
            <div className="w-full absolute z-[1] top-0">
              <div className="flex justify-center mt-[-12%]">
                <img
                  src={Profile}
                  className="w-[26%] h-[26%]"
                  alt="profile"
                ></img>
              </div>

              <div>
                <p className="sm:text-[18px] md:text-[20px] font-[600] text-center text-white font-poppins">
                  Register
                </p>
              </div>
              <div className="w-[90%] m-auto ">
                <div className={`mt-[3%] w-full flex justify-between h-full`}>
                  <TextInput
                    Icon={person}
                    name={"firstName"}
                    value={firstName}
                    placeholder={"First Name"}
                    inputClass={
                      "w-full rounded-tr-[10px] rounded-br-[10px] border-none"
                    }
                    iconAreaClass={
                      "w-[30%] bg-[#E6E6E6] rounded-tl-[10px] rounded-bl-[10px] border-none"
                    }
                    inputDivClass={"h-[55px] sm:h-[65px]"}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    error={errors.firstName}
                    touched={touched.firstName}
                  />
                  <div className="w-[10%]"></div>
                  <TextInput
                    Icon={person}
                    name={"lastName"}
                    value={lastName}
                    placeholder={"Last Name"}
                    inputClass={
                      "w-full rounded-tr-[10px] rounded-br-[10px] border-none"
                    }
                    iconAreaClass={
                      "w-[30%] bg-[#E6E6E6] rounded-tl-[10px] rounded-bl-[10px] border-none"
                    }
                    inputDivClass={"h-[55px] sm:h-[65px]"}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    error={errors.lastName}
                    touched={touched.lastName}
                  />
                </div>

                <div className={`sm:mt-[1%] md:mt-[2%]`}>
                  <TextInput
                    Icon={emailIcon}
                    name={"email"}
                    value={email}
                    placeholder={"Email ID"}
                    inputClass={
                      "w-full rounded-tr-[10px] rounded-br-[10px] border-none"
                    }
                    iconAreaClass={
                      "w-[12%] bg-[#E6E6E6] rounded-tl-[10px] rounded-bl-[10px] border-none"
                    }
                    inputDivClass={"h-[55px] sm:h-[65px]"}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    error={errors.email}
                    touched={touched.email}
                  />
                </div>

                <div className={`sm:mt-[1%] md:mt-[2%]`}>
                  <TextInput
                    Icon={passwordIcon}
                    type={showPassword ? "text" : "password"}
                    name={"password"}
                    value={password}
                    placeholder={"Password"}
                    inputClass={
                      "w-full rounded-tr-[10px] rounded-br-[10px] border-none"
                    }
                    iconAreaClass={
                      "w-[12%] bg-[#E6E6E6] rounded-tl-[10px] rounded-bl-[10px] border-none"
                    }
                    inputDivClass={"h-[55px] sm:h-[65px]"}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    error={errors.password}
                    touched={touched.password}
                    showPassword={showPassword}
                    togglePassword={togglePassword}
                    autoComplete={"new-password"}
                  />
                </div>
                <div className={`sm:mt-[2%] md:mt-[2%] flex items-center`}>
                  {checked === true ? (
                    <CheckedBox onClick={onChecked} className="inputCheckbox" />
                  ) : (
                    <UncheckedBox
                      onClick={onChecked}
                      className="inputCheckbox"
                    />
                  )}
                  <p className="sm:text-[10px] md:text-[12px] font-[400] text-white font-poppins">
                    {" "}
                    I Accept{" "}
                    <span className="sm:text-[10px] md:text-[12px] font-[700] text-white font-poppins">
                      Terms And Conditions
                    </span>
                  </p>
                </div>

                <div className={`text-center mt-[3%] mb-[2%] md:mt-[2%]`}>
                  <PrimaryButton
                    type="submit"
                    className={
                      "w-[60%] sm:text-[16px] md:text-[18px] font-[500] h-[50px] sm:h-[45px] md:h-[50px] rounded-[10px]"
                    }
                    label="Register"
                  />
                </div>

                <div>
                  <p className="text-white text-center sm:text-[10px] md:text-[12px] font-[500] font-poppins">
                    Already have an account?{" "}
                    <NavLink
                      to={"/"}
                      className={
                        "sm:text-[10px] md:text-[12px] ml-1 font-[700] font-poppins"
                      }
                    >
                      Sign In
                    </NavLink>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </form>
  );
};

export default SignUpSection;
