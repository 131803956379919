import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./franchiseform.scss";
import BreadCrumsHeader from "../../common/BreadCrums";
import FormLayout from "../../common/FormLayout";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import {
  franchiseSchema,
  uploadOfferSchema,
} from "../../../utils/validationSchemas";
import {
  addFranchise,
  editFranchise,
} from "../../../services/franchiseService";
import Loader from "../../common/Loader";
import dayjs from "dayjs";
import AddFranchise from "./AddFranchise";
import { ReactComponent as UploadIcon } from "../../../Assets/Icons/uploadExcelIcon.svg";
import { ReactComponent as AddIcon } from "../../../Assets/Icons/addNewIcon.svg";

const FranchiseFormPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.franchiseReducer?.loading);
  const [uploadClicked, setUploadClicked] = useState(false);
  const allFranchises = useSelector(
    (state) => state.franchiseReducer.allFranchises
  );
  const { id = null } = location?.state || "";
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const format = "hh:mm A";

  const formik = useFormik({
    initialValues: {
      document: "",
      franchiseName: "",
      email: "",
      contact: "",
      merchantName: "",
      address: "",
      timeSet: false,
      timeData: days?.map((d) => ({
        day: d?.toLowerCase(),
        openTime: undefined,
        closeTime: undefined,
        isSelected: false,
      })),
    },
    validationSchema:
      uploadClicked === true ? uploadOfferSchema : franchiseSchema,
    onSubmit: async (values) => {
      if (id !== "" && id !== null) {
        const editPromise = await dispatch(
          editFranchise({
            id: id,
            name: values?.franchiseName,
            email: values?.email,
            phone: values?.contact,
            googlePlaceId: placeId !== null ? placeId : null,
            tradingHours: timeData
              .filter((day) => day?.isSelected === true)
              .map((day) => ({
                day: day?.day?.toLowerCase(),
                openTime: day?.openTime ? day?.openTime.format(format) : null,
                closeTime: day?.closeTime
                  ? day?.closeTime.format(format)
                  : null,
              })),
          })
        ).unwrap();
        if (editPromise?.success === true) {
          navigate("/merchant/franchise-list");
        }
      } else {
        const addPromise = await dispatch(
          addFranchise({
            name: values?.franchiseName,
            email: values?.email,
            phone: values?.contact,
            googlePlaceId: placeId !== null ? placeId : null,
            tradingHours: timeData
              .filter((day) => day?.isSelected === true)
              .map((day) => ({
                day: day?.day?.toLowerCase(),
                openTime: day?.openTime ? day?.openTime.format(format) : null,
                closeTime: day?.closeTime
                  ? day?.closeTime.format(format)
                  : null,
              })),
          })
        ).unwrap();
        if (addPromise?.success === true) {
          navigate("/merchant/franchise-list");
        }
      }
    },
  });

  const {
    document,
    franchiseName,
    email,
    contact,
    merchantName,
    address,
    timeSet,
    timeData,
  } = formik.values;

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    setValues,
  } = formik;

  const handleTimeChange = (dayIndex, field, time) => {
    const updatedTimeData = [...timeData];
    updatedTimeData[dayIndex][field] = time;
    setFieldValue("timeData", updatedTimeData);
  };

  useEffect(() => {
    const allTimeSet = timeData?.every((day) => day.isSelected === false);
    setFieldValue("timeSet", allTimeSet);
  }, [timeData]);

  const items = [
    {
      title: "Franchise",
    },
    {
      title: "Add a Franchise",
    },
  ];

  const [placeId, setPlaceId] = useState(null);

  const handleUploadClick = () => {
    setUploadClicked(true);
  };

  const handleAddClick = () => {
    setUploadClicked(false);
  };

  const handleCancelClick = () => {
    navigate("/merchant/franchise-list");
  };

  useEffect(() => {
    if (id !== "" && id !== null) {
      allFranchises?.map((franchise) => {
        if (franchise?._id === id) {
          setValues({
            franchiseName: franchise?.name,
            email: franchise?.email,
            contact: franchise?.phone,
            merchantName: franchise?.merchant?.legalName,
            address: franchise?.googlePlaceId?.address,
          });
          franchise?.tradingHours?.forEach((th) => {
            const index = timeData?.findIndex((td) => td?.day === th?.day);
            
            if (index !== -1) {
              const updatedTimeData = [...timeData];
              updatedTimeData[index].openTime = dayjs(th?.openTime, format);
              updatedTimeData[index].closeTime = dayjs(th?.closeTime, format);
              updatedTimeData[index].isSelected = true;
              setFieldValue("timeData", updatedTimeData);
            }
          });
        }
      });
    }
  }, [id]);

  return (
    <>
      {loading === true && <Loader />}
      <BreadCrumsHeader heading="Add a Franchise" items={items} form={true} />
      <FormLayout
        title={"Franchise Information"}
        content={
          <div className="px-5">
            <div className="mt-5 w-full flex justify-between items-center">
              <div
                className="flex items-center gap-2 cursor-pointer"
                onClick={handleAddClick}
              >
                <AddIcon />
                <p className="font-poppins font-[400] sm:text-[12px] md:text-[14px] text-[#00ADEF] text-center leading-[18px]">
                  Add Location
                </p>
              </div>
              <UploadIcon
                className="cursor-pointer"
                onClick={handleUploadClick}
              />
            </div>
            <AddFranchise
              values={formik.values}
              errors={errors}
              touched={touched}
              handleBlur={handleBlur}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              handleTimeChange={handleTimeChange}
              format={format}
              uploadClicked={uploadClicked}
              setPlaceId={setPlaceId}
            />
          </div>
        }
      />
      <div className="col-span-12 text-end mt-14">
        <button
          className="w-[30%] lg:w-[15%] h-[45px] rounded-[5px] bg-[#F0F0F0] text-[16px] font-[500] text-[#8C8C8C]"
          style={{ border: "1px solid #C4C4C4" }}
          onClick={handleCancelClick}
        >
          Cancel
        </button>
        <button
          type="submit"
          onClick={handleSubmit}
          className="w-[30%] lg:w-[15%] h-[45px] rounded-[5px] bg-[#00ADEF] text-[16px] font-[500] text-[#FFFFFF] ms-2"
        >
          Save
        </button>
      </div>
    </>
  );
};

export default FranchiseFormPage;
