import React from "react";
import "./offerReview.scss";
import MerchantDetails from "./MerchantDetails";
import OfferDetails from "./OfferDetails";

const MobileViews = ({offerData, merchantData}) => {
  return (
    <div className="flex sm:flex-col lg:flex-row justify-start items-center sm:gap-8 md:gap-6 lg:gap-12">
      <MerchantDetails merchantData={merchantData} offerData={offerData} />
      <OfferDetails offerData={offerData} />
    </div>
  );
};

export default MobileViews;
