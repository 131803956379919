import axios from "../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const signUp = createAsyncThunk(
  "auth/signup",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post("auth/signup", payload);
      if (response) {
        return response?.data;
      }
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post("auth/login", payload);
      if (response) {
        return response?.data;
      }
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const forgetPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post("auth/forgotPassword", payload);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const emailVerification = createAsyncThunk(
  "auth/emailVerification",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post("auth/emailVerification", payload);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const getAccessToken = async (payload) => {
  try {
    const response = await axios.post("auth/refresh", payload);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

export const otpVerification = createAsyncThunk(
  "auth/verifyForgotOtp",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post("auth/verifyForgotOtp", payload);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post("auth/resetPassword", payload);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post("auth/logout", payload);
      if (response) {
        return response?.data;
      }
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);