import React, { useRef, useEffect, useState } from "react";
import FundraiserHeader from "../common/FundraiserHeader";
import FundraiserSocial from "./FundraiserSocial";
import FundraiserAboutUs from "./FundraiserAboutUs";
import SubscriptionPlan from "../common/SubscriptionPlan";
import { useDispatch, useSelector } from "react-redux";
import { getFundraiserContent } from "../../../services/fundraiserService";
import Loader from "../../common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { getSubscriptionPlans } from "../../../services/subscriptionService";
import { setSelectedPlan } from "../../../redux/slices/subscriptionSlice";

const FundraiserBrandDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.fundraiserReducer?.loading);
  const id = useParams();
  const scrollRef = useRef(null);
  const [content, setContent] = useState();
  const [premiumPlan, setPremiumPlan] = useState({});
  const [basicPlan, setBasicPlan] = useState({});

  const fetchPlans = async () => {
    try {
      const subPlans = await dispatch(getSubscriptionPlans({})).unwrap();
      if (subPlans?.success === true && subPlans?.data?.length > 0) {
        setPremiumPlan(subPlans?.data[0]);
        setBasicPlan(subPlans?.data[1]);
      }
    } catch (error) {
      console.error("Error fetching subscription plans:", error);
    }
  };

  const getContent = async () => {
    const getPromise = await dispatch(
      getFundraiserContent({ userId: id?.id })
    )?.unwrap();
    if (getPromise?.success === true) {
      setContent(getPromise?.data);
    }
  };

  useEffect(() => {
    getContent();
    fetchPlans();
  }, []);

  const scrollTo = () => {
    scrollRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleJoinClick = async (plan) => {
    dispatch(setSelectedPlan(plan));
    navigate("/membership-activation");
  };

  return (
    <div className="w-full h-full">
      {loading === true && <Loader />}
      <FundraiserHeader brandLogo={content?.logo} />

      <div className="flex flex-col px-[4%] 2xl:px-[3%] py-8">
        <FundraiserSocial
          brandLogo={content?.logo}
          scrollTo={scrollTo}
          banner={content?.banner}
          fundraiserName={content?.user?.name}
        />
        <FundraiserAboutUs html={content?.aboutUs} scrollTo={scrollTo} fundraiserName={content?.user?.name}/>
      </div>

      <div className="bg-[#F4F4F4] py-10 flex flex-col items-center gap-12">
        <p className="text-[22px] sm:text-[26px] md:text-[28px] xl:text-[32px] font-[600] text-[#303030] text-center w-[85%]">
          Be a part of our community by joining us!
        </p>
        <div
          ref={scrollRef}
          className="flex flex-col sm:flex-row w-full items-center justify-center gap-5"
        >
          <SubscriptionPlan
            title={basicPlan?.name}
            plans={basicPlan?.description?.split(",")}
            price={basicPlan?.amount}
            duration={basicPlan?.duration}
            joinClick={() => handleJoinClick(basicPlan)}
          />
          <SubscriptionPlan
            title={premiumPlan?.name}
            plans={premiumPlan?.description?.split(",")}
            price={premiumPlan?.amount}
            duration={premiumPlan?.duration}
            joinClick={() => handleJoinClick(premiumPlan)}
          />
        </div>
        <p className="text-[14px] sm:text-[16px] md:text-[18px] font-poppins font-[400] mt-4 text-center w-[90%]">
          By selecting a plan, you agree to our
          <span className="text-[#00ADEF] cursor-pointer" onClick={() => window.open("/privacy/toc", "_blank")}> Terms of Services </span>
          and
          <span className="text-[#00ADEF] cursor-pointer" onClick={() => window.open("/privacy/policy", "_blank")}> Privacy Policy</span>
        </p>
      </div>
    </div>
  );
};

export default FundraiserBrandDetails;
