import React, { useEffect, useState } from "react";
import BreadCrumsHeader from "../../common/BreadCrums";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import Pagination from "../../common/TablePagination";
import "./fundsDeposited.scss";
import SearchBar from "../../common/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { getFundsDeposited } from "../../../services/fundraiserService";
import Loader from "../../common/Loader";
import { separateDateTimeAndConvertToLocale } from "../../../utils/utilFunctions";

const FundsDepositedTable = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.fundraiserReducer?.loading);
  const [allFunds, setAllFunds] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);

  const getFundsData = async () => {
    const fundsPromise = await dispatch(getFundsDeposited())?.unwrap();
    if (fundsPromise?.success === true) {
      setAllFunds(fundsPromise?.data);
      setCurrentPage(fundsPromise?.currentPage);
      setTotalPages(fundsPromise?.totalPages);
      setCount(fundsPromise?.count);
    }
  };

  useEffect(() => {
    getFundsData();
  }, [currentPage]);

  const items = [
    {
      title: "Transactions",
    },
    {
      title: "Funds Deposited",
    },
  ];

  const columns = [
    {
      name: "No.",
    },
    {
      name: "Date",
    },
    {
      name: "User Name",
    },
    {
      name: "Email",
    },
    {
      name: "Balance",
    },
  ];

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      {loading === true && <Loader />}
      <div className="flex flex-col justify-between col-span-12">
        <BreadCrumsHeader heading="Funds Deposited" items={items} />
        <SearchBar
          divClassName="relative"
          inputClassName="searchBar mt-[4%] p-3 sm:w-[50%] lg:w-[33%] text-[14px] font-poppins font-[400] outline-none"
          iconClassName="absolute sm:left-[43%] sm:bottom-[20%] lg:left-[29%] xl:left-[30.5%] lg:bottom-[15%]"
          placeholder="Search"
        />
      </div>

      <div className="col-span-12">
        <TableContainer className="w-[100%]">
          <Table
            sx={{ borderCollapse: "separate", borderSpacing: "0px 8px" }}
            aria-label="simple table"
          >
            <TableHead className="bg-[#00ADEF]">
              <TableRow
                className="h-[50px]"
                sx={{ boxShadow: "0px 4px 4px 0px #00000040" }}
              >
                {columns.map((column) => {
                  return <TableCell>{column.name}</TableCell>;
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {allFunds?.map((funds, index) => {
                return (
                  <TableRow
                    className="h-[50px]"
                    sx={{
                      boxShadow: "0px 4px 4px 0px #00000040",
                      backgroundColor: (index + 1) % 2 !== 0 ? "#F5F5F5" : "#FFFFFF",
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{separateDateTimeAndConvertToLocale(funds?.createdAt)?.localDate}</TableCell>
                    <TableCell>{funds?.user?.name}</TableCell>
                    <TableCell>{funds?.user?.email}</TableCell>
                    <TableCell>{funds?.fundraiser?.balance}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          totalPages={totalPages}
          handlePageChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default FundsDepositedTable;
