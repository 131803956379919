import React from "react";

const InputTextArea = ({
  label,
  divClassName,
  labelClassName,
  textareaClassName,
  placeholder,
  name,
  value,
  setFieldValue,
  handleBlur,
  error,
  touched,
}) => {
  const maxCharacters = 250;

  const handleTextChange = (event) => {
    const newText = event.target.value;
    if (newText.length <= maxCharacters) {
      setFieldValue(name, newText);
    }
  };

  return (
    <>
      <div className={divClassName}>
        <p className={labelClassName}>{label}</p>
        <textarea
          className={textareaClassName}
          style={{scrollbarWidth: 'none'}}
          placeholder={placeholder}
          maxLength={maxCharacters}
          name={name}
          value={value}
          onChange={handleTextChange}
          onBlur={handleBlur}
        ></textarea>
        <div className="absolute bottom-4 right-3 text-[#D9D9D9] text-[10px] font-poppins">
          {value?.length}/{maxCharacters}
        </div>
      </div>
      {error && touched ? (
        <div className="ms-1 text-start w-full">
          <span className="text-red-600 sm:text-[10px] md:text-[12px] font-[600]">
            {error}
          </span>
        </div>
      ) : null}
    </>
  );
};

export default InputTextArea;
