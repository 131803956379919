import React, { useState } from "react";
import DropZone from "../../Dropzone";
import uploadOutline from "../../../../Assets/Icons/uploadOutlineIcon.svg";
import AddPhoto from "../AddPhoto";
import toast from "../../../../utils/toast";
import { uploadFile } from "../../../../utils/s3";

const UploadExcel = ({
  document,
  setFieldValue,
  setFileData,
  errors,
  touched,
  accept,
  fileText,
  validateFile,
  setS3Url
}) => {
  const [loading, setLoading] = useState(false);

  const handleAcceptedFiles = async (files) => {
    const file = files[0];
    if (validateFile(file.name)) {
      setLoading(true);
      const s3Url = await uploadFile(files[0], file.name);
      if (s3Url) {
        setS3Url(s3Url);
        setLoading(false);
      }
      setFileData(file);
      setFieldValue("document", file.name);
    } else {
      toast.error(`Only ${accept} files are allowed!`);
      handleRejectedFiles(files);
    }
  };

  const handleRejectedFiles = (files) => {
    const file = files[0];
    setFileData(null);
    setFieldValue("document", null);
    files[0]?.errors?.forEach((error) => {
      toast.error(error.message);
    });
  };

  const handleClear = () => {
    setS3Url(null)
    setFileData(null);
    setFieldValue("document", null);
  };

  return (
    <div
      className="h-[406px] w-[100%] mx-auto rounded-[20px] mt-5"
      style={{
        border: "2px dashed #00ADEF",
        boxShadow: "0px 4px 20px 10px #79797926",
      }}
    >
      {!document ? (
        <DropZone
          acceptedFiles={handleAcceptedFiles}
          rejectedFiles={handleRejectedFiles}
          maxLength={1}
          supportText={""}
          multiple={false}
          img={
            <AddPhoto
              divClassName="w-[202px] h-[111px] mt-24"
              icon={uploadOutline}
              text={fileText}
              loading={loading}
            />
          }
          accept={accept}
        ></DropZone>
      ) : (
        <div className="h-full mt-2 flex flex-col justify-center items-center w-full">
          <AddPhoto
            divClassName="w-[202px] h-[111px]"
            icon={uploadOutline}
            text={document}
          />
          <button
            type="button"
            className="my-5 shadow-none sm:w-[30%] xl:w-[10%] h-[40px] rounded-[5px] bg-[#00ADEF] text-[16px] font-[500] text-[#FFFFFF]"
            onClick={handleClear}
          >
            Clear
          </button>
        </div>
      )}
      <span className="text-red-600 sm:text-[10px] md:text-[12px] font-[600] ms-1">
        {errors.document && touched.document ? errors.document : null}
      </span>
    </div>
  );
};

export default UploadExcel;
