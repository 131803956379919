import React, { useEffect, useState } from "react";
import { ReactComponent as RejectedIcon } from "../../../Assets/Icons/rejectedIcon.svg";
import { ReactComponent as ApprovedIcon } from "../../../Assets/Icons/approvedIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader";
import { getNotificationsByUser } from "../../../services/notificationService";

const Notifications = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location?.pathname;
  const [notifications, setNotifications] = useState([]);
  const loading = useSelector((state) => state?.notificationReducer?.loading);

  const getNotifications = async () => {
    const notificationPromise = await dispatch(
      getNotificationsByUser()
    )?.unwrap();
    setNotifications(notificationPromise?.data);
  };
  useEffect(() => {
    getNotifications();
  }, []);
  const handleNotificationClick = () => {
    if (currentPath?.includes("merchant/")) {
      navigate("/merchant/offer-list");
    } else {
      navigate("/offer-list");
    }
  };

  return (
    <div
      className="absolute top-[45px] right-[6px] h-[250px] w-[350px] border-[#00ADEF] border-opacity-30 border-2 rounded-[6px] z-50 bg-white flex flex-col justify-between items-center overflow-y-auto drop-shadow-[0_3px_3px_rgba(0,0,0,0.25)]"
      style={{ scrollbarWidth: "thin" }}
    >
      <div className="w-full h-full notification">
        {loading === true && <Loader />}
        {notifications?.length > 0 ? (
          notifications?.map((n, index) => (
            <div
              className={`w-full flex justify-start items-center cursor-pointer ${
                index != notifications?.length - 1
                  ? "border-b border-[#DDDDDD]"
                  : ""
              } p-4 gap-4`}
              onClick={handleNotificationClick}
            >
              {n?.body?.includes("approved") && <ApprovedIcon />}
              {n?.body?.includes("rejected") && <RejectedIcon />}
              <p className="font-poppins text-[14px]">{n?.body}</p>
            </div>
          ))
        ) : (
          <div className="w-full h-full flex justify-center items-center">
            <p className="font-poppins text-[14px] font-[400]">
              Your Notifications will appear here!
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Notifications;
