import React, { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import { store, persistor } from "./redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import NetworkStatus from "./pages/common/NetworkStatus";
import AdminRoutes from "./routes/AdminRoutes";
import MerchantRoutes from "./routes/MerchantRoutes";
import FundraiserRoutes from "./routes/FundraiserRoutes";
import UserRoutes from "./routes/UserRoutes";
import CommonRoutes from "./routes/CommonRoutes";
import WebNotifications from "./components/common/WebNotifications";
import { isUserRoute } from "./utils/utilFunctions";

function App() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <NetworkStatus isOnline={isOnline} setIsOnline={setIsOnline} />
          {!isUserRoute && <WebNotifications />}
          {isOnline === true && (
            <>
              {/*Common*/}
              <CommonRoutes />

              {/* Admin */}
              <AdminRoutes />

              {/* Merchant */}
              <MerchantRoutes />

              {/* Fundraiser */}
              <FundraiserRoutes />

              {/*User Flow */}
              <UserRoutes />
            </>
          )}
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
