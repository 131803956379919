import React from 'react'
import SwoodleLogo from '../../../../Assets/Images/Onboard Swoodle Logo.svg'

const Header = () => {
  return (
    <div className='w-full h-[30%] bg-[#00ADEF] pl-[2%] py-[1%]'>
        <img className='sm:w-[15%] md:w-[12%] lg:w-[10%]' src={SwoodleLogo} ></img>
    </div>
  )
}

export default Header;