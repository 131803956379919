import React, { useEffect, useState } from "react";
import HamBurgerIcon from "../../../Assets/Icons/Group 49.svg";
import profileImage from "../../../Assets/Images/Ellipse 3.svg";
import BellIcon from "../../../Assets/Icons/Group 51.svg";
import "./navbar.scss";
import { Dropdown } from "antd";
import { useNavigate } from "react-router-dom";
// import SearchBar from "../SearchBar";
import Notifications from "./Notifications";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../services/authService";
import { getFcmToken } from "../../../utils/connectStore";
import Loader from "../Loader";

const Navbar = ({ isSideBarOpen, setisSideBarOpen, element }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isBellClicked, setIsBellClicked] = useState();
  const [greeting, setGreeting] = useState("");
  const loading = useSelector((state) => state.authReducer.loading);
  const userData = useSelector((state) => state?.authReducer?.tokenData?.user);

  const handleLogoutClick = async () => {
    const logoutPromise = await dispatch(
      logout({ fcmToken: getFcmToken() })
    )?.unwrap();
    if (logoutPromise?.success === true) {
      navigate("/");
    }
  };

  const items = [
    {
      key: 1,
      label: <p onClick={handleLogoutClick}>Logout</p>,
    },
  ];

  const onBellClicked = async () => {
    setIsBellClicked(!isBellClicked);
  };

  const handleGreeting = () => {
    const now = new Date();
    const hour = now.getHours();

    if (hour < 12) {
      setGreeting("Good Morning");
    } else if (hour < 18) {
      setGreeting("Good Afternoon");
    } else {
      setGreeting("Good Evening");
    }
  };

  useEffect(() => {
    handleGreeting();
  }, []);

  return (
    <div
      className={
        isSideBarOpen
          ? "sm:w-[70%] md:w-[75%] lg:w-[80%] xl:w-[80%] overflow-y-auto bg-[#FBFBFB]"
          : "w-full overflow-y-auto bg-[#FBFBFB]"
      }
    >
      {loading && <Loader />}
      <div className="flex py-5 bg-white">
        <div className="w-[50%] flex ps-10">
          <div>
            <img
              src={HamBurgerIcon}
              className="mr-2 cursor-pointer"
              onClick={() => {
                setisSideBarOpen(!isSideBarOpen);
              }}
            ></img>
          </div>
          {/* <SearchBar
            parentDivClass="w-[60%]"
            divClassName="flex items-center w-full h-[40px] relative"
            inputClassName="navSearchBar h-full w-[100%] ps-[4%] pe-[14%] text-[12px] font-[400] outline-none bg-[#F5F5F5] text-[#303030]"
            iconClassName="absolute right-[5%]"
            placeholder="Search here"
          /> */}
        </div>

        <div className="w-[50%] flex items-center justify-end pe-10">
          <div className="relative flex flex-col items-center justify-start">
            <img
              src={BellIcon}
              className="bg-[#00ADEF0D] p-2 cursor-pointer"
              onClick={onBellClicked}
            ></img>
            {isBellClicked == true && <Notifications />}
          </div>

          <Dropdown
            menu={{ items }}
            trigger={["click"]}
            className="ms-8 cursor-pointer"
          >
            <div className="flex" style={{ borderLeft: "1px solid #E0E0E0" }}>
              <div className="ps-6 pe-4">
                <p
                  className="text-[14px] text-right font-[500] whitespace-nowrap"
                  style={{ lineHeight: "21px" }}
                >
                  {userData?.name}
                </p>
                <p className="text-[12px] text-[#8C8C8C] font-[500] whitespace-nowrap">
                  {greeting}
                </p>
              </div>

              <div className="">
                <img src={profileImage}></img>
              </div>
            </div>
          </Dropdown>
        </div>
      </div>

      {/* Main Layout Route Pages Section */}

      <div className="ps-9 pe-9 pt-3 pb-8">{element}</div>
    </div>
  );
};

export default Navbar;
