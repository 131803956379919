import React, { useEffect } from "react";
import BreadCrumsHeader from "../../common/BreadCrums";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Status from "./Status";
import SwitchStatus from "../../common/Status";
import Confirmation from "./Confirmation";
import { useState } from "react";
import Pagination from "../../common/TablePagination";
import { useDispatch, useSelector } from "react-redux";
import {
  getMerchants,
  toggleMerchantStatus,
} from "../../../services/merchantService";
import Loader from "../../common/Loader";
import RejectOfferPopup from "../../common/RejectOfferPopup";
import crossIcon from "../../../Assets/Icons/cross.svg";

const MerchantListDataTable = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.merchantReducer?.loading);
  const [allMerchants, setAllMerchants] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);
  const [reason, setReason] = useState("");
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [openReasonBox, setOpenReasonBox] = useState(false);
  const [statusToggled, setStatusToggled] = useState(false);

  const getMerchantsData = async () => {
    const merchantsPromise = await dispatch(
      getMerchants({ page: currentPage })
    )?.unwrap();
    if (merchantsPromise?.success === true) {
      setAllMerchants(merchantsPromise?.data);
      setCurrentPage(merchantsPromise?.currentPage);
      setTotalPages(merchantsPromise?.totalPages);
      setCount(merchantsPromise?.count);
    }
  };

  useEffect(() => {
    getMerchantsData();
  }, [statusToggled, currentPage]);

  const items = [
    {
      title: "Merchants",
    },
    {
      title: "Merchant List",
    },
  ];

  const columns = [
    {
      name: "No.",
    },
    {
      name: "Merchant ID",
    },
    {
      name: "Merchant Name",
    },
    {
      name: "Email",
    },
    {
      name: "Contact",
    },
    {
      name: "Address",
    },
    // {
    //   name: "Confirmation",
    // },
    // {
    //   name: "Status",
    // },
    {
      name: "Action",
    },
  ];

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleActionToggled = async (merchant) => {
    setSelectedMerchant(merchant);
    if (merchant?.user?.isEnabled === true) {
      setOpenReasonBox(true);
    } else {
      const togglePromise = await dispatch(
        toggleMerchantStatus({ id: merchant?._id })
      )?.unwrap();
      if (togglePromise?.success === true) {
        setStatusToggled(!statusToggled);
        setSelectedMerchant(null);
        setOpenReasonBox(false);
      }
    }
  };

  const handleMerchantDisable = async () => {
    setOpenReasonBox(false);
    const togglePromise = await dispatch(
      toggleMerchantStatus({ id: selectedMerchant?._id, reason: reason })
    ).unwrap();
    if (togglePromise?.success === true) {
      setStatusToggled(!statusToggled);
      setSelectedMerchant(null);
    }
  };

  const handleCloseReject = () => {
    setSelectedMerchant(null);
    setOpenReasonBox(false);
  };

  return (
    <>
      {loading === true && <Loader />}
      {openReasonBox === true && (
        <RejectOfferPopup
          icon={crossIcon}
          content={
            "Could you please provide the reason for disabling this merchant?"
          }
          popupClassName={"w-[400px] sm:w-[500px] pb-4"}
          handleClose={handleCloseReject}
          setReason={setReason}
          handleOfferRejection={handleMerchantDisable}
        />
      )}
      <div className="flex justify-between col-span-12">
        <BreadCrumsHeader heading="Merchant List" items={items} />
      </div>

      <div className=" col-span-12 mt-8">
        <TableContainer style={{ width: "100%" }}>
          <Table
            sx={{
              minWidth: "1936px",
              borderCollapse: "separate",
              borderSpacing: "0px 8px",
            }}
            aria-label="simple table"
          >
            <TableHead className="bg-[#00ADEF]">
              <TableRow
                className="h-[50px]"
                sx={{ boxShadow: "0px 4px 4px 0px #00000040" }}
              >
                {columns.map((column) => {
                  return <TableCell>{column.name}</TableCell>;
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {allMerchants?.map((merchant, index) => {
                return (
                  <TableRow
                    className="h-[50px]"
                    sx={{
                      boxShadow: "0px 4px 4px 0px #00000040",
                      backgroundColor:
                        (index + 1) % 2 !== 0 ? "#F5F5F5" : "#FFFFFF",
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{merchant?._id}</TableCell>
                    <TableCell>{merchant?.businessName}</TableCell>
                    <TableCell>{merchant?.user?.email}</TableCell>
                    <TableCell>{merchant?.contactPersonNumber}</TableCell>
                    <TableCell>{merchant?.address}</TableCell>
                    {/* <TableCell>
                      <Confirmation status={merchant?.confirmation} />
                    </TableCell>
                    <TableCell>
                      <Status status={merchant?.status} />
                    </TableCell> */}
                    <TableCell>
                      <SwitchStatus
                        isEnabled={merchant?.user?.isEnabled}
                        handleToggle={() => handleActionToggled(merchant)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          totalPages={totalPages}
          handlePageChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default MerchantListDataTable;
