import React from "react";
import "./invitationform.scss";
import BreadCrumsHeader from "../../common/BreadCrums";
import Input from "../../common/FormInputs";
import FormLayout from "../../common/FormLayout";
import { useFormik } from "formik";
import { invitationSchema } from "../../../utils/validationSchemas";
import { sendInvite } from "../../../services/onboardingService";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../../common/Loader";

const InvitaionFormPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.onboardingReducer?.loading);
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      business: "",
    },
    validationSchema: invitationSchema,
    onSubmit: async (values) => {
      // Handle form submission
      console.log("invite values", values);
      const payload = {
        firstName: values?.firstName,
        lastName: values?.lastName,
        merchantName: values?.business,
        email: values?.email,
      };
      const invitePromise = await dispatch(sendInvite(payload)).unwrap();
      if (invitePromise?.success === true) {
        navigate("/invitation-list");
      }
    },
  });

  const { firstName, lastName, email, business } = formik.values;

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = formik;

  const items = [
    {
      title: "Merchants",
    },
    {
      title: "Invitation",
    },
  ];

  const InvitationFormContent = (
    <>
      {/* TimeLine Tool */}
      <div className="mt-8 w-[100%]">
        <div className="w-[45.5%] m-auto relative">
          {/* <div className='h-[40px] flex items-center'>
                    <div className='w-[95%]' style={{ height: "1px", backgroundColor: "#C4C4C4"}}></div>
                </div>

                <div className='w-full absolute top-0 flex justify-between'>

                    <div className='flex flex-col items-center'>
                        <div className='bg-[#00ADEF] text-[20px] border-none h-[40px] w-[40px] flex justify-center items-center text-white rounded-[100%]' >1</div>
                        <p className='text-[10px] text-[#303030] font-[400] mt-2' >Invite</p>
                    </div>

                    <div className='flex flex-col items-center'>
                        <div className='bg-white text-[20px] h-[40px] w-[40px] flex justify-center items-center text-[#C4C4C4] rounded-[100%]' style={{border: '1px solid #C4C4C4'}}>2</div>
                        <p className='text-[10px] text-[#303030] font-[400] mt-2' >Send / Schedule</p>
                    </div>

                    <div className='flex flex-col items-center'>
                        <div className='bg-white text-[20px] h-[40px] w-[40px] flex justify-center items-center text-[#C4C4C4] rounded-[100%]' style={{border: '1px solid #C4C4C4'}}>3</div>
                        <p className='text-[10px] text-[#303030] font-[400] mt-2' >Complete</p>
                    </div>

                </div> */}
        </div>
      </div>

      {/* Form Inputs */}

      <div className="flex sm:flex-col md:flex-row justify-between sm:mt-0 md:mt-12 w-[100%]">
        <Input
          label="First Name"
          placeholder="Name"
          width="100%"
          radius="10px"
          InputDivClass="w-[100%]"
          name={"firstName"}
          value={firstName}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.firstName}
          touched={touched.firstName}
        />
        <div className="w-[10%]"></div>
        <Input
          label="Last Name"
          placeholder="Name"
          width="100%"
          radius="10px"
          InputDivClass="w-[100%] sm:mt-10 md:mt-0"
          name={"lastName"}
          value={lastName}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.lastName}
          touched={touched.lastName}
        />
      </div>

      <div className="flex sm:flex-col md:flex-row justify-between sm:mt-0 md:mt-12 w-[100%]">
        <Input
          label="Email"
          placeholder="Email Address"
          width="100%"
          radius="10px"
          InputDivClass="w-[100%] sm:mt-10 md:mt-0"
          name={"email"}
          value={email}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.email}
          touched={touched.email}
        />
        <div className="w-[10%]"></div>
        <Input
          label="Business Name"
          placeholder="Business Name"
          width="100%"
          radius="10px"
          InputDivClass="w-[100%] sm:mt-10 md:mt-0"
          name={"business"}
          value={business}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.business}
          touched={touched.business}
        />
      </div>

      <div className="invite mt-16 flex justify-end w-full">
        <button
          type="submit"
          className="sendButton text-[14px] font-[400] font-poppins text-center sm:w-[35%] md:w-[30%] lg:w-[15%]"
          onClick={() => {
            handleSubmit();
          }}
        >
          Send
        </button>
      </div>
    </>
  );

  return (
    <>
      {loading === true && <Loader />}
      <BreadCrumsHeader heading="Invitation" items={items} form={true} />
      <FormLayout title={"Form Step"} content={InvitationFormContent} />
    </>
  );
};

export default InvitaionFormPage;
