import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  addFranchise,
  getFranchises,
  toggleFranchiseStatus,
  editFranchise,
  deleteFranchise,
  getLocationById
} from "../../services/franchiseService";
import toast from "../../utils/toast";

const initialState = {
  franchiseData: null,
  allFranchises: null,
  toggledFranchise: null,
  updatedFranchise: null,
  deletedFranchise: null,
  locationById: null, 
  loading: false,
};

const franchiseSlice = createSlice({
  name: "franchise",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addFranchise?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.franchiseData = action?.payload?.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(getFranchises?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.allFranchises = action?.payload?.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(toggleFranchiseStatus?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.toggledFranchise = action?.payload?.data;
        toast.success(action?.payload?.message);
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(editFranchise?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.updatedFranchise = action?.payload?.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(deleteFranchise?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.deletedFranchise = action?.payload?.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(getLocationById?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.locationById = action?.payload?.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder
      .addMatcher(
        isAnyOf(
          addFranchise?.pending,
          getFranchises?.pending,
          toggleFranchiseStatus?.pending,
          editFranchise?.pending,
          deleteFranchise?.pending,
          getLocationById?.pending
        ),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          addFranchise?.rejected,
          getFranchises?.rejected,
          toggleFranchiseStatus?.rejected,
          editFranchise?.rejected,
          deleteFranchise?.rejected,
          getLocationById?.rejected
        ),
        (state, action) => {
          state.loading = false;
          toast.error(action?.payload?.message);
        }
      );
  },
});

export const {} = franchiseSlice?.actions;
export default franchiseSlice?.reducer;
