import React from "react";
import PrimaryButton from "../../common/PrimaryButton";
import PrimaryOutlineButton from "../../common/PrimaryOutlineButton";
import "./fundraiserbrand.scss";

const FundraiserAboutUs = ({ html, scrollTo, fundraiserName }) => {
  return (
    <div className="flex flex-col-reverse gap-5 md:flex-row md:gap-0 justify-between mt-4 md:mt-8">
      <div className="w-full md:w-[63%]">
        <div className="flex flex-col gap-y-5">
          <p className="text-[18px] sm:text-[20px] md:text-[22px] font-[500] text-[#303030] mb-1">
            About Us
          </p>
          <div
            dangerouslySetInnerHTML={{
              __html: html
                ?.replace(/<p\s/g, '<p class="styled-paragraph" ')
                ?.replace(/<a\s/g, '<a class="styled-link" '),
            }}
          />
          {/* <p className="text-[12px] sm:text-[14px] md:text-[16px] font-[400] text-[#8C8C8C]">
            BOUNCE combines trampolines, airbags and adventure challenge
            features to create Freestyle Playgrounds that lead the world in
            action entertainment. BOUNCE, is about inspiring everyone to forget
            the serious stuff and get immersed in adventure and fun!
          </p>
          <p className="text-[12px] sm:text-[14px] md:text-[16px] font-[400] text-[#8C8C8C]">
            BOUNCE-X is the world’s first Freestyle Terrain park and the
            flagship hero in the BOUNCE global network that includes 32 venues
            across 16 countries. It’s an exciting integration of trampolines,
            parkour elements, ninja courses and freestyle features all combined
            into an huge 5,000 square meter indoor space.
          </p>
          <p className="text-[14px] sm:text-[16px] md:text-[18px] font-[600] text-[#00ADEF]">
            https://bounce.ae/
          </p>
          <p className="text-[12px] sm:text-[14px] md:text-[16px] font-[400] text-[#303030]">
            20% of every membership sold contributes to our fundraising. Help us
            achieve our goal by sharing this link with your friends and family.
            Thanks for your support!
          </p> */}
        </div>

        {/* <div className="flex flex-col gap-y-5 mt-6">
          <p className="text-[18px] sm:text-[20px] md:text-[22px] font-[500] text-[#303030] mb-1">
            Support our fundraising and enjoy 100s of everyday savings!
          </p>
          <p className="text-[12px] sm:text-[14px] md:text-[16px] font-[400] text-[#8C8C8C]">
            Swoodle is a user-friendly app that offers remarkable discounts of
            up to 50% and buy-one-get-one-free deals on a wide range of items,
            including takeout, dining, activities, and more!
          </p>
          <p className="text-[12px] sm:text-[14px] md:text-[16px] font-[400] text-[#8C8C8C]">
            What's even more exciting is that 20% of each membership purchase
            contributes to supporting Bounce Free Spirit Unleashed!
          </p>
        </div> */}
      </div>

      <div className="w-full md:w-[34%]">
        <div
          className="py-14 px-7 rounded-[10px]"
          style={{ boxShadow: "0px 6px 20px 0px #00000029" }}
        >
          <div
            className="text-center"
            style={{ borderBottom: "1px solid #DFE1E6" }}
          >
            <p className="text-[12px] sm:text-[14px] font-poppins font-[400] text-[303030]">
              Join Swoodle to help {fundraiserName} Free Spirits Unleashed.
            </p>
            <div className="flex flex-col gap-y-4 mt-8 mb-10 items-center">
              <PrimaryButton
                label={"Join Now"}
                className={
                  "w-[42%] sm:w-[35%] md:w-[50%] xl:w-[42%] text-[14px] sm:text-[16px] md:text-[18px] font-[500] h-[40px] sm:h-[40px] md:h-[45px] rounded-[10px]"
                }
                onClick={scrollTo}
              />
              <PrimaryOutlineButton
                label={"Share"}
                className={
                  "w-[42%] sm:w-[35%] md:w-[50%] xl:w-[42%] text-[14px] sm:text-[16px] md:text-[18px] font-[500] rounded-[10px]"
                }
              />
            </div>
          </div>

          <div className="flex flex-col mt-10 text-center gap-2">
            <p className="text-[14px] sm:text-[16px] md:text-[18px] font-[500] text-[#303030]">
              Fundraiser Contact{" "}
            </p>
            <p className="text-[14px] sm:text-[16px] md:text-[18px] font-[600] text-[#303030]">
              {fundraiserName}
            </p>
            <p className="text-[14px] sm:text-[16px] md:text-[18px] font-[500] text-[#303030]">
              +04 304 9333 879
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FundraiserAboutUs;
