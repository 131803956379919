import React, { useState } from "react";
import OnboardSwoodleLogo from "../../../Assets/Images/Onboard Swoodle Logo.svg";
import PrimaryButton from "../../common/PrimaryButton";
import TextInput from "../../common/SignUp/TextInput";
import person from "../../../Assets/Images/Person.svg";
import passwordIcon from "../../../Assets/Icons/passwordIcon.svg";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { createMerchantAccountSchema } from "../../../utils/validationSchemas";
import { useNavigate, useParams } from "react-router-dom";
import { merchantOnboard } from "../../../services/onboardingService";
import Loader from "../../common/Loader";

const CreateAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const { verificationCode } = useParams();
  const loading = useSelector((state) => state?.onboardingReducer?.loading);
  const merchantEmail = useSelector(
    (state) => state?.onboardingReducer?.merchantInviteSent?.email
  );
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: createMerchantAccountSchema,
    onSubmit: async (values) => {
      console.log(values);
      try {
        const merchantPromise = await dispatch(
          merchantOnboard({
            name: values?.firstName + " " + values?.lastName,
            password: values?.password,
            code: verificationCode,
          })
        ).unwrap();
        if (merchantPromise?.success === true) {
          navigate("/account-created");
        }
      } catch (error) {
        throw error;
      }
    },
  });

  const { firstName, lastName, password, confirmPassword } = formik.values;

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = formik;

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPassword = () => {
    setConfirmShowPassword(!showConfirmPassword);
  };

  return (
    <form
      className="w-full py-10 h-screen flex flex-col items-center offerform"
      onSubmit={handleSubmit}
    >
      {loading && <Loader />}
      <p className="font-poppins font-[500] sm:text-[18px] md:text-[22px] lg:text-[26px] text-[#303030]">
        Welcome to Swoodle! Let’s set up your Account!
      </p>
      <div className="flex flex-col items-center sm:w-[70%] md:w-[60%] lg:w-[50%] xl:w-[45%] 2xl:w-[40%]">
        <img src={OnboardSwoodleLogo} className="pt-[6%] pb-[2%] w-[60%]"></img>
        <p className="font-poppins font-[500] sm:text-[18px] md:text-[22px] lg:text-[26px] text-[#303030]">
          MERCHANT
        </p>
        <div className="flex flex-col justify-start items-start gap-2 w-full">
          <p className="font-poppins font-[500] sm:text-[16px] md:text-[18px] lg:text-[18px] xl:text-[22px] text-[#303030] mt-10 mb-3 text-start">
            Create Your Account
          </p>
          <p className="font-poppins font-[400] sm:text-[16px] md:text-[18px] lg:text-[18px] xl:text-[22px] text-[#767676] mt-2 mb-4 text-start">
            Email: {merchantEmail}
          </p>
          <TextInput
            Icon={person}
            name={"firstName"}
            value={firstName}
            placeholder={"First Name"}
            inputClass={
              "w-full rounded-tr-[5px] rounded-br-[5px] border border-[#D9D9D9]"
            }
            iconAreaClass={
              "w-[11%] bg-[#E6E6E6] rounded-tl-[5px] rounded-bl-[5px] border border-[#D9D9D9]"
            }
            inputDivClass={"h-[55px] sm:h-[65px]"}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors.firstName}
            touched={touched.firstName}
          />
          <TextInput
            Icon={person}
            name={"lastName"}
            value={lastName}
            placeholder={"Last Name"}
            inputClass={
              "w-full rounded-tr-[5px] rounded-br-[5px] border border-[#D9D9D9]"
            }
            iconAreaClass={
              "w-[11%] bg-[#E6E6E6] rounded-tl-[5px] rounded-bl-[5px] border border-[#D9D9D9]"
            }
            inputDivClass={"h-[55px] sm:h-[65px]"}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors.lastName}
            touched={touched.lastName}
          />

          <TextInput
            Icon={passwordIcon}
            type={showPassword ? "text" : "password"}
            name={"password"}
            value={password}
            placeholder={"Password"}
            inputClass={
              "w-full rounded-tr-[5px] rounded-br-[5px] border border-[#D9D9D9]"
            }
            iconAreaClass={
              "w-[11%] bg-[#E6E6E6] rounded-tl-[5px] rounded-bl-[5px] border border-[#D9D9D9]"
            }
            inputDivClass={"h-[55px] sm:h-[65px]"}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors.password}
            touched={touched.password}
            showPassword={showPassword}
            togglePassword={togglePassword}
            autoComplete={"new-password"}
          />
          <TextInput
            Icon={passwordIcon}
            type={showConfirmPassword ? "text" : "password"}
            name={"confirmPassword"}
            value={confirmPassword}
            placeholder={"Confirm Password"}
            inputClass={
              "w-full rounded-tr-[5px] rounded-br-[5px] border border-[#D9D9D9]"
            }
            iconAreaClass={
              "w-[11%] bg-[#E6E6E6] rounded-tl-[5px] rounded-bl-[5px] border border-[#D9D9D9]"
            }
            inputDivClass={"h-[55px] sm:h-[65px]"}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors.confirmPassword}
            touched={touched.confirmPassword}
            showPassword={showConfirmPassword}
            togglePassword={toggleConfirmPassword}
          />
          <p className="text-[10px] sm:text-[12px] md:text-[14px] font-poppins font-[400] mt-2 text-start w-[90%]">
            By clicking 'Create Account', I agree to the
            <span
              className="text-[#00ADEF] font-[500] cursor-pointer"
              onClick={() => window.open("/privacy/toc", "_blank")}
            >
              {" "}
              Terms of Use{" "}
            </span>
            that I have read the
            <span
              className="text-[#00ADEF] font-[500] cursor-pointer"
              onClick={() => window.open("/privacy/policy", "_blank")}
            >
              {" "}
              Privacy Policy.
            </span>
          </p>
        </div>
        <div className="text-center w-full">
          <PrimaryButton
            type={"submit"}
            className={
              "sm:w-[50%] md:w-[40%] lg:w-[50%] font-poppins sm:text-[14px] lg:text-[16px] text-center font-[500] sm:h-[45px] md:h-[50px] lg:h-[55px] rounded-[10px] my-10 tracking-wide"
            }
            label="Create Account"
          />
        </div>
      </div>
    </form>
  );
};

export default CreateAccount;
