import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./membershipConfirmation.scss";
import FundraiserHeader from "../common/FundraiserHeader";
import FundraiserBodyHeader from "../common/FundraiserBodyHeader";
import { Modal } from "antd";

import SwoodleModalLogo from "../../../Assets/Images/swoodleLogo.svg";
import GreenButton from "../../common/GreenButton";
import PrimaryOutlineButton from "../../common/PrimaryOutlineButton";
import PrimaryButton from "../../common/PrimaryButton";
import { useDispatch } from "react-redux";
import { logout } from "../../../services/authService";
import { getFcmToken } from "../../../utils/connectStore";

const Confirmation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const activateNow = () => {
    setIsModalOpen(true);
  };

  const activateLater = () => {
    setIsModalOpen(false);
  };

  const handleLogoutClick = async () => {
    const logoutPromise = await dispatch(
      logout({ fcmToken: getFcmToken() })
    )?.unwrap();
    if (logoutPromise?.success === "true") {
      navigate("/");
    }
  };

  const BodyHeaderContent = (
    <>
      You are about to activate your{" "}
      <span className="text-[#303030] font-[600]">
        Subscription plan up to 12 Months upgrade
      </span>{" "}
      membership
    </>
  );

  return (
    <div>
      <FundraiserHeader />
      <FundraiserBodyHeader
        heading="Confirmation"
        content={BodyHeaderContent}
        contentClass={"w-[100%] sm:w-[80%] md:w-[90%] text-[#303030]"}
      />

      {/* Subscription Plan */}

      <div className="flex flex-col items-center mt-8">
        <div className="text-center">
          <p className="text-[18px] font-[500] font-poppins text-[#303030]">
            irfandanish.dev@gmail.com
          </p>
        </div>

        <div className="w-[90%] sm:w-[80%] md:w-[70%] lg:w-[60%] xl:w-[50%] 2xl:w-[40%]">
          <div
            className="rounded-[10px] border-[2px] border-[#00ADEF4D] mt-5 mb-7"
            style={{ boxShadow: "0px 4px 6px 0px #00000040" }}
          >
            <div className="flex justify-between items-center  bg-[#00ADEF1A] ps-4 pt-8 pb-12 sm:ps-10 sm:pt-8 sm:pb-8 pe-4 rounded-t-[10px]">
              <p className="text-[14px] sm:text-[18px] font-[600] font-poppins text-[#303030] w-[200px]">
                Subscription Plan - 12 Months upgrade
              </p>
              <GreenButton
                onClick={activateNow}
                label={"Activate Now"}
                className={
                  "w-[39%] font-[500] font-poppins text-[14px] sm:w-[32%] sm:font-[600]"
                }
              />
            </div>

            <div className="text-center pt-7 pb-9">
              <PrimaryOutlineButton
                label={"Active Later"}
                className={
                  "w-[38%] text-[14px] font-[500] font-poppins sm:w-[32%] rounded-[10px]"
                }
              />
              <p className="text-[12px] font-[400] font-poppins text-[#8C8C8C] mt-3">
                Exit the activation process
              </p>
            </div>
          </div>

          <div className="text-end">
            <PrimaryButton
              label={"Logout"}
              className={
                "w-[32%] text-[14px] font-[500] font-poppins sm:w-[20%] h-[40px] md:h-[45px] rounded-[10px]"
              }
              onClick={handleLogoutClick}
            />
            <p className="text-[12px] font-[400] font-poppins text-[#8C8C8C] mt-3">
              Not you? Logout and start again
            </p>
          </div>
        </div>

        <Modal
          open={isModalOpen}
          title={null}
          closeIcon={false}
          footer={null}
          centered
          width={""}
          className="w-[90%] sm:w-[80%] md:w-[70%] lg:w-[60%] xl:w-[50%] 2xl:w-[40%]"
          onCancel={() => setIsModalOpen(false)}
        >
          <div className="flex flex-col items-center text-center">
            <img src={SwoodleModalLogo} className="w-[114px]"></img>
            <p className="text-[16px] text-[#303030] font-[400] mb-8 mt-10 font-poppins">
              By clicking now, you are starting your membership today, and it
              will expire on 16-10-2024. If you do not wish to commence your
              membership today, please click cancel and activate at a later
              time.
            </p>

            <div className="w-full flex justify-center gap-3">
              <PrimaryOutlineButton
                label={"Active Later"}
                className={
                  "w-[40%] text-[14px] font-[500] sm:w-[23%] sm:font-[500] rounded-[10px]"
                }
                onClick={activateLater}
              />

              <GreenButton
                onClick={activateNow}
                label={"Activate Now"}
                className={
                  "w-[40%] font-[500] text-[14px] sm:w-[23%] sm:font-[500]"
                }
              />
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Confirmation;
