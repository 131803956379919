import React from "react";

const PrimaryButton = ({ label, className, onClick, type, disabled }) => {
  return (
    <button
      type={type ? type : "button"}
      className={`${disabled ? "bg-[#8C8C8C]" : "bg-[#00ADEF]"} text-white font-poppins text-center ${
        className ? className : null
      }`}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default PrimaryButton;
