import React from "react";
import SwoodleLogo from "../../Assets/Images/Onboard Swoodle Logo.svg";
import PrimaryOutlineButton from "./PrimaryOutlineButton";

const Header = ({ btn, handleSaveAndExit }) => {
  return (
    <div className="w-full h-[30%] bg-[#FFFFFF] px-[2%] py-[1%] drop-shadow-[0_4px_10px_rgba(0,0,0,0.05)] flex items-center justify-between">
      <img className="sm:w-[15%] md:w-[10%]" src={SwoodleLogo}></img>
      {btn && (
        <PrimaryOutlineButton
          label={"Save and Exit"}
          className={
            "w-[40%] sm:w-[30%] md:w-[20%] lg:w-[15%] xl:w-[10%] text-[14px] font-[500] h-[40px] sm:h-[40px] md:h-[45px] rounded-[5px]"
          }
          onClick={handleSaveAndExit}
        />
      )}
    </div>
  );
};

export default Header;
