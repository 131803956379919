import React, { useState } from "react";
import Swoodle from "../../../Assets/Images/Onboard Swoodle Logo.svg";
import "./resetPassword.scss";
import Profile from "../../../Assets/Images/Profile.svg";
import passwordIcon from "../../../Assets/Icons/passwordIcon.svg";
import TextInput from "../SignUp/TextInput";
import { useNavigate } from "react-router-dom";
import GlassEffect from "../../../Assets/Images/blueGlass.svg";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { resetPasswordSchema } from "../../../utils/validationSchemas";
import { resetPassword } from "../../../services/authService";
import PrimaryButton from "../PrimaryButton";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const otpData = useSelector((state) => state?.authReducer?.otpToken);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPassword = () => {
    setConfirmShowPassword(!showConfirmPassword);
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: resetPasswordSchema,
    onSubmit: async (values) => {
      try {
        const resetPromise = await dispatch(
          resetPassword({ token: otpData, password: password })
        ).unwrap();

        if(resetPromise?.success === true) {
          navigate("/");
        } else {
          navigate("/forget-password")
        }
      } catch (err) {
        console.log("err", err);
      }
      // Handle form submission
      console.log("values", values);
    },
  });

  const { password, confirmPassword } = formik.values;

  const { handleChange, handleBlur, handleSubmit, errors, touched } = formik;

  return (
    <form className="loginSection py-16 w-full h-[100vh]" onSubmit={handleSubmit}>
      <div className="flex flex-col items-center">
        <div className="pb-[5%]">
          <img src={Swoodle} alt="swoodle"></img>
        </div>

        <div className="relative sm:w-[340px] md:w-[370px] sm:mt-14 md:mt-12 lg:mt-8 2xl:mt-3">
          <img src={GlassEffect} alt="Glass Effect"></img>
          <div className="w-full absolute z-[1] top-0">
            <div className="flex justify-center mt-[-12%] ">
              <img src={Profile} className="w-[26%] h-[26%]" alt="profile"></img>
            </div>
            <div>
              <p className="sm:text-[18px] md:text-[20px] font-[600] text-center text-white font-poppins">
                Enter New Password
              </p>
            </div>
            <div className="w-[90%] h-[100%] m-auto">
              <div className={`sm:mt-[4%] 2xl:mt-[4%]`}>
                <TextInput
                  Icon={passwordIcon}
                  type={showPassword ? "text" : "password"}
                  name={"password"}
                  value={password}
                  placeholder={"Enter Password "}
                  inputClass={"w-full rounded-tr-[10px] rounded-br-[10px] border-none"}
                  iconAreaClass={"w-[15%] bg-[#E6E6E6] rounded-tl-[10px] rounded-bl-[10px] border-none"}
                  inputDivClass={"h-[55px] sm:h-[65px]"}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  error={errors.password}
                  touched={touched.password}
                  showPassword={showPassword}
                  togglePassword={togglePassword}
                  autoComplete={"new-password"}
                />
              </div>
              <div className={`sm:mt-[4%] 2xl:mt-[4%]`}>
                <TextInput
                  Icon={passwordIcon}
                  type={showConfirmPassword ? "text" : "password"}
                  name={"confirmPassword"}
                  value={confirmPassword}
                  placeholder={"Confirm Password"}
                  inputClass={"w-full rounded-tr-[10px] rounded-br-[10px] border-none"}
                  iconAreaClass={"w-[15%] bg-[#E6E6E6] rounded-tl-[10px] rounded-bl-[10px] border-none"}
                  inputDivClass={"h-[55px] sm:h-[65px]"}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  error={errors.confirmPassword}
                  touched={touched.confirmPassword}
                  showPassword={showConfirmPassword}
                  togglePassword={toggleConfirmPassword}
                />
              </div>

              <PrimaryButton
                type="submit"
                className={
                  "mt-[5%] mb-[4%] w-full sm:text-[16px] md:text-[18px] font-[500] h-[50px] sm:h-[45px] md:h-[50px] rounded-[10px] tracking-[0.15em]"
                }
                label="LOGIN"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ResetPassword;
