import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ForgetPassword from "../pages/common/ForgetPassword";
import Login from "../pages/common/Login";
import SignUp from "../pages/common/SignUp";
import OTPVerification from "../pages/common/OTPVerification";
import ResetPassword from "../pages/common/ResetPassword";
import Welcome from "../pages/common/Welcome";
import AccountSetup from "../pages/common/AccountSetup";
import MerchantOnboardLayout from "../components/Merchant/MerchantOnboarding/MerchantOnboardLayout";
import Privacy from "../components/common/Privacy";
import { useSelector } from "react-redux";

const CommonRoutes = () => {
  const token = useSelector((state) => state?.authReducer?.tokenData?.token);
  const role = useSelector(
    (state) => state?.authReducer?.tokenData?.user?.role
  );

  const [isAuthenticated, setIsAuthenticated] = useState(!!token);

  const getRedirectPath = () => {
    switch (role) {
      case "merchant":
        return "/merchant/dashboard";
      case "fundraiser":
        return "/fundraiser/dashboard";
      default:
        return "/dashboard"; // Default dashboard
    }
  };

  useEffect(() => {
    setIsAuthenticated(!!token);
  }, [token]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          !isAuthenticated ? <Login /> : <Navigate to={getRedirectPath()} />
        }
      />
      <Route path="/fundraiser/welcome" element={<Welcome />} />
      <Route path="/complete-setup" element={<AccountSetup />} />
      <Route
        path="/signup"
        element={
          !isAuthenticated ? <SignUp /> : <Navigate to={getRedirectPath()} />
        }
      />
      <Route
        path="merchant/signup"
        element={
          !isAuthenticated ? <SignUp /> : <Navigate to={getRedirectPath()} />
        }
      />
      <Route
        path="fundraiser/signup"
        element={
          !isAuthenticated ? <SignUp /> : <Navigate to={getRedirectPath()} />
        }
      />
      <Route path="/forget-password" element={<ForgetPassword />} />
      <Route path="/otp-verification" element={<OTPVerification />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route
        path="/privacy/:doc"
        element={<MerchantOnboardLayout element={<Privacy />} />}
      />
    </Routes>
  );
};

export default CommonRoutes;
