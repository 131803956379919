import React from "react";
import "./common.scss";
import { ReactComponent as CloseIcon } from "../../Assets/Icons/popupCloseIcon.svg";

const DetailsPopup = ({ icon, handleClose, content, popupClassName }) => {
  return (
    <div className="popup-container">
      <div
        className={`popup flex flex-col items-center bg-white rounded-[10px] drop-shadow-[0_3px_3px_rgba(0,0,0,0.25)] relative ${popupClassName}`}
      >
        {handleClose && (
          <CloseIcon
            className="absolute top-4 right-4 cursor-pointer"
            onClick={handleClose}
          />
        )}
        <img src={icon} className="w-[40px] h-[40px] mt-10 mb-4" />
        <p
          className="py-2 text-[14px] sm:text-[16px] font-[400] font-montserrat text-[#303030] text-center px-4 break-words w-[99%] h-[130px] overflow-y-scroll"
          style={{ scrollbarWidth: "none" }}
        >
          {content ? content : "No content was added!"}
        </p>
      </div>
    </div>
  );
};

export default DetailsPopup;
