import React from 'react'
import Header from './header'
import TimelineSidebar from './TimelineSidebar';
import ContentBody from './body';
import { useLocation } from 'react-router-dom';

const OnBoardLayout = ({element}) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const titles = [{name: "Business Profile", completed: true}, {name: "More Information", completed: currentPath.includes("/more-information") || currentPath.includes("/submit-information")? true: false}, {name: "Submit Information", completed: currentPath.includes("/submit-information") ? true: false}]
  return (
    <div className='w-full h-full bg-red-200'>
        <Header />
        
        <div className='flex'>
            <TimelineSidebar titles={titles}/>
            <ContentBody element={element} />
        </div>
    </div>
  )
}

export default OnBoardLayout;