import React from "react";

const GreyButton = ({ label, className, onClick }) => {
  return (
    <button
      className={`bg-[#F0F0F0] text-[#8C8C8C] ${
        className ? className : null
      }`}
      onClick={onClick}
      style={{ border: "1px solid #C4C4C4" }}
    >
      {label}
    </button>
  );
};

export default GreyButton;
