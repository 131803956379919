import React from "react";

const GreyOutlineButton = ({ label, className, onClick }) => {
  return (
    <button
      className={`bg-[white] border-[1px] border-[#8C8C8C] rounded-[5px] text-[#8C8C8C] font-poppins ${
        className ? className : null
      }`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default GreyOutlineButton;
