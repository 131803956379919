import React, { useState, useEffect } from "react";
import { ReactComponent as SuffixIcon } from "../../../../Assets/Icons/TimeSuffixIcon.svg";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import Checkbox from "../../../common/Checkbox";
import { ReactComponent as UncheckedIcon } from "../../../../Assets/Icons/uncheckedBlackBox.svg";

const TimeTable = ({
  label,
  labelClass,
  openClass,
  closeClass,
  dayClass,
  timeDivClass,
  toClass,
  timePickerClass,
  width,
  format,
  timeData,
  handleTimeChange,
  error,
  touched,
  setFieldValue,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleChecked = (index) => {
    if (timeData[index].isSelected === false) {
      setFieldValue(`timeData.${index}.openTime`, dayjs("09:00 AM", format));
      setFieldValue(`timeData.${index}.closeTime`, dayjs("05:00 PM", format));
    } else {
      setFieldValue(`timeData.${index}.openTime`, undefined);
      setFieldValue(`timeData.${index}.closeTime`, undefined);
    }
    setFieldValue(`timeData.${index}.isSelected`, !timeData[index].isSelected);
  };
  return (
    <>
      <div className="mt-5" style={{ width: width }}>
        <p className={labelClass}>{label}</p>
      </div>
      {timeData?.map((day, index) => (
        <div className="flex flex-col">
          {index == 0 && (
            <div className="flex justify-evenly mb-3">
              <span className={openClass}>Open Time</span>
              <span className={closeClass}>Close Time</span>
            </div>
          )}
          <div
            className="flex justify-between items-center mb-2 lg:mb-2 w-full timetable"
            key={index}
          >
            <span className={dayClass}>{day?.day?.charAt(0).toUpperCase() + day?.day?.slice(1)}</span>
            <div className={timeDivClass}>
              <div className="w-full flex flex-col">
                <TimePicker
                  className={timePickerClass}
                  allowClear={false}
                  use12Hours={true}
                  format={format}
                  suffixIcon={<SuffixIcon />}
                  value={day?.openTime}
                  onChange={(time, timeString) => {
                    handleTimeChange(index, "openTime", time);
                  }}
                  disabled={day?.isSelected === false}
                />
                {error && touched ? (
                  <span className="text-red-600 text-[10px] font-[600] ms-1">
                    {error[index]?.openTime}
                  </span>
                ) : null}
              </div>
              <div className={toClass}>to</div>
              <div className="w-full flex flex-col">
                <TimePicker
                  className={timePickerClass}
                  allowClear={false}
                  use12Hours={true}
                  format={format}
                  suffixIcon={<SuffixIcon />}
                  value={day?.closeTime}
                  onChange={(time, timeString) =>
                    handleTimeChange(index, "closeTime", time)
                  }
                  disabled={day?.isSelected === false}
                />
                {error && touched ? (
                  <span className="text-red-600 text-[10px] font-[600] ms-1">
                    {error[index]?.closeTime}
                  </span>
                ) : null}
              </div>
              <div className="sm:w-0 md:w-[10%]"></div>
            </div>
            {/* <img className="mb-1" src={TimeTableEllipse} /> */}
            <div className="">
              <Checkbox
                divClassName="flex items-start"
                checked={day?.isSelected}
                onChecked={() => handleChecked(index)}
                UncheckedIcon={UncheckedIcon}
              />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default TimeTable;
