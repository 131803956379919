import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  createPotentialMerchant,
  getMerchantInvites,
  getPotentialMerchant,
  getReasons,
  merchantOnboard,
  saveMerchantData,
  sendFundraiserInvite,
  sendInvite,
  startOnboarding,
  createReason,
  fundraiserOnboard,
} from "../../services/onboardingService";
import toast from "../../utils/toast";

const initialState = {
  merchantInviteSent: null,
  allInvites: null,
  startedOnboarding: null,
  savedMerchant: null,
  potentialMerchant: null,
  createdMerchant: null,
  onboardedMerchant: null,
  fundraiserInviteSent: null,
  allReasons: null,
  reasonCreated: null,
  onboardedFundraiser: null,
  loading: null,
};

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sendInvite?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.merchantInviteSent = action?.payload?.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(getMerchantInvites?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.allInvites = action?.payload?.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(startOnboarding?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.startedOnboarding = action?.payload?.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(saveMerchantData?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.savedMerchant = action?.payload?.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(getPotentialMerchant?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.potentialMerchant = action?.payload?.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(createPotentialMerchant?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.createdMerchant = action?.payload?.data;
        toast.success("Merchant Successfully Onboarded!");
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(merchantOnboard?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.onboardedMerchant = action?.payload?.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(sendFundraiserInvite?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.fundraiserInviteSent = action?.payload?.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(getReasons?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.allReasons = action?.payload?.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(createReason?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.reasonCreated = action?.payload?.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(fundraiserOnboard?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.onboardedFundraiser = action?.payload?.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder
      .addMatcher(
        isAnyOf(
          createPotentialMerchant?.pending,
          getMerchantInvites?.pending,
          getPotentialMerchant?.pending,
          merchantOnboard?.pending,
          saveMerchantData?.pending,
          sendInvite?.pending,
          startOnboarding?.pending,
          sendFundraiserInvite?.pending,
          getReasons?.pending,
          createReason?.pending,
          fundraiserOnboard?.pending
        ),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          createPotentialMerchant?.rejected,
          getMerchantInvites?.rejected,
          getPotentialMerchant?.rejected,
          merchantOnboard?.rejected,
          saveMerchantData?.rejected,
          sendInvite?.rejected,
          startOnboarding?.rejected,
          sendFundraiserInvite?.rejected,
          getReasons?.rejected,
          createReason?.rejected,
          fundraiserOnboard?.rejected
        ),
        (state, action) => {
          state.loading = false;
          toast.error(action?.payload?.message);
        }
      );
  },
});

export const {} = onboardingSlice?.actions;
export default onboardingSlice?.reducer;
