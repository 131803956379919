import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../pages/common/Dashboard";
import FeaturedForm from "../pages/common/FeaturedForm";
import Withdrawals from "../pages/Fundraiser/Withdrawals";
import Withdraw from "../pages/Fundraiser/Withdraw";
import FundsDeposited from "../pages/Fundraiser/FundsDeposited";
import AddBank from "../pages/Fundraiser/AddBank";
import BanksList from "../pages/Fundraiser/BanksList";
import FundraiserReferralList from "../pages/Fundraiser/FundraiserReferralList";
import ReferForm from "../pages/Fundraiser/ReferForm";
import Assets from "../pages/Fundraiser/Assets";
import Marketing from "../pages/Fundraiser/Marketing";
import FundraiserGettingStarted from "../pages/Fundraiser/GettingStarted";
import FundraiserCreateAccount from "../pages/Fundraiser/CreateAccount";
import FundraiserAccountCreated from "../pages/Fundraiser/AccountCreated";
import FundraiserAccountLogin from "../pages/Fundraiser/AccountLogin";
import FundraiserSetUpAccount from "../pages/Fundraiser/SetUpAccount";
import MerchantOnboardLayout from "../components/Merchant/MerchantOnboarding/MerchantOnboardLayout";
import { useSelector } from "react-redux";

const FundraiserRoutes = () => {
  const token = useSelector((state) => state?.authReducer?.tokenData?.token);
  const role = useSelector(
    (state) => state?.authReducer?.tokenData?.user?.role
  );

  const [isAuthenticated, setIsAuthenticated] = useState(!!token);

  const getRedirectPath = () => {
    switch (role) {
      case "merchant":
        return "/merchant/dashboard";
      case "fundraiser":
        return "/fundraiser/dashboard";
      default:
        return "/dashboard"; // Default dashboard
    }
  };

  useEffect(() => {
    setIsAuthenticated(!!token);
  }, [token]);

  return (
    <Routes>
      <Route
        path="/fundraiser/dashboard"
        element={<FeaturedForm element={<Dashboard />} />}
      />
      <Route
        path="/fundraiser/deposited-funds"
        element={<FeaturedForm element={<FundsDeposited />} />}
      />
      <Route
        path="/fundraiser/withdrawals"
        element={<FeaturedForm element={<Withdrawals />} />}
      />
      <Route
        path="/fundraiser/withdraw"
        element={<FeaturedForm element={<Withdraw />} />}
      />
      <Route
        path="/fundraiser/add-bank"
        element={<FeaturedForm element={<AddBank />} />}
      />
      <Route
        path="/fundraiser/banks-list"
        element={<FeaturedForm element={<BanksList />} />}
      />
      <Route
        path="/fundraiser/refer-merchant"
        element={<FeaturedForm element={<ReferForm />} />}
      />
      <Route
        path="/fundraiser/referral-list"
        element={<FeaturedForm element={<FundraiserReferralList />} />}
      />
      <Route
        path="/fundraiser/assets"
        element={<FeaturedForm element={<Assets />} />}
      />
      <Route
        path="/fundraiser/marketing"
        element={<FeaturedForm element={<Marketing />} />}
      />
      <Route
        path="/fundraiser/getting-started"
        element={
          <MerchantOnboardLayout
            element={
              !isAuthenticated ? (
                <FundraiserGettingStarted />
              ) : (
                <Navigate to={getRedirectPath()} />
              )
            }
          />
        }
      />
      <Route
        path="/fundraiser/account-setup"
        element={<FundraiserSetUpAccount />}
      />
      <Route
        path="/fundraiser/welcome-onboard/:verificationCode"
        element={
          <MerchantOnboardLayout element={<FundraiserCreateAccount />} />
        }
      />
      <Route
        path="/fundraiser/account-created"
        element={
          <MerchantOnboardLayout element={<FundraiserAccountCreated />} />
        }
      />
      <Route
        path="/fundraiser/account-login"
        element={<MerchantOnboardLayout element={<FundraiserAccountLogin />} />}
      />
    </Routes>
  );
};

export default FundraiserRoutes;
