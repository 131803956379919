import React from "react";
import AddIcon from "../../Assets/Icons/AddIcon.svg";
import { useNavigate } from "react-router-dom"

const CreateNewButton = ({ text, navigateTo, iconClassName }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(navigateTo);
  }
  return (
    <div className="flex items-center w-[40%] justify-end">
      <button className="relative sm:w-[160px] lg:w-[188px] h-[38px] rounded-[5px] bg-[#00ADEF] sm:text-[12px] lg:text-[14px] font-[600] font-nunito text-[#FFFFFF] text-center pl-[0.75rem]" onClick={() => handleClick()}>
        {text}
        <img src={AddIcon} className={`absolute ${iconClassName}`} />
      </button>
    </div>
  );
};

export default CreateNewButton;
