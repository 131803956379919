import React from 'react'
import SwoodleNavLogo from '../../../Assets/Images/swoodleLogo.svg'

const FundraiserHeader = ({brandLogo}) => {
  return (
    <div className={`bg-[#00ADEF] px-[4%] 2xl:px-[3%] py-[2%] sm:py-[1%] ${brandLogo ? 'flex justify-between' : null}`}>
        {brandLogo ? <img src={brandLogo} className='h-[35px] sm:h-[45px] w-auto rounded-md' ></img> : null}
        <img src={SwoodleNavLogo} className='w-[30%] sm:w-[20%] lg:w-[12%] 2xl:w-[9%]' ></img>
    </div>
  )
}

export default FundraiserHeader;