
import { useState } from "react";
import Navbar from "../../components/common/Navbar";
import CustomSidebar from "../../components/common/Sidebar/Sidebar";

const FeaturedForm = ({element}) => {

    const [isSideBarOpen, setisSideBarOpen] = useState(true);

    return(
        <div className="flex h-full w-full fixed">
            <CustomSidebar isSideBarOpen={isSideBarOpen} setisSideBarOpen={setisSideBarOpen} />
            <Navbar isSideBarOpen={isSideBarOpen} setisSideBarOpen={setisSideBarOpen} element={element} />
        </div>
    )
}

export default FeaturedForm;