import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import FundraiserHeader from "../../User/common/FundraiserHeader";
import FundraiserBodyHeader from "../../User/common/FundraiserBodyHeader";
import SubscriptionPlan from "../common/SubscriptionPlan";
import {
  getUserSubscription,
  getSubscriptionPlans,
} from "../../../services/subscriptionService";
import { setSelectedPlan } from "../../../redux/slices/subscriptionSlice";
import { setToken } from "../../../utils/connectStore";
import Loader from "../../common/Loader";
import toast from "../../../utils/toast";

const SubscriptionDetails = () => {
  const [fromApp, setFromApp] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [premiumPlan, setPremiumPlan] = useState({});
  const [basicPlan, setBasicPlan] = useState({});
  const loading = useSelector((state) => state.subscriptionReducer.loading);

  const fetchPlans = async () => {
    try {
      const subPlans = await dispatch(getSubscriptionPlans({})).unwrap();
      if (subPlans?.success === true && subPlans?.data?.length > 0) {
        setPremiumPlan(subPlans?.data[0]);
        setBasicPlan(subPlans?.data[1]);
      }
    } catch (error) {
      console.error("Error fetching subscription plans:", error);
    }
  };

  const navigation = (subscriptionData) => {
    if (subscriptionData?.length > 0) {
      navigate("/existing-subscription");
    } else {
      navigate("/fundraiser-subscription");
    }
  };

  const getImmediateExistingSub = async () => {
    
    const result = await dispatch(getUserSubscription()).unwrap();
    if (result?.success === true && result?.data?.length > 0) {
      toast.warning("You already have an active subscription!")
      navigate("/existing-subscription");
    }
  };

  useEffect(() => {
    const token = searchParams?.get("token");
    if (token !== null) {
      setFromApp(true);
      setToken(token);
      getImmediateExistingSub();
    }

    fetchPlans();
  }, []);

  const handleJoinClick = async (plan) => {
    dispatch(setSelectedPlan(plan));
    if (fromApp == true) {
      const result = await dispatch(getUserSubscription()).unwrap();
      if (result?.success === true) {
        navigation(result?.data);
      }
    } else {
      navigate("/membership-activation");
    }
  };

  return (
    <div className="w-full h-full">
      <FundraiserHeader />
      {loading && <Loader />}
      {!loading && (
        <>
          <div className="bg-[#F4F4F4] py-10 flex flex-col items-center gap-12">
            <FundraiserBodyHeader
              heading={"Memberships"}
              content={
                "Select a Swoodle Membership and contribute 20% of the proceeds to foundation!"
              }
              contentClass={"w-[100%] sm:w-[80%] md:w-[90%] text-[#8C8C8C]"}
            />

            <div className="flex flex-col sm:flex-row w-full items-center justify-center gap-5">
              <SubscriptionPlan
                title={basicPlan?.name}
                plans={basicPlan?.description?.split(",")}
                price={basicPlan?.amount}
                duration={basicPlan?.duration}
                joinClick={() => handleJoinClick(basicPlan)}
              />
              <SubscriptionPlan
                title={premiumPlan?.name}
                plans={premiumPlan?.description?.split(",")}
                price={premiumPlan?.amount}
                duration={premiumPlan?.duration}
                joinClick={() => handleJoinClick(premiumPlan)}
              />
            </div>
            <p className="text-[14px] sm:text-[16px] md:text-[18px] font-poppins font-[400] mt-4 text-center w-[90%]">
              By selecting a plan, you agree to our
              <span className="text-[#00ADEF] cursor-pointer" onClick={() => window.open("/privacy/toc", "_blank")}> Terms of Services </span>
              and
              <span className="text-[#00ADEF] cursor-pointer" onClick={() => window.open("/privacy/policy", "_blank")}> Privacy Policy</span>
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default SubscriptionDetails;
