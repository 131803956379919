import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  cancelSubscription,
  getUserSubscription,
  getSubscriptionPlans,
  checkout,
  retrieveSession,
} from "../../services/subscriptionService";
import toast from "../../utils/toast";

const initialState = {
  subscription: null,
  cancelSubscription: null,
  subscriptionPlans: null,
  selectedPlan: null,
  checkoutData: null,
  retrieveSession: null,
  loading: false
};

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: initialState,
  reducers: {
    setSelectedPlan: (state, action) => {
      state.selectedPlan = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserSubscription?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.subscription = action?.payload?.data[0];
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(cancelSubscription?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.cancelSubscription = action.payload.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(getSubscriptionPlans?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.subscriptionPlans = action.payload.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(checkout?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.checkoutData = action.payload.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(retrieveSession?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.retrieveSession = action.payload.data;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder
      .addMatcher(
        isAnyOf(
          cancelSubscription?.pending,
          getUserSubscription?.pending,
          getSubscriptionPlans?.pending,
          checkout?.pending,
          retrieveSession?.pending
        ),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          cancelSubscription?.rejected,
          getUserSubscription?.rejected,
          getSubscriptionPlans?.rejected,
          checkout?.rejected,
          retrieveSession?.rejected
        ),
        (state, action) => {
          state.loading = false;
          toast.error(action?.payload?.message);
        }
      );
  },
});

export const { setSelectedPlan } = subscriptionSlice?.actions;
export default subscriptionSlice?.reducer;
