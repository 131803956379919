import React from "react";
import { ReactComponent as CloseIcon } from "../../../Assets/Icons/popupCloseIcon.svg";
import { ReactComponent as Bullet } from "../../../Assets/Icons/FundraiserIcon.svg";
import { ReactComponent as Minus } from "../../../Assets/Icons/minusButton.svg";
import "../../common/common.scss";

const FundraiserPopup = ({ fundraisers, handleCloseFundraiser }) => {
  return (
    <div className="popup-container">
      <div
        className={`popup flex flex-col justify-evenly items-center sm:w-[470px] bg-white rounded-[10px] drop-shadow-[0_3px_3px_rgba(0,0,0,0.25)] relative pt-5 ${
          fundraisers.length > 3 ? "pb-8 h-[500px] overflow-y-scroll" : "pb-8"
        }`}
      >
        <div className="h-[3%]">
          <CloseIcon
            className="absolute top-5 right-5 cursor-pointer"
            onClick={handleCloseFundraiser}
          />
        </div>
        <div className="h-[92%] w-full flex flex-col justify-start gap-4">
          <span className="font-poppins font-[500] text-[18px] text-left pl-5">
            Fundraisers
          </span>
          <ul
            className={`flex flex-col gap-2 w-[100%] px-5 ${
              fundraisers.length > 3 ? "overflow-y-scroll" : ""
            }`}
          >
            {fundraisers?.map((f, index) => (
              <div
                className={`flex justify-center items-center w-[100%] gap-4 rounded-[10px] bg-white shadow-[0_4px_10px_0px_rgba(0,0,0,0.1)] py-5 px-2 ${
                  index == fundraisers?.length - 1 ? "" : ""
                }`}
              >
                <Bullet className="w-[10%]" />
                <li className="w-[90%] font-poppins font-[400] text-[14px] text-[#303030] pr-1">
                  {f?.user?.name}
                </li>
                <Minus className="cursor-pointer" />
              </div>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FundraiserPopup;
