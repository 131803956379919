import React from 'react'
import SwoodleNavLogo from '../../../Assets/Images/swoodleLogo.svg'

const FundraiserBodyHeader = ({heading, content, contentClass}) => {
  return (
    <div className='flex flex-col items-center text-center mt-12'>
        <img src={SwoodleNavLogo} className='w-[220px] mb-8' ></img>
        <p className='text-[22px] sm:text-[30px] font-[400] font-poppins text-[#303030] mb-2' >{heading}</p>
        <p className={`text-[16px] sm:text-[18px] font-[400] font-poppins ${contentClass ? contentClass : ''}`}>{content}</p>
    </div>
  )
}

export default FundraiserBodyHeader;