import React from "react";
import OtpInput from "react-otp-input";

const OTP = ({ value, handleChange, inputStyle }) => {
  return (
    <div className="w-full flex justify-center mt-10 sm:mt-7 lg:mt-[7%]">
      <OtpInput
        value={value}
        onChange={handleChange}
        numInputs={6}
        renderInput={(props) => <input {...props} />}
        isInputNum={true}
        shouldAutoFocus={true}
        inputStyle={inputStyle}
        containerStyle={{
          gap: "8px",
        }}
      />
    </div>
  );
};

export default OTP;
