import React from "react";
import { useNavigate } from "react-router-dom";
import Swoodle from "../../../Assets/Images/Onboard Swoodle Logo.svg";
import Profile from "../../../Assets/Images/Profile.svg";
import PrimaryButton from "../PrimaryButton";
import GlassEffect from "../../../Assets/Images/blueGlass.svg";
import OTP from "../OTP.jsx";
import { useFormik } from "formik";
import { otpSchema } from "../../../utils/validationSchemas";
import { useDispatch, useSelector } from "react-redux";
import {
  forgetPassword,
  otpVerification,
} from "../../../services/authService.js";
import { setForgetPasswordData } from "../../../redux/slices/authSlice";

const OTPVerification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const forgetData = useSelector(
    (state) => state?.authReducer?.forgetPasswordData
  );

  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: otpSchema,
    onSubmit: async (values) => {
      try {
        const otpPromise = await dispatch(
          otpVerification({ otp: values?.otp, email: forgetData?.email })
        )?.unwrap();
        if (otpPromise?.success == true) {
          navigate("/reset-password");
        }
      } catch (error) {
        console.log("---err---", error);
      }
      // Handle form submission
      console.log(values);
    },
  });

  const { otp } = formik.values;

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = formik;

  const onOtpChange = (otpValue) => {
    setFieldValue("otp", otpValue);
  };

  const handleResendClick = async () => {
    setFieldValue("otp", "");
    const forgetPromise = await dispatch(
      forgetPassword({ email: forgetData?.email })
    )?.unwrap();
    if (forgetPromise?.success === true) {
      dispatch(setForgetPasswordData({ email: forgetData?.email }));
    }
  };

  return (
    <form
      className="ForgetPassSection bg-[#00ADEF80] py-16 h-[100vh]"
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col items-center">
        <div className="pb-[5%]">
          <img src={Swoodle}></img>
        </div>

        <div className="relative sm:w-[380px] md:w-[415px] lg:w-[415px] xl:w-[415px] sm:mt-14 md:mt-12 lg:mt-8 2xl:mt-3">
          <img src={GlassEffect}></img>
          <div className="w-full absolute z-[1] top-0">
            <div className="flex justify-center mt-[-12%]">
              <img src={Profile} className="w-[26%] h-[26%]"></img>
            </div>

            <p className="sm:text-[18px] md:text-[20px] font-[600] text-center text-white font-poppins">
              OTP Verification
            </p>

            <div className="w-[90%] m-auto">
              <p className="text-white text-[14px] font-[500] font-poppins sm:mt-7 lg:mt-[7%] text-center">
                Enter the 6 digit numbers sent to your email
              </p>
              <OTP
                value={otp}
                handleChange={onOtpChange}
                inputStyle={{
                  border: "1.5px solid white",
                  borderRadius: "6px",
                  width: "50px",
                  height: "64px",
                  fontFamily: "Inter, sans-serif",
                  fontSize: "20px",
                  color: "white",
                  background: "transparent",
                  fontWeight: "400",
                  caretColor: "#00ADEF",
                  outline: "none",
                }}
              />
              <p className="text-white text-[16px] font-[500] font-poppins sm:mt-7 lg:mt-[7%] text-center">
                If you didn’t receive code,{" "}
                <span
                  className="underline font-[700] cursor-pointer"
                  onClick={handleResendClick}
                >
                  Resend
                </span>
              </p>

              <PrimaryButton
                type="submit"
                className={
                  "mt-3 sm:mt-7 w-[100%] text-[18px] font-[600] h-[50px] sm:h-[45px] md:h-[50px] rounded-[10px]"
                }
                label="SET NEW PASSWORD"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default OTPVerification;
