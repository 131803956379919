import React, { useEffect, useState } from "react";
import BreadCrumsHeader from "../../common/BreadCrums";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import Pagination from "../../common/TablePagination";
import "./WithdrawmoneyTransactions.scss";
import SearchBar from "../../common/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { getWithdrawals } from "../../../services/fundraiserService";
import Loader from "../../common/Loader";
import { separateDateTimeAndConvertToLocale } from "../../../utils/utilFunctions";

const WithdrawalsDataTable = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.fundraiserReducer?.loading);
  const [allWithdrawals, setAllWithdrawals] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [disableNext, setDisableNext] = useState(false);

  const getAllWithdrawals = async (endingBefore, startingAfter) => {
    if (endingBefore !== undefined) {
      const withdrawalsPromise = await dispatch(
        getWithdrawals({ endingBefore: endingBefore })
      )?.unwrap();
      if (
        withdrawalsPromise?.success === true &&
        withdrawalsPromise?.data?.length > 0
      ) {
        setAllWithdrawals(withdrawalsPromise?.data);
      } else {
        setDisableNext(true);
      }
    } else if (startingAfter !== undefined) {
      const withdrawalsPromise = await dispatch(
        getWithdrawals({ startingAfter: startingAfter })
      )?.unwrap();
      if (
        withdrawalsPromise?.success === true &&
        withdrawalsPromise?.data?.length > 0
      ) {
        setAllWithdrawals(withdrawalsPromise?.data);
      } else {
        setDisableNext(true);
      }
    } else {
      const withdrawalsPromise = await dispatch(getWithdrawals())?.unwrap();
      if (
        withdrawalsPromise?.success === true &&
        withdrawalsPromise?.data?.length > 0
      ) {
        setAllWithdrawals(withdrawalsPromise?.data);
      } else {
        setDisableNext(true);
      }
    }
  };

  useEffect(() => {
    getAllWithdrawals();
  }, []);

  const items = [
    {
      title: "Transactions",
    },
    {
      title: "Withdrawals",
    },
  ];

  const columns = [
    {
      name: "No.",
    },
    {
      name: "Date",
    },
    {
      name: "Arrival Date",
    },
    {
      name: "Withdrawal Amount",
    },
    {
      name: "Status",
    },
  ];

  const handlePreviousChange = () => {
    getAllWithdrawals(allWithdrawals[0]?.id, undefined);
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextChange = () => {
    getAllWithdrawals(
      undefined,
      allWithdrawals[allWithdrawals?.length - 1]?.id
    );
    setCurrentPage((prevPage) => prevPage + 1);
  };

  return (
    <>
      {loading === true && <Loader />}
      <div className="flex flex-col justify-between col-span-12">
        <BreadCrumsHeader heading="Withdrawals" items={items} />
        <SearchBar
          divClassName="relative"
          inputClassName="searchBar mt-[4%] p-3 sm:w-[50%] lg:w-[33%] text-[14px] font-poppins font-[400] outline-none"
          iconClassName="absolute sm:left-[43%] sm:bottom-[20%] lg:left-[29%] xl:left-[30.5%] lg:bottom-[15%]"
          placeholder="Search"
        />
      </div>

      <div className="col-span-12">
        <TableContainer className="w-[100%]">
          <Table
            sx={{ borderCollapse: "separate", borderSpacing: "0px 8px" }}
            aria-label="simple table"
          >
            <TableHead className="bg-[#00ADEF] ">
              <TableRow
                className="h-[50px]"
                sx={{ boxShadow: "0px 4px 4px 0px #00000040" }}
              >
                {columns.map((column) => {
                  return <TableCell>{column.name}</TableCell>;
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {allWithdrawals?.map((withdrawal, index) => {
                return (
                  <TableRow
                    className="h-[50px]"
                    sx={{
                      boxShadow: "0px 4px 4px 0px #00000040",
                      backgroundColor:
                        (index + 1) % 2 !== 0 ? "#F5F5F5" : "#FFFFFF",
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {
                        separateDateTimeAndConvertToLocale(withdrawal?.created)
                          ?.localDate
                      }
                    </TableCell>
                    <TableCell>
                      {
                        separateDateTimeAndConvertToLocale(
                          withdrawal?.arrivalDate
                        )?.localDate
                      }
                    </TableCell>
                    <TableCell>{withdrawal?.amount}</TableCell>
                    <TableCell>{withdrawal?.status}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          totalPages={undefined}
          disableNext={disableNext}
        />
      </div>
    </>
  );
};

export default WithdrawalsDataTable;
