import React, { useState, useMemo, useEffect } from "react";
import "./business.scss";
import MerchantFormInput from "../Common/FormInput";
import { ReactComponent as DownArrow } from "../../../../Assets/Icons/Down Arrow.svg";
import PhoneFormInput from "../../../common/PhoneInput";
import { useNavigate } from "react-router-dom";
import Selector from "../../../common/Selector";
import { Country, State, City } from "country-state-city";
import { useFormik } from "formik";
import { businessProfileSchema } from "../../../../utils/validationSchemas";
import { useDispatch, useSelector } from "react-redux";
import {
  getPotentialMerchant,
  saveMerchantData,
} from "../../../../services/onboardingService";
import { getCategories } from "../../../../services/offerService";
import GoogleMaps from "../../../common/GoogleMaps";
import PrimaryOutlineButton from "../../../common/PrimaryOutlineButton";
import PrimaryButton from "../../../common/PrimaryButton";
import Loader from "../../../common/Loader";

const BusinessProfileSection = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.onboardingReducer?.loading);
  const merchantData = useSelector(
    (state) => state?.onboardingReducer?.startedOnboarding
  );
  const navigate = useNavigate();
  const [categories, setCategories] = useState();
  const [placeId, setPlaceId] = useState(null);
  const [showMap, setShowMap] = useState(false);
  const [categoryIds, setCategoryIds] = useState([]);
  const [merchant, setMerchant] = useState();

  const handleLocationClick = () => {
    setShowMap(true);
  };

  const handleLocationSelect = (selectedLocation) => {
    setFieldValue("location", selectedLocation?.placeName);
    setPlaceId(selectedLocation?.placeId);
  };

  const getMerchantData = async () => {
    const merchantPromise = await dispatch(
      getPotentialMerchant({ id: merchantData?._id })
    )?.unwrap();
    if (merchantPromise?.success === true) {
      setMerchant(merchantPromise?.data);
    }
  };

  const formik = useFormik({
    initialValues: {
      business: "",
      abn: "",
      location: "",
      websiteURL: "",
      merchantCategory: [],
      primaryFirstName: "",
      primaryLastName: "",
      primaryEmail: "",
      primaryContact: "",
      marketFirstName: "",
      marketLastName: "",
      marketEmail: "",
      marketContact: "",
    },
    validationSchema: businessProfileSchema,
    onSubmit: async (values) => {
      // Handle form submission
      console.log(values);
      const payload = {
        id: merchantData?._id,
        businessName: values?.business,
        abnNo: values?.abn,
        googlePlaceId: placeId,
        website: values?.websiteURL,
        merchantCategory: categoryIds,
        primaryContactDetails: {
          firstName: values?.primaryFirstName,
          lastName: values?.primaryLastName,
          email: values?.primaryEmail,
          phone: values?.primaryContact,
        },
        marketingContactDetails: {
          firstName: values?.marketFirstName,
          lastName: values?.marketLastName,
          email: values?.marketEmail,
          phone: values?.marketContact,
        },
      };
      const savePromise = await dispatch(saveMerchantData(payload))?.unwrap();
      if (savePromise?.success === true) {
        navigate("/more-information");
      }
    },
  });

  const {
    business,
    abn,
    location,
    websiteURL,
    merchantCategory,
    primaryFirstName,
    primaryLastName,
    primaryEmail,
    primaryContact,
    marketFirstName,
    marketLastName,
    marketEmail,
    marketContact,
  } = formik.values;

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
    setValues,
  } = formik;

  const getMerchantCategoriesData = async () => {
    const categoryPromise = await dispatch(getCategories())?.unwrap();
    if (categoryPromise?.success === true) {
      setCategories(categoryPromise?.data);
    }
  };

  const merchantCategoryOptions = categories?.map((cat) => ({
    value: cat?.title ?? "abc",
    label: cat?.title ?? "dsc",
  }));

  useEffect(() => {
    getMerchantData();
    getMerchantCategoriesData();
  }, []);

  const handleCategoryChange = (e) => {
    if (e) {
      setFieldValue("merchantCategory", e);
    }
  };

  useEffect(() => {
    if (merchantCategory?.length !== 0 && categories?.length !== 0) {
      const catIds = merchantCategory?.map((merchantCategoryName) => {
        const matchingCategories = categories?.filter(
          (cat) => cat?.title === merchantCategoryName
        );
        return matchingCategories?.map((cat) => cat?._id);
      });
      setCategoryIds(catIds?.flat());
    }
  }, [merchantCategory, categories]);

  useEffect(() => {
    if (merchant !== undefined && merchant !== null) {
      setPlaceId(merchant?.location?.googlePlaceId);
      setValues({
        business: merchant?.businessName,
        abn: merchant?.abnNo,
        location: merchant?.location?.address,
        websiteURL: merchant?.website,
        merchantCategory: merchant?.merchantCategory?.map((cat) => cat?.title),
        primaryFirstName: merchant?.primaryContactDetails?.firstName,
        primaryLastName: merchant?.primaryContactDetails?.lastName,
        primaryEmail: merchant?.primaryContactDetails?.email,
        primaryContact: merchant?.primaryContactDetails?.phone,
        marketFirstName: merchant?.marketingContactDetails?.firstName,
        marketLastName: merchant?.marketingContactDetails?.lastName,
        marketEmail: merchant?.marketingContactDetails?.email,
        marketContact: merchant?.marketingContactDetails?.phone,
      });
    }
  }, [merchant]);

  const sameAsAbove = () => {
    var checkBox = document.getElementById("same");

    if (checkBox.checked) {
      setFieldValue("marketFirstName", primaryFirstName);
      setFieldValue("marketLastName", primaryLastName);
      setFieldValue("marketEmail", primaryEmail);
      setFieldValue("marketContact", primaryContact);
    } else {
      setFieldValue("marketFirstName", "");
      setFieldValue("marketLastName", "");
      setFieldValue("marketEmail", "");
      setFieldValue("marketContact", "");
    }
  };

  const saveClick = async () => {
    const payload = {
      id: merchantData?._id,
      businessName: business,
      abnNo: abn,
      googlePlaceId: placeId,
      website: websiteURL,
      merchantCategory: categoryIds,
      primaryContactDetails: {
        firstName: primaryFirstName,
        lastName: primaryLastName,
        email: primaryEmail,
        phone: primaryContact,
      },
      marketingContactDetails: {
        firstName: marketFirstName,
        lastName: marketLastName,
        email: marketEmail,
        phone: marketContact,
      },
    };
    const savePromise = await dispatch(saveMerchantData(payload))?.unwrap();
    if (savePromise?.success === true) {
      window.history.pushState(null, null, window.location.href);
      window.location.href = "https://swoodle.wpenginepowered.com/";
    }
  };

  return (
    <div className="px-9 py-10">
      {loading === true && <Loader />}
      <div className="">
        <p className="lg:text-[20px] sm:text-[18px] font-[500] text-[#303030] font-poppins">
          Business profile
        </p>
      </div>

      <MerchantFormInput
        label={"Business Name"}
        width={"100%"}
        name="business"
        value={business}
        handleChange={handleChange}
        handleBlur={handleBlur}
        error={errors.business}
        touched={touched.business}
      />

      <MerchantFormInput
        type={"number"}
        label={"ABN No."}
        width={"100%"}
        name="abn"
        value={abn}
        handleChange={handleChange}
        handleBlur={handleBlur}
        error={errors.abn}
        touched={touched.abn}
      />
      <MerchantFormInput
        label={"Location"}
        width={"100%"}
        name="location"
        value={location}
        handleChange={handleChange}
        handleBlur={handleBlur}
        error={errors.location}
        touched={touched.location}
        onClick={handleLocationClick}
      />
      {showMap && (
        <GoogleMaps
          onLocationSelect={handleLocationSelect}
          searchContainerClass="sm:w-[35%] md:w-[54%] lg:w-[60%] xl:w-[60%] 2xl:w-[64%] sm:left-[50%] md:left-[35%] lg:left-[29%] xl:left-[28%] 2xl:left-[24%]"
        />
      )}

      <MerchantFormInput
        label={"Website URL"}
        width={"100%"}
        name="websiteURL"
        value={websiteURL}
        handleChange={handleChange}
        handleBlur={handleBlur}
        error={errors.websiteURL}
        touched={touched.websiteURL}
      />
      <div className="selectBusiness">
        <Selector
          value={merchantCategory}
          label={"Merchant Category"}
          labelClassName="lg:text-[14px] sm:text-[12px] font-[400] text-[#303030] mb-2 font-poppins lg:leading-[27px] sm:leading-[21px]"
          divClassName="mt-5 w-full multipleField"
          selectClassName="w-full rounded-[8px] bg-[#FFFFFF] text-[#303030] lg:text-[16px] sm:text-[10px] font-[400] outline-none !font-poppins !focus:outline-none !border-none shadow-[0_4px_4px_0px_rgba(0,0,0,0.06)]"
          flexClassName="gap-1"
          options={merchantCategoryOptions}
          suffixIcon={<DownArrow />}
          placeholder={"Select Category"}
          onChange={handleCategoryChange}
          handleBlur={handleBlur}
          error={errors.merchantCategory}
          touched={touched.merchantCategory}
          multiple={true}
        />
      </div>

      {/* Contact Details Section */}

      <div className="mt-9 mb-7">
        <p className="lg:text-[20px] sm:text-[18px] font-[500] text-[#303030] mb-2 font-poppins">
          Add contact details
        </p>
        <p className="lg:text-[16px] sm:text-[12px] font-[400] text-[#303030] font-poppins">
          Primary Contact Details
        </p>
      </div>

      <MerchantFormInput
        label={"First Name"}
        width={"100%"}
        name="primaryFirstName"
        value={primaryFirstName}
        handleChange={handleChange}
        handleBlur={handleBlur}
        error={errors.primaryFirstName}
        touched={touched.primaryFirstName}
      />
      <MerchantFormInput
        label={"Last Name"}
        width={"100%"}
        name="primaryLastName"
        value={primaryLastName}
        handleChange={handleChange}
        handleBlur={handleBlur}
        error={errors.primaryLastName}
        touched={touched.primaryLastName}
      />
      <MerchantFormInput
        label={"Email"}
        width={"100%"}
        name="primaryEmail"
        value={primaryEmail}
        handleChange={handleChange}
        handleBlur={handleBlur}
        error={errors.primaryEmail}
        touched={touched.primaryEmail}
      />

      <PhoneFormInput
        label={"Contact no."}
        divClassName="mt-5 w-full"
        name="primaryContact"
        value={primaryContact}
        handleBlur={handleBlur}
        error={errors.primaryContact}
        touched={touched.primaryContact}
        setFieldValue={setFieldValue}
        inputStyle={{
          width: "100%",
          boxShadow: "0px 4px 4px 0px #0000000F",
          border: "none",
        }}
        containerStyle={{
          boxShadow: "0px 4px 4px 0px #0000000F",
          border: "none",
        }}
        buttonStyle={{ border: "none" }}
        buttonClass="!rounded-l-[8px] !bg-[#D9D9D9] md:!w-[8%] sm:!w-[12%] lg:!w-[6%] xl:!w-[5%] 2xl:!w-[4%]"
        containerClass="!rounded-[8px]"
        inputClass="lg:!h-[40px] md:!h-[40px] !rounded-[8px] !bg-[#FFFFFF] !text-[#303030] lg:!text-[16px] sm:!text-[14px] !font-[400] !outline-none !font-poppins md:!pl-[10%] sm:!pl-[13%] lg:!pl-[7%] xl:!pl-[6%] 2xl:!pl-[5%]"
        labelClass="lg:text-[14px] md:text-[12px] font-[400] text-[#303030] mb-3 font-poppins lg:leading-[27px] md:leading-[21px]"
        errorClass={
          "text-red-600 sm:text-[10px] md:text-[12px] font-[600] ms-1"
        }
      />

      <div className="flex justify-between mt-6 ">
        <div className="">
          <p className="lg:text-[16px] sm:text-[12px] font-[400] text-[#303030] font-poppins">
            Maketing Contact Details
          </p>
        </div>

        <div className="flex items-center">
          <input
            type="checkbox"
            id="same"
            className="sameCheckbox"
            onChange={sameAsAbove}
          ></input>
          <label className="lg:text-[12px] sm:text-[10px] font-[400] text-[#303030] ms-2 font-poppins">
            Same as above
          </label>
        </div>
      </div>

      <MerchantFormInput
        label={"First Name"}
        width={"100%"}
        name="marketFirstName"
        value={marketFirstName}
        handleChange={handleChange}
        handleBlur={handleBlur}
        error={errors.marketFirstName}
        touched={touched.marketFirstName}
      />
      <MerchantFormInput
        label={"Last Name"}
        width={"100%"}
        name="marketLastName"
        value={marketLastName}
        handleChange={handleChange}
        handleBlur={handleBlur}
        error={errors.marketLastName}
        touched={touched.marketLastName}
      />
      <MerchantFormInput
        label={"Email"}
        width={"100%"}
        name="marketEmail"
        value={marketEmail}
        handleChange={handleChange}
        handleBlur={handleBlur}
        error={errors.marketEmail}
        touched={touched.marketEmail}
      />

      <PhoneFormInput
        label={"Contact no."}
        divClassName="mt-5 w-full"
        name="marketContact"
        value={marketContact}
        handleBlur={handleBlur}
        error={errors.marketContact}
        touched={touched.marketContact}
        setFieldValue={setFieldValue}
        inputStyle={{
          width: "100%",
          boxShadow: "0px 4px 4px 0px #0000000F",
          border: "none",
        }}
        containerStyle={{
          boxShadow: "0px 4px 4px 0px #0000000F",
          border: "none",
        }}
        buttonStyle={{ border: "none" }}
        buttonClass="!rounded-l-[8px] !bg-[#D9D9D9] md:!w-[8%] sm:!w-[12%] lg:!w-[6%] xl:!w-[5%] 2xl:!w-[4%]"
        containerClass="!rounded-[8px]"
        inputClass="lg:!h-[40px] md:!h-[40px] !rounded-[8px] !bg-[#FFFFFF] !text-[#303030] lg:!text-[16px] sm:!text-[14px] !font-[400] !outline-none !font-poppins md:!pl-[10%] sm:!pl-[13%] lg:!pl-[7%] xl:!pl-[6%] 2xl:!pl-[5%]"
        labelClass="lg:text-[14px] md:text-[12px] font-[400] text-[#303030] mb-3 font-poppins lg:leading-[27px] md:leading-[21px]"
        errorClass={
          "text-red-600 sm:text-[10px] md:text-[12px] font-[600] ms-1"
        }
      />

      <div className=" flex justify-between text-end mt-8 w-full">
        <PrimaryOutlineButton
          label={"Save And Exit"}
          className={
            "lg:w-[30%] 2xl:w-[25%] sm:w-[35%] lg:h-[50px] sm:h-[50px] rounded-[5px]"
          }
          onClick={saveClick}
        />
        <PrimaryButton
          className={
            "lg:w-[30%] 2xl:w-[25%] sm:w-[35%] lg:h-[50px] sm:h-[50px] rounded-[5px] lg:text-[18px] sm:text-[14px] font-[500]"
          }
          label="Continue"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default BusinessProfileSection;
