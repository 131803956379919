import React from "react";
import "./dashboard.scss";
import Box from "./Box";

const AccountsDetails = () => {

  return (
    <div className="summary w-full h-full p-4 offersChart">
      <div className="flex justify-between w-full pb-[2%]">
        <span className="text-[16px] font-poppins font-[600] text-[#8C8C8C] whitespace-nowrap">
          Bank Account
        </span>
        <span className="text-[16px] font-poppins font-[600] text-[#8C8C8C] whitespace-nowrap">
          Available Funds
        </span>
      </div>
      <hr />
      <div className="flex justify-between items-end mt-[3%] w-full">
        <div className="flex flex-col">
          <p className="sm:text-[12px] md:text-[18px] lg:text-[14px] xl:text-[18px] font-poppins font-[600] text-[#303030] leading-[27px]">
            Nev Bank Current Account
          </p>
          <p className="sm:text-[12px] md:text-[16px] lg:text-[14px] xl:text-[16px] font-poppins font-[400] text-[#8C8C8C] leading-[24px]">
            AU65 AUSI 0000 0011 2345 6702
          </p>
        </div>
        <div className="flex justify-between items-end w-[33%] md:w-[28%] 2xl:w-[30%]">
          <p className="sm:text-[24px] md:text-[36px] lg:text-[20px] xl:text-[26px] 2xl:text-[36px] font-poppins font-[700] text-[#303030] flex items-end sm:leading-[36px] lg:leading-[25px] xl:leading-[30px] 2xl:leading-[34px]">
            70.678
          </p>
          <p className="sm:text-[20px] md:text-[24px] lg:text-[16px] xl:text-[20px] 2xl:text-[24px] font-poppins font-[500] text-[#8C8C8C] leading-[30px] lg:leading-[25px] xl:leading-[28px] 2xl:leading-[32px] text-end">
            $
          </p>
        </div>
      </div>
      <div className="sm:w-[70%] md:w-[60%] lg:w-[75%] xl:w-[58%] lg:mt-[15%] sm:mt-[15%] sm:mb-[10%] lg:mb-0 xl:mt-[20%]">
        <Box text="Fundraiser Link Accounts" number="253" mainClassName="box p-3 sm:pb-8 lg:pb-5 2xl:pb-8" textClassName="text-[16px] font-poppins font-[500] text-[white]" numberClassName="text-[20px] font-[700] text-[white] font-poppins"/>
      </div>
    </div>
  );
};

export default AccountsDetails;
