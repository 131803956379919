import React, { useEffect, useState } from "react";
import NotificationPopup from "./NotificationPopup";
import { fetchToken, onMessageListener, messaging } from "../../firebase";
import { setFcm } from "../../utils/connectStore";
import rejectedIcon from "../../Assets/Icons/rejectedIcon.svg";
import approvedIcon from "../../Assets/Icons/approvedIcon.svg";

const WebNotifications = () => {
  const [notification, setNotification] = useState(null);

  const permissionRequest = async () => {
    if ("Notification" in window) {
      const permission = await Notification?.requestPermission();
      if (permission === "granted") {
        //Generate Token
        if (messaging) {
          try {
            // navigator.serviceWorker.ready.then(async() => {
            const token = await fetchToken();
            console.log("Token====>", token);
            setFcm({ fcmToken: token });
            // });
          } catch (e) {
            console.log("catch the error>>>", e);
          }
        } else {
          return null;
        }
        //Send this token to your backend DB
      } else if (permission === "denied") {
        alert("Please allow notification for this website to get up to date.");
      }
    } else {
      console.log("window else");
    }
  };

  useEffect(() => {
    console.log('webNotifications');
    //Request user to show notifications
    permissionRequest();
  }, []);
  onMessageListener()
    .then((payload) => {
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
        icon: payload.notification.body.includes("approved")
          ? approvedIcon
          : rejectedIcon,
      });
      // alert(payload.notification.title + payload.notification.body);
    })
    .catch((err) => console.log("failed: ", err));
  return (
    notification !== null && (
      <NotificationPopup
        heading={notification?.title}
        content={notification?.body}
        image={notification?.icon}
        setNotification={setNotification}
      />
    )
  );
};

export default WebNotifications;
