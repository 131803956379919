import React from "react";
import "./common.scss";
import { ReactComponent as CloseIcon } from "../../Assets/Icons/popupCloseIcon.svg";

const ImagePopup = ({ image, handleCloseImage }) => {
  return (
    <div className="popup-container" onClick={handleCloseImage}>
      <div className="popup flex flex-col justify-evenly items-center w-[335px] sm:w-[470px] h-[275px] sm:h-[255px] bg-white rounded-[10px] drop-shadow-[0_3px_3px_rgba(0,0,0,0.25)] relative">
        <CloseIcon
          className="absolute top-4 right-4 cursor-pointer"
          onClick={handleCloseImage}
        />
        {image ? (
          <img src={image} className="w-[60%] h-[100%] object-contain" />
        ) : (
          <p className="font-poppins font-[500] text-[14px] text-[#303030] text-center px-4">
            No image was added for this offer. You can add it by editing this offer!
          </p>
        )}
      </div>
    </div>
  );
};

export default ImagePopup;
