import * as Yup from "yup";
import moment from "moment/moment";

const nameSchema = {
  firstName: Yup.string()
    .max(25, "First Name cannot be more than 25 characters")
    .required("First Name is required"),
  lastName: Yup.string()
    .max(25, "Last Name cannot be more than 25 characters")
    .required("Last Name is required"),
};

const emailSchema = {
  email: Yup.string()
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email address")
    .required("Email is required"),
};

const passwordSchema = {
  password: Yup.string()
    .min(8, "Password must have atleast 8 characters")
    .required("Password is required"),
};

const dateTimeSchema = {
  startDate: Yup.date()
    .required("Start date is required")
    .min(
      moment().startOf("day"),
      "Start date must be greater than or equal to today"
    ),
  startTime: Yup.date().required("Start time is required"),
  endDate: Yup.date()
    .min(
      Yup.ref("startDate"),
      "End date should be later than or equal to start date"
    )
    .required("End date is required"),
  endTime: Yup.date()
    .test(
      "min_end_time",
      "End time must be later than start time",
      function (value) {
        const { startDate, endDate, startTime, endTime } = this.parent;

        const start_date = moment(startDate).format("YYYY-MM-DD");
        const end_date = moment(endDate).format("YYYY-MM-DD");
        const start_time = moment(startTime).format("HH:mm");
        const end_time = moment(endTime).format("HH:mm");

        if (start_date === end_date) {
          return moment(end_time, "HH:mm").isAfter(moment(start_time, "HH:mm"));
        } else {
          return true;
        }
      }
    )
    .required("End time is required"),
};

export const signUpSchema = Yup.object().shape({
  ...nameSchema,
  ...emailSchema,
  ...passwordSchema,
});

export const fundraiserSignUpSchema = Yup.object().shape({
  ...nameSchema,
  ...emailSchema,
  ...passwordSchema,
  category: Yup.string().required("Category is required"),
});

export const loginSchema = Yup.object().shape({
  ...emailSchema,
  ...passwordSchema,
});

export const forgetPasswordSchema = Yup.object().shape({
  ...emailSchema,
});

export const invitationSchema = Yup.object().shape({
  ...nameSchema,
  ...emailSchema,
  business: Yup.string()
    .max(25, "Business Name cannot be more than 25 characters")
    .required("Business Name is required"),
});

export const categorySchema = Yup.object({
  category: Yup.string().required("Category is required"),
});

export const subCategorySchema = Yup.object({
  subCategory: Yup.array()
    .of(Yup.string().required("Sub Categories are required"))
    .min(1, "Atleast Select one Sub Category"),
});

export const offerCreationSchema = Yup.object({
  title: Yup.string()
    .required("Offer title is required")
    .max(25, "Offer Title cannot be more than 25 characters"),
  offerType: Yup.string().required("Offer Type Selection is required"),
  offer: Yup.string().required("Offer Selection is required"),
  savedMoney: Yup.number().required("Saved Money is required"),
  offerDescription: Yup.string()
    .required("Offer Description is required")
    .max(500, "Offer Description cannot be more than 500 characters"),
  range: Yup.number().min(0).max(100).required("Range is required"),
  thumbnail: Yup.string().required("Thumbnail is required"),
  previewImgs: Yup.array()
    .of(Yup.string().required("Preview Images are required"))
    .min(1, "Upload atleast one preview image."),
});

export const customizeOfferSchema = Yup.object({
  rules: Yup.string().required("Select exactly one rule."),
  offerTimeCheck: Yup.boolean().oneOf(
    [true, false],
    "You must add all the Times"
  ),
  ...dateTimeSchema,
  days: Yup.array()
    .of(Yup.string().required("Days are required"))
    .min(1, "At least select one Day!"),
});

export const rulesOfferSchema = Yup.object({
  rules: Yup.array()
    .of(Yup.string().required("Rules are required"))
    .min(1, "Atleast Select one Rule"),
});

export const franchiseOfferSchema = Yup.object().shape({
  franchiseName: Yup.string()
    .max(50, "Franchise Name cannot be more than 25 characters")
    .required("First Name is required"),
  ...emailSchema,
  contact: Yup.string().required("Contact is required"),
  location: Yup.string().required("Location is required"),
  timeSet: Yup.boolean().oneOf([false], "You must select at least one day"),
  timeData: Yup.array().of(
    Yup.object().shape({
      day: Yup.string().required(""),
      openTime: Yup.date().when("isSelected", {
        is: true,
        then: () => Yup.date().required("Open Time is required"),
        otherwise: () => Yup.date(), // No validation when isSelected is false
      }),
      closeTime: Yup.date().when("isSelected", {
        is: true, // Check both isSelected and openTime
        then: () =>
          Yup.date()
            .test(
              "min_close_time",
              "Close time must be later than start time",
              function (value) {
                const { openTime, closeTime } = this.parent;
                const start_time = moment(openTime).format("HH:mm");
                const end_time = moment(closeTime).format("HH:mm");

                // if (start_date === end_date) {
                return moment(end_time, "HH:mm").isAfter(
                  moment(start_time, "HH:mm")
                );
                // } else {
                //   return true;
                // }
              }
            )
            .required("Close time is required"),
        otherwise: () => Yup.date(),
      }),
      isSelected: Yup.boolean().oneOf([true, false], ""),
    })
  ),
});

export const uploadOfferSchema = Yup.object().shape({
  document: Yup.string().required("Excel document is required"),
});

export const locationOfferSchema = Yup.object({
  location: Yup.array()
    .of(Yup.string().required("Locations are required"))
    .min(1, "Atleast Select one Location"),
});

export const adminOfferSchema = Yup.object({
  title: Yup.string()
    .required("Offer title is required")
    .max(25, "Offer Title cannot be more than 25 characters"),
  category: Yup.string().required("Category type is required"),
  subCategory: Yup.string().required("Sub Category is required"),
  ...dateTimeSchema,
  offerDescription: Yup.string()
    .required("Offer Description is required")
    .max(500, "Offer Description cannot be more than 500 characters"),
  rules: Yup.string()
    .required("Rules are required")
    .max(500, "Rules and regulations cannot be more than 500 characters"),
  range: Yup.number().min(0).max(100).required("Range is required"),
  businessName: Yup.string().required("Business name is required"),
  franchise: Yup.array().required("Franchise is required"),
  document: Yup.string().required("Image document is required"),
});

export const merchantOfferSchema = Yup.object({
  title: Yup.string()
    .required("Offer title is required")
    .max(25, "Offer Title cannot be more than 25 characters"),
  category: Yup.string().required("Category type is required"),
  subCategory: Yup.string().required("Sub Category is required"),
  ...dateTimeSchema,
  offerDescription: Yup.string()
    .required("Offer Description is required")
    .max(500, "Offer Description cannot be more than 500 characters"),
  rules: Yup.string()
    .required("Rules are required")
    .max(500, "Rules and regulations cannot be more than 500 characters"),
  range: Yup.number().min(0).max(100).required("Range is required"),
  document: Yup.string().required("Image document is required"),
});

export const assetsSchema = Yup.object({
  title: Yup.string().required("Asset Title is required"),
  assetDescription: Yup.string().required("Asset Description is required"),
  selectedFundraisers: Yup.array()
    .required("Fundraisers are required")
    .min(1, "There must be at least one fundraiser"),
  ...dateTimeSchema,
  document: Yup.string().required("zip or rar file is required"),
});

export const franchiseSchema = Yup.object().shape({
  franchiseName: Yup.string()
    .max(50, "Franchise Name cannot be more than 25 characters")
    .required("Franchise Name is required"),
  ...emailSchema,
  contact: Yup.string().required("Contact is required"),
  address: Yup.string().required("Location is required"),
  timeSet: Yup.boolean().oneOf([false], "You must select at least one day"),
  timeData: Yup.array().of(
    Yup.object().shape({
      day: Yup.string().required(""),
      openTime: Yup.date().when("isSelected", {
        is: true,
        then: () => Yup.date().required("Open Time is required"),
        otherwise: () => Yup.date(), // No validation when isSelected is false
      }),
      closeTime: Yup.date().when("isSelected", {
        is: true, // Check both isSelected and openTime
        then: () =>
          Yup.date()
            .test(
              "min_close_time",
              "Close time must be later than start time",
              function (value) {
                const { openTime, closeTime } = this.parent;
                const start_time = moment(openTime).format("HH:mm");
                const end_time = moment(closeTime).format("HH:mm");
                return moment(end_time, "HH:mm").isAfter(
                  moment(start_time, "HH:mm")
                );
              }
            )
            .required("Close time is required"),
        otherwise: () => Yup.date(),
      }),
      isSelected: Yup.boolean().oneOf([true, false], ""),
    })
  ),
});

export const bankSchema = Yup.object({
  accountTitle: Yup.string().required("Account Title is required"),
  bankName: Yup.string().required("Bank Name is required"),
  branchName: Yup.string().required("Branch Name is required"),
  branchCode: Yup.number().required("Branch Code is required"),
  accountNo: Yup.number().required("Account Number is required"),
});

export const referSchema = Yup.object().shape({
  ...nameSchema,
  ...emailSchema,
  contact: Yup.string().required("Contact is required"),
  message: Yup.string().required("Message is required"),
  business: Yup.string().required("Business is required"),
  location: Yup.string().required("Location is required"),
  country: Yup.string().required("Country is required"),
  state: Yup.string().required("State is required"),
  city: Yup.string().required("City is required"),
  postCode: Yup.string().required("Post Code is required"),
  website: Yup.string().required("Website is required"),
});

export const fundraiserAssetsSchema = Yup.object({
  sale: Yup.string().required("Sale is required"),
});

export const businessProfileSchema = Yup.object().shape({
  business: Yup.string()
    .max(25, "Business Name cannot be more than 25 characters")
    .required("Business Name is required"),
  abn: Yup.string()
    .matches(/^[0-9]{2} *[0-9]{3} *[0-9]{3} *[0-9]{3}$/, "Invalid ABN format")
    .required("ABN is required"),
  location: Yup.string().required("Location is required"),
  websiteURL: Yup.string()
    .url("Invalid URL format")
    .required("Website URL is required"),
  merchantCategory: Yup.array().required("Merchant Category is required"),
  primaryFirstName: Yup.string()
    .max(25, "First Name cannot be more than 25 characters")
    .required("First Name is required"),
  primaryLastName: Yup.string()
    .max(25, "Last Name cannot be more than 25 characters")
    .required("Last Name is required"),
  primaryEmail: Yup.string()
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email address")
    .required("Email is required"),
  primaryContact: Yup.string().required("Contact is required"),
  marketFirstName: Yup.string()
    .max(25, "First Name cannot be more than 25 characters")
    .required("First Name is required"),
  marketLastName: Yup.string()
    .max(25, "Last Name cannot be more than 25 characters")
    .required("Last Name is required"),
  marketEmail: Yup.string()
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email address")
    .required("Email is required"),
  marketContact: Yup.string().required("Contact is required"),
});

export const moreInfoSchema = Yup.object().shape({
  aboutBusiness: Yup.string().required("Business Detail is required"),
  physicalStore: Yup.string().required("Please select an option"),
  timeSet: Yup.boolean().oneOf([false], "You must select at least one day"),
  timeData: Yup.array().of(
    Yup.object().shape({
      day: Yup.string().required(""),
      openTime: Yup.date().when("isSelected", {
        is: true,
        then: () => Yup.date().required("Open Time is required"),
        otherwise: () => Yup.date(), // No validation when isSelected is false
      }),
      closeTime: Yup.date().when("isSelected", {
        is: true, // Check both isSelected and openTime
        then: () =>
          Yup.date()
            .test(
              "min_close_time",
              "Close time must be later than start time",
              function (value) {
                const { openTime, closeTime } = this.parent;
                const start_time = moment(openTime).format("HH:mm");
                const end_time = moment(closeTime).format("HH:mm");

                return moment(end_time, "HH:mm").isAfter(
                  moment(start_time, "HH:mm")
                );
              }
            )
            .required("Close time is required"),
        otherwise: () => Yup.date(),
      }),
      isSelected: Yup.boolean().oneOf([true, false], ""),
    })
  ),
});

export const submitInfoSchema = Yup.object().shape({
  authCheckBox: Yup.boolean().oneOf([true], "You must accept this agreement"),
  termsCheckbox: Yup.boolean().oneOf([true], "You must accept this agreement"),
  privacyCheckbox: Yup.boolean().oneOf(
    [true],
    "You must accept privacy policy"
  ),
});

export const subscriptionSchema = Yup.object({
  category: Yup.string().required("Fundraiser Category is required"),
  fundraiser: Yup.string().required("Fundraiser is required"),
});

export const fundraiserSchema = Yup.object({});

export const membershipActivationSchema = Yup.object().shape({
  ...emailSchema,
  // confirmEmail: Yup.string()
  //   .oneOf([Yup.ref("email"), null], "Emails must match")
  //   .required("Email Confirmation is required"),
});

export const memberSetUpSchema = Yup.object().shape({
  ...nameSchema,
  ...emailSchema,
  phone: Yup.string().required("Phone Number is required"),
  age: Yup.number()
    .max(100, "Age must be at most 100")
    .required("Age is required"),
  location: Yup.string().required("Location is required"),
  ...passwordSchema,
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Password Confirmation is required"),
  club: Yup.string().max(
    25,
    "Fundraiser club name cannot be more than 25 characters"
  ),
  presidentEmail: Yup.string().matches(
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    "Invalid email address"
  ),
});

export const existingLoginSchema = Yup.object().shape({
  ...passwordSchema,
});

export const otpSchema = Yup.object().shape({
  otp: Yup.string().required("OTP is required"),
});

export const resetPasswordSchema = Yup.object().shape({
  ...passwordSchema,
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Password Confirmation is required"),
});

export const withdrawSchema = Yup.object().shape({
  amount: Yup.number().required("Amount to withdraw is required"),
  bankCheckBox: Yup.boolean().oneOf([true], "You must select a bank"),
});

export const gettingStartedSchema = Yup.object().shape({
  businessName: Yup.string()
    .max(25, "Business Name cannot be more than 25 characters")
    .required("Business Name is required"),
  businessAddress: Yup.string().required("Business Address is required"),
  logo: Yup.string().required("Logo is required"),
  websiteUrl: Yup.string()
    .url("Invalid URL format")
    .required("Website URL is required"),
  businessCategory: Yup.array()
    .of(Yup.string().required("Business Categories are required"))
    .min(1, "Atleast Select one Business Category"),
  personName: Yup.string()
    .max(25, "Contact Person Name cannot be more than 25 characters")
    .required("Contact Person Name is required"),
  ...emailSchema,
  personContact: Yup.string().required("Contact is required"),
  aboutBusiness: Yup.string()
    .required("Business Information is required")
    .max(500, "Business Information cannot be more than 500 characters"),
});

export const createMerchantAccountSchema = Yup.object().shape({
  ...nameSchema,
  ...passwordSchema,
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Password Confirmation is required"),
});

export const fundraiserMarketingSchema = Yup.object({
  logo: Yup.string().required("Logo is required"),
  aboutUs: Yup.string().required("Description is required"),
  banner: Yup.string().required("Banner is required"),
});

export const fundraiserGettingStartedSchema = Yup.object().shape({
  fundraiserName: Yup.string()
    .max(25, "Fundraiser Name cannot be more than 25 characters")
    .required("Fundraiser Name is required"),
  fundraiserAddress: Yup.string().required("Fundraiser Address is required"),
  websiteUrl: Yup.string()
    .url("Invalid URL format")
    .required("Website URL is required"),
  fundraiserCategory: Yup.array()
    .of(Yup.string().required("Fundraiser Categories are required"))
    .min(1, "Atleast Select one Fundraiser Category"),
  personName: Yup.string()
    .max(25, "Contact Person Name cannot be more than 25 characters")
    .required("Contact Person Name is required"),
  ...emailSchema,
  personContact: Yup.string().required("Contact is required"),
  aboutOrganization: Yup.string()
    .required("Organization Information is required")
    .max(500, "Organization Information cannot be more than 500 characters"),
  reasons: Yup.array()
    .of(Yup.string().required("Reasons are required"))
    .min(1, "Atleast Select one Reason"),
});

export const rejectedReasonSchema = Yup.object().shape({
  reason: Yup.string().required("Reason is required"),
});

export const redemptionSchema = Yup.object().shape({
  code: Yup.string().required("Code is required"),
});