import React, { useEffect } from "react";
import BreadCrumsHeader from "../../common/BreadCrums";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SwitchStatus from "../../common/Status";
import { useState } from "react";
import Pagination from "../../common/TablePagination";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../common/Loader";
import {
  getFundraisers,
  toggleFundraiserStatus,
} from "../../../services/fundraiserService";
import RejectOfferPopup from "../../common/RejectOfferPopup";
import crossIcon from "../../../Assets/Icons/cross.svg";

const FundraiserListDataTable = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.fundraiserReducer?.loading);
  const [allFundraisers, setAllFundraisers] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);
  const [statusToggled, setStatusToggled] = useState(false);

  const getFundraisersData = async () => {
    const fundraisersPromise = await dispatch(
      getFundraisers({ page: currentPage })
    )?.unwrap();
    if (fundraisersPromise?.success === true) {
      setAllFundraisers(fundraisersPromise?.data);
      setCurrentPage(fundraisersPromise?.currentPage);
      setTotalPages(fundraisersPromise?.totalPages);
      setCount(fundraisersPromise?.count);
    }
  };

  useEffect(() => {
    getFundraisersData();
  }, [statusToggled, currentPage]);

  const items = [
    {
      title: "Fundraisers",
    },
    {
      title: "Fundraiser List",
    },
  ];

  const columns = [
    {
      name: "No.",
    },
    {
      name: "Fundraiser ID",
    },
    {
      name: "Fundraiser Name",
    },
    {
      name: "Email",
    },
    {
      name: "Contact",
    },
    {
      name: "Balance",
    },
    // {
    //   name: "About",
    // },
    {
      name: "Action",
    },
  ];

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleActionToggled = async (merchant) => {
    const togglePromise = await dispatch(
      toggleFundraiserStatus({ id: merchant?._id })
    )?.unwrap();
    if (togglePromise?.success === true) {
      setStatusToggled(!statusToggled);
    }
  };

  return (
    <>
      {loading === true && <Loader />}
      <div className="flex justify-between col-span-12">
        <BreadCrumsHeader heading="Fundraiser List" items={items} />
      </div>

      <div className=" col-span-12 mt-8">
        <TableContainer style={{ width: "100%" }}>
          <Table
            sx={{
              minWidth: "1936px",
              borderCollapse: "separate",
              borderSpacing: "0px 8px",
            }}
            aria-label="simple table"
          >
            <TableHead className="bg-[#00ADEF]">
              <TableRow
                className="h-[50px]"
                sx={{ boxShadow: "0px 4px 4px 0px #00000040" }}
              >
                {columns.map((column) => {
                  return <TableCell>{column.name}</TableCell>;
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {allFundraisers?.map((fundraiser, index) => {
                return (
                  <TableRow
                    className="h-[50px]"
                    sx={{
                      boxShadow: "0px 4px 4px 0px #00000040",
                      backgroundColor:
                        (index + 1) % 2 !== 0 ? "#F5F5F5" : "#FFFFFF",
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{fundraiser?._id}</TableCell>
                    <TableCell>{fundraiser?.user?.name}</TableCell>
                    <TableCell>{fundraiser?.user?.email}</TableCell>
                    <TableCell>{fundraiser?.contactPersonNumber}</TableCell>
                    <TableCell>{"$" + fundraiser?.balance}</TableCell>
                    {/* <TableCell>{fundraiser?.aboutUs}</TableCell> */}
                    <TableCell>
                      <SwitchStatus
                        isEnabled={fundraiser?.user?.isEnabled}
                        handleToggle={() => handleActionToggled(fundraiser)}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          totalPages={totalPages}
          handlePageChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default FundraiserListDataTable;
