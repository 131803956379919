import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./rootReducer";

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "offerReducer",
    "authReducer.loading",
    "subscriptionReducer.loading",
    "fundraiserReducer",
    "franchiseReducer.loading",
    "offerReducer.loading",
    "merchantReducer.loading",
    "onboardingReducer.loading",
    "assetsReducer.loading",
    "notificationReducer.loading",
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: persistedReducer,
});

const onRehydrateComplete = () => {
  console.log("Rehydration complete!");
};

const persistor = persistStore(store, null, onRehydrateComplete);

export { store, persistor };
