import React from "react";
import "./fundraiserAssets.scss";
import Selector from "../../common/Selector";
import BreadCrumsHeader from "../../common/BreadCrums";
import { ReactComponent as RedNose } from "../../../Assets/Images/rednose.svg";
import { ReactComponent as DownArrow } from "../../../Assets/Icons/greyDownArrow.svg";

import { useFormik } from "formik";
import { fundraiserAssetsSchema } from "../../../utils/validationSchemas";

const FundraiserAssets = () => {

  const initialValues = {
    sale: ""
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: fundraiserAssetsSchema,

    onSubmit: (values) => {
      console.log("Bank values: ", values);
    },
  });

  const { sale } =
    formik.values;

  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
  } = formik;

  const handleSalesChange = (e) => {
    setFieldValue("sale", e)
  }

  const sales = [
    { name: "Oct 2023 - Flash Sale - 25% OFF all memberships" },
    { name: "Nov 2023 - Flash Sale - 50% OFF all memberships" },
    { name: "Dec 2023 - Flash Sale - 75% OFF all memberships" },
  ];

  const salesOptions = sales.map((country) => ({
    value: country?.name ?? "abc",
    label: country?.name ?? "dsc",
  }))
  const items = [
    {
      title: "Assets and Prmomotions",
    },
    {
      title: "View and Download",
    },
  ];

  return (
    <>
      <div className="flex flex-col gap-5">
        <RedNose />
        <BreadCrumsHeader
          heading="View and Download"
          items={items}
          form={true}
        />
      </div>
      <div className="col-span-12 mainDiv">
        <p className="sm:text-[14px] md:text-[16px] font-[500] text-[#303030] font-poppins leading-[27px]">
          Promotional Campaigns - Download these marketing materials to promote
          to your audience
        </p>
        <div className="flex sm:gap-7 md:gap-0 sm:flex-col md:flex-row justify-between lg:items-baseline w-full asset">
          <Selector
            label={""}
            labelClassName="lg:text-[14px] sm:text-[12px] font-[400] text-[#303030] mb-2 font-poppins lg:leading-[27px] sm:leading-[21px]"
            divClassName="md:w-[70%] sm:w-[100%]"
            selectClassName="w-full mt-3 rounded-[5px] bg-[#FFFFFF] text-[#303030] lg:text-[16px] sm:text-[10px] font-[400] !font-poppins !focus:outline-none"
            flexClassName="gap-7"
            options={salesOptions}
            suffixIcon={<DownArrow />}
            defaultValue={sales[0]?.name}
            onChange={handleSalesChange}
            handleBlur={handleBlur}
            error={errors.sale}
            touched={touched.sale}
          />
          <button
            type="submit"
            className="md:w-[27%] md:mt-[19px] lg:mt-0 mt-0 sm:w-[100%] h-[45px] rounded-[5px] bg-[#00ADEF] sm:text-[12px] md:text-[14px] font-[500] font-montserrat text-[#FFFFFF]"
          >
            Download Assets
          </button>
        </div>
        <p className="mt-10 font-poppins font-[600] sm:text-[18px] md:text-[20px] text-[#00ADEF] text-center">{sale ? sale : sales[0]?.name}</p>
        <p className="font-poppins font-[400] sm:text-[14px] md:text-[16px] text-[#303030] mt-6"><span className="font-[600] mr-2">Promotion Dates:</span>Wednesday, 24 Oct 2023 at 6:30 am - Friday, 26 Oct 2023 at 6:30 am</p>
        <p className="font-poppins font-[400] sm:text-[14px] md:text-[16px] text-[#303030] mt-2"><span className="font-[600] mr-2">Offer Name:</span>Flash Sale - 2023 Swoodle</p>
        <p className="font-poppins font-[400] sm:text-[14px] md:text-[16px] text-[#303030] mt-2"><span className="font-[600] mr-2">The Offer:</span>25% off all memberships - 12 month and 24 - month inclusive</p>
        <p className="font-poppins font-[400] sm:text-[14px] md:text-[16px] text-[#303030] mt-2"><span className="font-[600] mr-2">Website :</span>https:// www.swoodle.com/ current-promotion</p>
        <p className="font-poppins font-[600] sm:text-[14px] md:text-[16px] text-[#303030] mt-2">Terms and Conditions:</p>
        <p className="font-poppins font-[400] sm:text-[14px] md:text-[16px] text-[#303030] mt-2">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
        <br/>
        <p className="font-poppins font-[400] sm:text-[14px] md:text-[16px] text-[#303030]">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
        <br/>
        <p className="font-poppins font-[400] sm:text-[14px] md:text-[16px] text-[#303030]">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
        <br/>
        <p className="font-poppins font-[400] sm:text-[14px] md:text-[16px] text-[#303030]">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
        <p className="font-comfortaa font-[400] sm:text-[12px] md:text-[14px] text-[#303030] mt-10">&copy; 2023 Swoodle All Right Reserved. </p>
      </div>
    </>
  );
};

export default FundraiserAssets;
