import React, { useState } from "react";
import TimeTable from "../../User/MerchantOnboard/Common/TimeTable";
import GoogleMaps from "../../common/GoogleMaps";
import UploadExcel from "../../common/OfferForm/Location/UploadExcel";
import Input from "../../common/FormInputs";
import PhoneFormInput from "../../common/PhoneInput";

const AddFranchise = ({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  setFieldValue,
  handleTimeChange,
  format,
  uploadClicked,
  setPlaceId,
}) => {
  const [showMap, setShowMap] = useState(false);
  const [fileData, setFileData] = useState();

  const handleLocationClick = () => {
    setShowMap(true);
  };

  const handleLocationSelect = (selectedLocation) => {
    setFieldValue("address", selectedLocation?.placeName);
    setPlaceId(selectedLocation?.placeId);
  };

  const validateFile = (data) => {
    var idxDot = data.lastIndexOf(".") + 1;
    var extFile = data.substr(idxDot, data.length).toLowerCase();
    if (extFile == "xlsx" || extFile == "xls") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      {uploadClicked === true ? (
        <>
          <p className="font-poppins font-[400] sm:text-[12px] md:text-[14px] text-[#767676] mt-2">
            Upload an excel file
          </p>
          <UploadExcel
            setFileData={setFileData}
            document={values?.document}
            errors={errors}
            touched={touched}
            setFieldValue={setFieldValue}
            accept={".xlsx, .xls"}
            fileText={"Uplaod an excel file"}
            validateFile={validateFile}
          />
        </>
      ) : (
        <>
          <Input
            type="text"
            label=""
            placeholder="Add Franchise Name"
            width="100%"
            radius="5px"
            InputDivClass="w-[100%] mt-8"
            name="franchiseName"
            value={values?.franchiseName}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors.franchiseName}
            touched={touched.franchiseName}
          />
          <Input
            type="text"
            label=""
            placeholder="Email"
            width="100%"
            radius="5px"
            InputDivClass="w-[100%] mt-8"
            name="email"
            value={values?.email}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors.email}
            touched={touched.email}
          />
          <PhoneFormInput
            label={""}
            divClassName="sm:mt-12 md:mt-0 w-full"
            name="contact"
            value={values?.contact}
            handleBlur={handleBlur}
            error={errors.contact}
            touched={touched.contact}
            setFieldValue={setFieldValue}
            inputStyle={{
              width: "100%",
              border: "none",
            }}
            buttonStyle={{ border: "none" }}
            buttonClass="!rounded-l-[5px] !bg-[#D9D9D9] md:!w-[10%] sm:!w-[15%] lg:!w-[6%] xl:!w-[5%] 2xl:!w-[5%]"
            containerClass="!rounded-[5px] border border-[#D9D9D9]"
            inputClass="lg:!h-[45px] md:!h-[45px] !rounded-[5px] !bg-[#FFFFFF] !text-[#303030] lg:!text-[16px] sm:!text-[14px] !font-[400] !outline-none !font-poppins md:!pl-[12%] sm:!pl-[17%] lg:!pl-[8%] xl:!pl-[6%] 2xl:!pl-[6%]"
            labelClass="mb-8"
            errorClass={
              "text-red-600 sm:text-[10px] md:text-[12px] font-[600] ms-1"
            }
          />
          <Input
            type="text"
            label=""
            placeholder="Location"
            width="100%"
            radius="5px"
            InputDivClass="w-[100%] mt-8"
            name="address"
            value={values?.address}
            handleChange={handleChange}
            handleBlur={handleBlur}
            error={errors.address}
            touched={touched.address}
            onClick={handleLocationClick}
          />
          {showMap && (
            <GoogleMaps
              onLocationSelect={handleLocationSelect}
              searchContainerClass="w-[56%] sm:w-[51%] md:w-[50%] lg:w-[56%] 2xl:w-[63%] absolute top-[10px] left-[33%] sm:left-[37%] md:left-[38%] lg:left-[33%] 2xl:left-[28%]"
            />
          )}

          <TimeTable
            label={"What are your working hours?"}
            labelClass="lg:text-[16px] md:text-[14px] sm:text-[12px] font-[400] text-[#303030] mb-3 font-poppins"
            openClass="lg:text-[16px] md:text-[14px] sm:text-[12px] font-[400] text-[#8C8C8C] sm:ml-[2rem] md:ml-[3rem] lg:ml-[4rem] xl:ml-[6rem] font-poppins"
            closeClass="lg:text-[16px] md:text-[14px] sm:text-[12px] font-[400] text-[#8C8C8C] sm:ml-[0rem] md:ml-[0rem] lg:ml-[0rem] xl:mr-[0rem] font-poppins"
            dayClass="lg:text-[16px] md:text-[14px] sm:text-[12px] font-[400] text-[#8C8C8C] mb-2 sm:mr-[25px] md:mr-[50px] lg:mr-[70px] xl:mr-[100px] sm:w-[18%] md:w-[12%] lg:w-[10%] xl:w-[10%] font-poppins"
            timeDivClass="flex sm:w-[80%] md:w-[70%] lg:w-[70%] xl:w-[70%] items-baseline"
            toClass="text-center sm:mx-[0.25rem] md:mx-[0.5rem] lg:mx-[1rem] xl:mx-[0.5rem] lg:text-[16px] md:text-[14px] sm:text-[12px] sm:w-[15%] md:w-[13%] lg:w-[5%] xl:w-[10%] font-poppins"
            timePickerClass="sm:w-[95%] md:w-full lg:h-[40px] sm:h-[30px] rounded-[5px] border border-[#E4E3E4] cursor-pointer"
            format={format}
            timeData={values?.timeData}
            handleTimeChange={handleTimeChange}
            error={errors?.timeData}
            touched={touched?.timeData}
            setFieldValue={setFieldValue}
          />
          {errors?.timeSet && touched?.timeSet ? (
            <span className="text-red-600 sm:text-[10px] md:text-[12px] font-[600] ms-1">
              {errors?.timeSet}
            </span>
          ) : null}
        </>
      )}
    </div>
  );
};

export default AddFranchise;
