import React from "react";
import { useNavigate } from "react-router-dom";
import tick from "../../../Assets/Images/circular-tick.svg";
import { useSelector } from "react-redux";

const WithDrawSuccess = ({ setPageContent }) => {
  const navigate = useNavigate();
  const transferDetails = useSelector(
    (state) => state.fundraiserReducer.transfer
  );
  return (
    <>
      <div className="text-center">
        <p className="text-[#8C8C8C] sm:mt-[6%] md:mt-[3%] font-poppins font-[400] sm:text-[14px] lg:text-[16px]">
          Amount
        </p>
        <p className="sm:mb-[8%] md:mb-[4%] sm:mt-[2%] md:mt-[1%] font-poppins font-[600] sm:text-[28px] lg:text-[32px]">
          {transferDetails?.amount}
        </p>
        <div className="flex sm:mb-[8%] md:mb-[4%] justify-center">
          <img src={tick} className="sm:h-[74px] md:h-[84px]" />
        </div>
        <p className="text-[#303030] font-poppins font-[600] sm:text-[14px] lg:text-[16px] sm:mb-[2%] md:mb-[1%]">
          Your funds are on the way
        </p>
        <p className="text-[#8C8C8C] font-poppins font-[400] sm:text-[14px] lg:text-[16px] sm:mb-[8%] md:mb-[4%]">
          Transaction ID:{transferDetails?.id}
        </p>
        {/* <p className="font-poppins font-[600] sm:text-[18px] lg:text-[20px] sm:mb-[2%] md:mb-[1%]">
          {transferDetails?.balance}
        </p>
        <p className="text-[#8C8C8C] sm:mb-[10%] md:mb-[5%] font-poppins font-[400] sm:text-[14px] lg:text-[16px]">
          Available Balance
        </p> */}
        <div className="flex justify-center sm:mb-[6%] md:mb-[3%]">
          <hr className="w-[90%]" />
        </div>
        <div className="w-[100%] flex flex-col items-center justify-center">
          <div className="sm:w-[100%] md:w-[90%] lg:w-[70%] xl:w-[60%] 2xl:w-[45%]">
            <p className="sm:mb-[10%] md:mb-[5%] font-poppins font-[400] sm:text-[14px] lg:text-[16px] text-[#8C8C8C]">
              We’ll send you a confirmation email shortly. Check that email for
              details on when the funds will reach your bank account.
            </p>
            <p
              className="text-[#00ADEF] font-poppins font-[400] sm:text-[14px] lg:text-[16px] sm:mb-[4%] md:mb-[2%] cursor-pointer"
              onClick={() => setPageContent("withdrawMoney")}
            >
              Make another withdrawal
            </p>
            <p
              className="text-[#00ADEF] font-poppins font-[400] sm:text-[14px] lg:text-[16px] cursor-pointer"
              onClick={() => navigate("/fundraiser/withdrawals")}
            >
              View all withdrawals
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default WithDrawSuccess;
