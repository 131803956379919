import axios from "../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getFundraisers = createAsyncThunk(
  "fundraiser",
  async (payload, thunkAPI) => {
    try {
      const url = payload?.page
        ? `fundraiser?page=${payload?.page}`
        : payload?.limit
        ? `fundraiser?limit=${payload?.limit}`
        : "fundraiser";
      const response = await axios.get(url);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const getFundraiserCategories = createAsyncThunk(
  "fundraiser/category",
  async () => {
    try {
      const response = await axios.get("fundraiser/category");
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);

export const getFundraisersByCategoryId = createAsyncThunk(
  "fundraiser/categoryById",
  async (payload) => {
    try {
      const response = await axios.get(`fundraiser/category/${payload?.id}`);
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);

export const getFundsDeposited = createAsyncThunk(
  "fundraiser/funds",
  async () => {
    try {
      const response = await axios.get("fundraiser/funds");
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);

export const addBank = createAsyncThunk(
  "payout/bank",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post("payout/bank", payload);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const getBanks = createAsyncThunk("payout/banks", async () => {
  try {
    const response = await axios.get("payout/banks");
    return response?.data;
  } catch (error) {
    return error;
  }
});

export const getWithdrawals = createAsyncThunk(
  "payout/fundraiser",
  async (payload) => {
    try {
      const response = await axios.get(
        payload?.endingBefore
          ? `payout/fundraiser?endingBefore=${payload?.endingBefore}`
          : payload?.startingAfter
          ? `payout/fundraiser?startingAfter=${payload?.startingAfter}`
          : "payout/fundraiser"
      );
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);

export const createTransfer = createAsyncThunk(
  "payout/transfer",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post("payout/transfer", payload);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const createConnectedAccount = createAsyncThunk(
  "payout/link",
  async () => {
    try {
      const response = await axios.get("payout/link");
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);

export const getOnboardingStatus = createAsyncThunk(
  "payout/onboardingStatus",
  async () => {
    try {
      const response = await axios.get("payout/onboardingStatus");
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);

export const getAccountDetails = createAsyncThunk(
  "payout/balance",
  async () => {
    try {
      const response = await axios.get("payout/balance");
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);

export const updateReferredBy = createAsyncThunk(
  "auth/updateReferredBy",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.patch("auth/updateReferredBy", payload);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const getFundraiserInquiries = createAsyncThunk(
  "inquiry/fundraiser",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.get(`inquiry/fundraiser?page=${payload?.page}`);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const addContent = createAsyncThunk(
  "fundraiser/updateContent",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.patch("fundraiser/updateContent", payload);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const getFundraiserContent = createAsyncThunk(
  "fundraiser/content",
  async (payload, thunkAPI) => {
    try {
      const url = `fundraiser/content/${payload?.userId}`
      const response = await axios.get(url);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const toggleFundraiserStatus = createAsyncThunk(
  "fundraiser/status",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.get(`fundraiser/status/${payload?.id}`);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);