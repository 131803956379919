import axios from "../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getCategories = createAsyncThunk("categories", async () => {
  try {
    const response = await axios.get("category");
    return response?.data;
  } catch (error) {
    throw error;
  }
});

export const createOffer = createAsyncThunk(
  "offer/create",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.patch("offer/create", payload);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const getOffers = createAsyncThunk(
  "offer",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post(`offer?page=${payload?.page}`);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const editOffer = createAsyncThunk(
  "offer/update",
  async (payload, thunkAPI) => {
    try {
      const id = payload.get("id");
      const response = await axios.patch(`offer/${id}`, payload);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const deleteOffer = createAsyncThunk(
  "offer/delete",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.delete(`offer/${payload?.id}`);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const toggleOfferStatus = createAsyncThunk(
  "offer/status",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.patch(`offer/status/${payload?.id}`);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const createSubCategory = createAsyncThunk(
  "subCategory/create",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post("subCategory/create", payload);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const createRule = createAsyncThunk(
  "ruleAndRegulation",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post("ruleAndRegulation", payload);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const getRules = createAsyncThunk("get/ruleAndRegulation", async () => {
  try {
    const response = await axios.get("ruleAndRegulation");
    return response?.data;
  } catch (error) {
    throw error;
  }
});

export const getOfferById = createAsyncThunk(
  "offer/get",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.get(`offer/${payload?.id}`);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const getNearestOffersByMerchant = createAsyncThunk(
  "offer/nearby/merchant",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post("offer/nearby/merchant", payload);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const getCategoryById = createAsyncThunk(
  "category",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.get(`category/${payload?.id}`);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const getOffersByMerchant = createAsyncThunk(
  "offer/merchant",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.get(`offer/merchant?page=${payload?.page}`);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const approveOrRejectResources = createAsyncThunk(
  "admin/approveReject",
  async (payload, thunkAPI) => {
    try {
      const body = {
        ids: payload?.ids,
        merchantId: payload?.merchantId,
        reason: payload?.reason,
      };
      const response = await axios.post(
        `admin/approveReject?type=${payload?.type}&status=${payload?.status}`,
        body
      );
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const getNonDraftedOffers = createAsyncThunk(
  "offer/nonDrafted",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.get(`offer/nonDrafted?page=${payload?.page}`);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);