import React, { useState } from "react";
import Mobile from "../../../Assets/Images/mobile.svg";

const SecurityVerification = ({ setPageContent }) => {
  const [code, setCode] = useState("");
  const handleCodeChange = (e) => {
    setCode(e.target.value);
  };
  return (
    <>
      <div className="text-center mt-8 ">
        <div className="flex justify-center ">
          <img src={Mobile} alt=" " />
        </div>
        <p className="sm:mt-[10%] md:mt-[5%] font-poppins font-[400] sm:text-[12px] md:text-[16px]">
          To complete your request, enter the verification code we sent to +92
          *********20{" "}
        </p>
        <input
          type="text"
          placeholder="Code"
          className="sm:w-[40%] md:w-[30%] lg:w-[25%] 2xl:w-[20%] border-b border-[#8C8C8C] sm:mt-[10%] md:mt-[5%] h-[31px] outline-none font-poppins font-[400] sm:text-[12px] md:text-[16px]"
          value={code}
          onChange={handleCodeChange}
        />
        <div className="flex justify-center sm:my-[10%] md:my-[5%]">
          <hr class="border-1 border-solid border-gray-300 w-[90%]" />
        </div>
        <div>
          <button
            className=" bg-[#00ADEF] sm:text-[16px] md:text-[20px] text-white cursor-pointer sm:w-[40%] md:w-[30%] lg:w-[25%] 2xl:w-[20%] h-[48px] rounded-[5px] font-montserrat font-[500]"
            onClick={() => setPageContent("success")}
          >
            Submit
          </button>
        </div>
        <p className="sm:mt-[10%] md:mt-[5%] text-[#8C8C8C] sm:text-[12px] md:text-[16px] font-poppins font-[400]">
          Didn’t get the code?
          <span className="text-[#00ADEF] sm:text-[12px] md:text-[16px] font-[600] font-poppins">
            {" "}
            Resend
          </span>
        </p>
      </div>
    </>
  );
};

export default SecurityVerification;
