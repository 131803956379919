import React from "react";
import { ReactComponent as HeartIcon } from "../../../../Assets/Icons/heartIcon.svg";

const OfferCard = ({ img, title, description, discount }) => {

  const truncateTitle = (title) => {
    return title?.length > 20 ? `${title.substring(0, 20)}...` : title;
  };

  return (
    <div className="h-[111px] w-[87px] bg-white flex flex-col justify-start items-center rounded-[6px] drop-shadow-md">
      <img src={img} className="w-full h-[65%] rounded-tl-[6px] rounded-tr-[6px]"/>
      <div className="p-1 w-full">
        <div className="w-full flex justify-between items-center">
          <p className="text-[#303030] font-poppins font-[500] text-[7px] w-[70%]">
            {truncateTitle(title)}
          </p>
          <HeartIcon />
        </div>
        {/* <p className="text-[#8C8C8C] font-poppins font-[400] text-[5px] whitespace-nowrap">
          {description}
        </p> */}
        <p className="text-[#00ADEF] font-poppins font-[600] text-[8px] lg:text-[9px] whitespace-nowrap">
          {`${discount}% off`}
        </p>
      </div>
    </div>
  );
};

export default OfferCard;
