import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getOfferMerchants,
  getMerchants,
  getMerchantInquiries,
  toggleMerchantStatus,
  redeemOffer,
} from "../../services/merchantService";
import toast from "../../utils/toast";

const initialState = {
  offerMerchants: null,
  merchants: null,
  inquiries: null,
  toggleMerchant: null,
  redeemedOffer: null,
  loading: false,
};

const merchantSlice = createSlice({
  name: "merchant",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOfferMerchants?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.loading = false;
        state.offerMerchants = action?.payload?.data;
      } else {
        state.loading = false;
        toast.error(action?.payload?.message);
      }
    });

    builder.addCase(getMerchants?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.loading = false;
        state.merchants = action?.payload?.data;
      } else {
        state.loading = false;
        toast.error(action?.payload?.message);
      }
    });

    builder.addCase(getMerchantInquiries?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.loading = false;
        state.inquiries = action?.payload?.data;
      } else {
        state.loading = false;
        toast.error(action?.payload?.message);
      }
    });

    builder.addCase(toggleMerchantStatus?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.loading = false;
        state.toggleMerchant = action?.payload?.data;
      } else {
        state.loading = false;
        toast.error(action?.payload?.message);
      }
    });

    builder.addCase(redeemOffer?.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.loading = false;
        state.redeemedOffer = action?.payload?.data;
      } else {
        state.loading = false;
        // toast.error(action?.payload?.message);
      }
    });

    builder
      .addMatcher(
        isAnyOf(
          getOfferMerchants?.pending,
          getMerchants?.pending,
          getMerchantInquiries?.pending,
          toggleMerchantStatus?.pending,
          redeemOffer?.pending
        ),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          getOfferMerchants?.rejected,
          getMerchants?.rejected,
          getMerchantInquiries?.rejected,
          toggleMerchantStatus?.rejected,
          redeemOffer?.rejected
        ),
        (state, action) => {
          state.loading = false;
          toast.error(action?.payload?.message);
        }
      );
  },
});

export const {} = merchantSlice?.actions;
export default merchantSlice?.reducer;
