import { Breadcrumb } from 'antd';
import React from 'react'

const BreadCrumsHeader = ({heading, items, form}) => {
  return (
    <div className={`${form == true ? '': 'w-[60%]'} `}>
      <p className='text-[20px] font-[600] text-[#303030] font-poppins mb-1'>{heading}</p>
      <Breadcrumb separatorMargin='' items={items} separator='>'/>
    </div>
  )
}

export default BreadCrumsHeader;