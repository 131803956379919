import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  signUp,
  login,
  forgetPassword,
  emailVerification,
  otpVerification,
  resetPassword,
  logout,
} from "../../services/authService";
import toast from "../../utils/toast";

const initialState = {
  signUpData: null,
  loginData: null,
  forgetPasswordData: null,
  forgetPasswordResponse: null,
  tokenData: null,
  email: null,
  emailData: null,
  otpToken: null,
  resetData: null,
  fcmToken: null,
  loading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setSignUpData: (state, action) => {
      state.signUpData = action.payload;
    },

    setLoginData: (state, action) => {
      state.loginData = action.payload;
    },

    setUserData: (state, action) => {
      state.tokenData = action.payload;
    },

    setForgetPasswordData: (state, action) => {
      state.forgetPasswordData = action.payload;
    },

    setEmail: (state, action) => {
      state.email = action.payload.email;
    },

    setFcmToken: (state, action) => {
      state.fcmToken = action.payload.fcmToken
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signUp.fulfilled, (state, action) => {
      if (action?.payload?.success == true) {
        state.loading = false;
      } else {
        state.loading = false;
        toast.error(action?.payload?.message);
      }
    });

    builder.addCase(login.fulfilled, (state, action) => {
      if (action?.payload?.success == true) {
        state.loading = false;
        state.tokenData = action.payload?.data;
      } else {
        state.loading = false;
        toast.error(action?.payload?.message);
      }
    });

    builder.addCase(forgetPassword.fulfilled, (state, action) => {
      if (action?.payload?.success == true) {
        state.loading = false;
        state.forgetPasswordResponse = action.payload?.data;
      } else {
        state.loading = false;
        toast.error(action?.payload?.message);
      }
    });

    builder.addCase(emailVerification.fulfilled, (state, action) => {
      if (action?.payload?.success === true) {
        state.emailData = action?.payload;
      } else {
        toast.error(action?.payload?.message);
      }
      state.loading = false;
    });

    builder.addCase(otpVerification.fulfilled, (state, action) => {
      if (action?.payload?.success == true) {
        state.loading = false;
        state.otpToken = action.payload?.data;
      } else {
        state.loading = false;
        toast.error(action?.payload?.message);
      }
    });

    builder.addCase(resetPassword.fulfilled, (state, action) => {
      if (action?.payload?.success == true) {
        state.loading = false;
        state.resetData = action.payload?.data;
        toast.success(action?.payload?.message);
      } else {
        state.loading = false;
        toast.error(action?.payload?.message);
      }
    });

    builder.addCase(logout.fulfilled, (state, action) => {
      if (action?.payload?.success == true) {
        state.loading = false;
        state.signUpData = null;
        state.email = null;
        state.emailData = null;
        state.forgetPasswordData = null;
        state.forgetPasswordResponse = null;
        state.loginData = null;
        state.otpToken = null;
        state.resetData = null;
        state.tokenData = null;
        state.fcmToken = null;
      } else {
        state.loading = false;
        toast.error(action?.payload?.message);
      }
    });

    builder
      .addMatcher(
        isAnyOf(
          signUp?.pending,
          login?.pending,
          forgetPassword?.pending,
          emailVerification?.pending,
          otpVerification?.pending,
          resetPassword?.pending,
          logout?.pending
        ),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(
          signUp?.rejected,
          login?.rejected,
          forgetPassword?.rejected,
          emailVerification?.rejected,
          otpVerification?.rejected,
          resetPassword?.rejected,
          logout?.rejected
        ),
        (state, action) => {
          state.loading = false;
          toast.error(action?.payload?.message);
        }
      );
  },
});

export const {
  setSignUpData,
  setLoginData,
  setForgetPasswordData,
  setUserData,
  setEmail,
  setFcmToken
} = authSlice?.actions;
export const getToken = (state) => state?.auth?.tokenData;
export default authSlice?.reducer;
