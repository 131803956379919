import React, { useEffect } from "react";
import BreadCrumsHeader from "../../common/BreadCrums";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import Pagination from "../../common/TablePagination";
import { useDispatch, useSelector } from "react-redux";
import { getMerchantInquiries } from "../../../services/merchantService";
import Loader from "../../common/Loader";
import DetailsPopup from "../../common/DetailsPopup";
import msgIcon from "../../../Assets/Icons/emailIcon.svg";
import { useLocation } from "react-router-dom";
import { getFundraiserInquiries } from "../../../services/fundraiserService";

const InquiriesListDataTable = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPath = location?.pathname;
  const merchantLoading = useSelector(
    (state) => state?.merchantReducer?.loading
  );
  const fundraiserLoading = useSelector(
    (state) => state?.fundraiserReducer?.loading
  );
  const [allInquiries, setAllInquiries] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);

  const [inquiryMsg, setInquiryMsg] = useState("");

  const getInquiriesData = async () => {
    if (currentPath === "/merchant-inquiries") {
      const inquirysPromise = await dispatch(
        getMerchantInquiries({ page: currentPage })
      )?.unwrap();
      if (inquirysPromise?.success === true) {
        setAllInquiries(inquirysPromise?.data);
        setCurrentPage(inquirysPromise?.currentPage);
        setTotalPages(inquirysPromise?.totalPages);
        setCount(inquirysPromise?.count);
      }
    } else if (currentPath === "/fundraiser-inquiries") {
      const inquirysPromise = await dispatch(
        getFundraiserInquiries({ page: currentPage })
      )?.unwrap();
      if (inquirysPromise?.success === true) {
        setAllInquiries(inquirysPromise?.data);
        setCurrentPage(inquirysPromise?.currentPage);
        setTotalPages(inquirysPromise?.totalPages);
        setCount(inquirysPromise?.count);
      }
    }
  };

  useEffect(() => {
    getInquiriesData();
  }, [currentPage, currentPath]);

  const items = [
    {
      title:
        currentPath === "/merchant-inquiries" ? "Merchants" : "Fundraisers",
    },
    {
      title: "Inquiries",
    },
  ];

  const columns = [
    {
      name: "No.",
    },
    {
      name: "First Name",
    },
    {
      name: "Last Name",
    },
    {
      name: "City",
    },
    {
      name: "State",
    },
    {
      name: "Email",
    },
    {
      name: "Contact",
    },
    {
      name: "Reason",
    },
    {
      name: "Contact Person",
    },
    {
      name: "Message",
    },
  ];

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleReadMore = (msg) => {
    setInquiryMsg(msg);
  };

  const handleCloseMsg = () => {
    setInquiryMsg("");
  };

  return (
    <>
      {(merchantLoading === true || fundraiserLoading === true) && <Loader />}
      {inquiryMsg !== "" && (
        <DetailsPopup
          content={inquiryMsg}
          handleClose={handleCloseMsg}
          icon={msgIcon}
          popupClassName={"w-[335px] sm:w-[470px] pb-5"}
        />
      )}
      <div className="flex justify-between col-span-12">
        <BreadCrumsHeader heading="Inquiries" items={items} />
      </div>

      <div className=" col-span-12 mt-8">
        <TableContainer style={{ width: "100%" }}>
          <Table
            sx={{
              minWidth: "1936px",
              borderCollapse: "separate",
              borderSpacing: "0px 8px",
            }}
            aria-label="simple table"
          >
            <TableHead className="bg-[#00ADEF]">
              <TableRow
                className="h-[50px]"
                sx={{ boxShadow: "0px 4px 4px 0px #00000040" }}
              >
                {columns.map((column) => {
                  return <TableCell>{column.name}</TableCell>;
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {allInquiries?.map((inquiry, index) => {
                return (
                  <TableRow
                    className="h-[50px]"
                    sx={{
                      boxShadow: "0px 4px 4px 0px #00000040",
                      backgroundColor:
                        (index + 1) % 2 !== 0 ? "#F5F5F5" : "#FFFFFF",
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{inquiry?.name?.split(" ")[0]}</TableCell>
                    <TableCell>{inquiry?.name?.split(" ")[1]}</TableCell>
                    <TableCell>{inquiry?.city}</TableCell>
                    <TableCell>{inquiry?.state}</TableCell>
                    <TableCell>{inquiry?.email}</TableCell>
                    <TableCell>{inquiry?.phone}</TableCell>
                    <TableCell>{inquiry?.reason}</TableCell>
                    <TableCell>{inquiry?.contactPerson}</TableCell>
                    <TableCell>
                      <div className="w-[50%] flex justify-center items-center">
                        <img
                          src={msgIcon}
                          className="cursor-pointer"
                          onClick={() => handleReadMore(inquiry?.message)}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          totalPages={totalPages}
          handlePageChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default InquiriesListDataTable;
