import React from "react";
import OfferStep from "./OfferStep";
import PrimaryOutlineButton from "../PrimaryOutlineButton";

const OfferFormLayout = ({
  title,
  content,
  stepIndex,
  handleSaveAndExit,
}) => {
  const formSteps = [
    { stepNumber: 1, content: "Select Category" },
    { stepNumber: 2, content: "Select Sub Category" },
    { stepNumber: 3, content: "Create Offer" },
    { stepNumber: 4, content: "Customize Your Offer" },
    { stepNumber: 5, content: "Rules and Regulations" },
    { stepNumber: 6, content: "Locations" },
    { stepNumber: 7, content: "Review and Approve" },
  ];

  return (
    <div className="col-span-12 mainFormDiv">
      <div className="py-4 px-7" style={{ borderBottom: "1px solid #D9D9D9" }}>
        <div className="flex justify-between items-center gap-3">
          <p
            className={`sm:text-[18px] md:text-[20px] font-[600] text-[#303030] font-poppins text-center`}
          >
            {title}
          </p>
          <PrimaryOutlineButton
            label={"Save and Exit"}
            className={
              "w-[40%] sm:w-[30%] md:w-[25%] lg:w-[20%] xl:w-[12%] text-[14px] font-[500] h-[40px] sm:h-[40px] md:h-[45px] rounded-[5px]"
            }
            onClick={handleSaveAndExit}
          />
        </div>
      </div>

      {/* Form */}

      <div className="px-7 w-[100%]">
        <div className="flex justify-between sm:flex-col lg:flex-row">
          <div className="flex items-center justify-between w-full mt-8">
            {formSteps?.map((step) => (
              <OfferStep
                stepNumber={step?.stepNumber}
                content={step?.content}
                stepIndex={stepIndex}
              />
            ))}
          </div>
        </div>
        {content}
      </div>
    </div>
  );
};

export default OfferFormLayout;
