import React, { useState, useEffect } from "react";
import "./dashboard.scss";
import Frequency from "./Frequency";
import NewOffers from "./NewOffers";
import OffersChart from "./OffersChart";

const Summary = () => {
  const [frequencies, setFrequencies] = useState([
    "Today",
    "Weekly",
    "Monthly",
  ]);

  return (
    <div className="summary w-full h-full p-4 offersChart">
      <div className="flex flex-col sm:flex-col xl:flex-row justify-between w-full">
        <span className="text-[20px] font-poppins font-[600] text-[black] whitespace-nowrap">
          Offers Summary
        </span>
        <Frequency frequencies={frequencies} className='frequency sm:mt-3 sm:w-[70%] md:w-[50%] lg:w-[70%] xl:w-[50%] xl:mt-0 py-2 px-1'/>
      </div>
      <NewOffers />
      <OffersChart className="sm:mt-[19%] 2xl:mt-[10%]" barThickness={5}/>
    </div>
  );
};

export default Summary;
