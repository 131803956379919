import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import subscriptionSlice from "./slices/subscriptionSlice";
import fundraiserSlice from "./slices/fundraiserSlice";
import franchiseSlice from "./slices/franchiseSlice";
import offerSlice from "./slices/offerSlice";
import merchantSlice from "./slices/merchantSlice";
import onboardingSlice from "./slices/onboardingSlice";
import assetsSlice from "./slices/assetsSlice";
import notificationSlice from "./slices/notificationSlice";

const rootReducer = combineReducers({
  authReducer: authSlice,
  subscriptionReducer: subscriptionSlice,
  fundraiserReducer: fundraiserSlice,
  franchiseReducer: franchiseSlice,
  offerReducer: offerSlice,
  merchantReducer: merchantSlice,
  onboardingReducer: onboardingSlice,
  assetsReducer: assetsSlice,
  notificationReducer: notificationSlice
});

export default rootReducer;
