import React from "react";

const FormLayout = ({
  title,
  content,
  icon,
  cont,
  pageContent,
  setPageContent,
}) => {
  return (
    <div className="col-span-12 mainFormDiv">
      <div
        className={`py-6 px-7 ${
          title !== "" ? "border-b border-solid border-[#D9D9D9]" : ""
        }`}
      >
        <div
          className={`flex ${
            pageContent == "success" ? "justify-center" : "justify-start"
          } gap-3`}
        >
          {icon ? (
            <img
              src={icon}
              className="cursor-pointer"
              onClick={() => setPageContent(cont)}
            />
          ) : null}
          <p
            className={`sm:text-[18px] md:text-[20px] font-[600] text-[#303030] font-poppins text-center`}
          >
            {title}
          </p>
        </div>
      </div>

      {/* Form */}

      <div className="px-7 w-[100%]">{content}</div>
    </div>
  );
};

export default FormLayout;
