import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BreadCrumsHeader from "../../common/BreadCrums";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Status from "../../common/Status";
import Delete from "../../common/Delete";
import Edit from "../../common/Edit";
import Pagination from "../../common/TablePagination";
import CreateNewButton from "../../common/CreateNewButton";
import { ReactComponent as File } from "../../../Assets/Icons/fileIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAsset,
  getAssets,
  toggleAssetStatus,
} from "../../../services/assetsService";
import { separateDateTimeAndConvertToLocale } from "../../../utils/utilFunctions";
import Loader from "../../common/Loader";
import deleteImg from "../../../Assets/Images/deleteImg.svg";
import CancelPopup from "../../common/CancelPopup";
import FundraiserPopup from "./FundraiserPopup";
import { ReactComponent as FundraiserIcon } from "../../../Assets/Icons/FundraiserIcon.svg";

const PromotionsListTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.assetsReducer?.loading);
  const location = useLocation();
  const currentPath = location.pathname;
  const [allAssets, setAllAssets] = useState();
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);
  const [fundraisers, setFundraisers] = useState(null);
  const [isFundraisersOpen, setIsFundraisersOpen] = useState(false);

  const getAssetsData = async () => {
    const assetsPromise = await dispatch(
      getAssets({ page: currentPage })
    )?.unwrap();
    if (assetsPromise?.success === true) {
      setAllAssets(assetsPromise?.data);
      setCurrentPage(assetsPromise?.currentPage);
      setTotalPages(assetsPromise?.totalPages);
      setCount(assetsPromise?.count);
    }
  };

  useEffect(() => {
    getAssetsData();
  }, [deleteSuccess, currentPage]);

  const items = [
    {
      title: "Assets and  Promotions",
    },
    {
      title: "List of Promotions",
    },
  ];

  const columns = [
    {
      name: "No.",
    },
    {
      name: "Title",
    },
    {
      name: "Description",
    },
    {
      name: "Start Date",
    },
    {
      name: "Start Time",
    },
    {
      name: "End Date",
    },
    {
      name: "End Time",
    },
    {
      name: "File",
    },
    {
      name: "Fundraisers",
    },
    {
      name: "Status",
    },
    {
      name: "Actions",
    },
  ];

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleToggle = async (id) => {
    try {
      await dispatch(toggleAssetStatus({ id: id })).unwrap();
    } catch (error) {
      console.error("Error toggling location status:", error);
    }
  };

  const handleEditClick = (id) => {
    navigate("/add-assets", {
      state: {
        id: id,
      },
    });
  };

  const handleDeleteClick = (id) => {
    setDeleteClicked(true);
    setSelectedAssetId(id);
  };

  const handleKeepAsset = () => {
    setDeleteClicked(false);
  };

  const handleDeleteAsset = async () => {
    if (selectedAssetId !== null) {
      const deletePromise = await dispatch(
        deleteAsset({ id: selectedAssetId })
      )?.unwrap();
      if (deletePromise?.success === true) {
        setDeleteClicked(false);
        setDeleteSuccess(!deleteSuccess);
      }
    }
  };

  const handleFundraiserClick = (fundraiser) => {
    setFundraisers(fundraiser);
    setIsFundraisersOpen(true);
  };

  const handleCloseFundraiser = () => {
    setIsFundraisersOpen(false);
  };

  return (
    <>
      {loading === true && <Loader />}
      {fundraisers !== null && isFundraisersOpen === true && (
        <FundraiserPopup
          fundraisers={fundraisers}
          handleCloseFundraiser={handleCloseFundraiser}
        />
      )}
      <div className="flex justify-between col-span-12">
        <BreadCrumsHeader heading="List of Promotions" items={items} />
        <CreateNewButton
          text={"Create New Promotion"}
          navigateTo={"/add-assets"}
          iconClassName="sm:left-[1%] md:left-[4%] bottom-[30%]"
        />
      </div>
      {deleteClicked == true ? (
        <CancelPopup
          handleNo={handleKeepAsset}
          handleYes={handleDeleteAsset}
          heading={"Delete Promotion"}
          content={"Are you sure you want to delete this promotion?"}
          image={deleteImg}
        />
      ) : null}
      <div className=" col-span-12 mt-8">
        <TableContainer style={{ width: "100%" }}>
          <Table
            sx={{
              minWidth: "1936px",
              borderCollapse: "separate",
              borderSpacing: "0px 8px",
            }}
            aria-label="simple table"
          >
            <TableHead className="bg-[#00ADEF]">
              <TableRow
                className="h-[50px]"
                sx={{ boxShadow: "0px 4px 4px 0px #00000040" }}
              >
                {columns.map((column) => {
                  return <TableCell>{column?.name}</TableCell>;
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {allAssets?.map((promotion, index) => {
                return (
                  <TableRow
                    className="h-[50px]"
                    sx={{
                      boxShadow: "0px 4px 4px 0px #00000040",
                      backgroundColor:
                        (index + 1) % 2 !== 0 ? "#F5F5F5" : "#FFFFFF",
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{promotion?.title}</TableCell>
                    <TableCell>{promotion?.description}</TableCell>
                    <TableCell>
                      {
                        separateDateTimeAndConvertToLocale(
                          promotion?.startDateTime
                        )?.localDate
                      }
                    </TableCell>
                    <TableCell>
                      {
                        separateDateTimeAndConvertToLocale(
                          promotion?.startDateTime
                        )?.localTime
                      }
                    </TableCell>
                    <TableCell>
                      {
                        separateDateTimeAndConvertToLocale(
                          promotion?.endDateTime
                        )?.localDate
                      }
                    </TableCell>
                    <TableCell>
                      {
                        separateDateTimeAndConvertToLocale(
                          promotion?.endDateTime
                        )?.localTime
                      }
                    </TableCell>
                    <TableCell>
                      {
                        <a
                          href={
                            promotion?.file
                          }
                          download
                        >
                          <File className="w-[25px] h-[25px] cursor-pointer" />
                        </a>
                      }
                    </TableCell>
                    <TableCell>
                      <div className="w-[80%] flex justify-center cursor-pointer">
                        <FundraiserIcon
                          onClick={() =>
                            handleFundraiserClick(promotion?.fundraisers)
                          }
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      <Status
                        isEnabled={promotion?.status}
                        handleToggle={() => handleToggle(promotion?._id)}
                      />
                    </TableCell>
                    <TableCell>
                      <div className="flex justify-start gap-[4%]">
                        <Edit onClick={() => handleEditClick(promotion?._id)} />
                        <Delete
                          onClick={() => handleDeleteClick(promotion?._id)}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          totalPages={totalPages}
          handlePageChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default PromotionsListTable;
