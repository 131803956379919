import axios from "../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getUserSubscription = createAsyncThunk(
  "subscription/user",
  async () => {
    try {
      const response = await axios.get("subscription/user");
      return response?.data;
    } catch (error) {
      return error;
    }
  }
);

export const cancelSubscription = createAsyncThunk(
  "subscription/cancelSubscription",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post("subscription/cancelSubscription", payload);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const getSubscriptionPlans = createAsyncThunk(
  "subscription/products",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.get("subscription/products");
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const checkout = createAsyncThunk(
  "subscription/createCheckoutSession",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post("subscription/createCheckoutSession", payload);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const retrieveSession = createAsyncThunk(
  "subscription/retrieve",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post("subscription/retrieve", payload);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);