import React from 'react'

const RelatedSubscriptionsHeader = ({heading, content, contentClass}) => {
  return (
    <div className='flex flex-col items-center text-center mt-4'>
        <p className='text-[22px] sm:text-[30px] font-[600] font-poppins text-[#303030] mb-2' >{heading}</p>
        <p className={`text-[16px] sm:text-[18px] font-[400] font-poppins ${contentClass ? contentClass : ''}`}>{content}</p>
    </div>
  )
}

export default RelatedSubscriptionsHeader;