import React from "react";
import { useDispatch, useSelector } from "react-redux";
import WelcomeList from "./WelcomeList";
import PrimaryButton from "../PrimaryButton";
import { createConnectedAccount } from "../../../services/fundraiserService";
import { ReactComponent as DollarIcon } from "../../../Assets/Icons/dollarIcon.svg";
import { ReactComponent as TimeIcon } from "../../../Assets/Icons/timeIcon.svg";
import { ReactComponent as ListIcon } from "../../../Assets/Icons/listIcon.svg";
import { useNavigate } from "react-router-dom";

const MerchantWelcome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    // const linkPromise = await dispatch(createConnectedAccount()).unwrap();
    // window.location.href = linkPromise?.data;
    navigate("/merchant/welcome-offer")
  };

  const welcomeList = [
    {
      heading: "How long does it take?",
      text: "Building a new offer typically takes 30 minutes. After you submit your offer, we’ll review it within 1 business day.",
      icon: <TimeIcon />,
    },
    {
      heading: "What does it cost?",
      text: "There’s zero upfront cost to build an offer.",
      icon: <DollarIcon />,
    },
    {
      heading: "Do I need anything before I get started?",
      text: "Have the following documents ready, you’ll need this information to complete the next steps:Photos of your business and services or goodsProof of price verification.",
      icon: <ListIcon />,
    },
  ];

  return (
    <div className="sm:w-[80%] md:w-[70%] lg:w-[60%] py-20">
      <p className="font-poppins font-[500] text-[28px] text-center text-[#303030]">
        Welcome to Swoodle Merchant!
      </p>
      <p className="font-poppins font-[400] text-[14px] text-center text-[#767676]">
        Select get started to build your offer.
      </p>
      <p className="font-poppins font-[500] text-[18px] text-start text-[#303030] mt-10">
        Meet Offer Builder
      </p>
      <p className="font-poppins font-[400] text-[14px] text-start text-[#767676] mt-2">
        Launch your swoodle offer in no time using offer builder. Just tell us
        what services your business offers and we’ll help you build a offer you
        have full control over, customize your deal’s pricing, discount,
        marketing copy photos, and more using offer builder’s data driven
        guidance.
      </p>
      <div className="flex flex-col gap-5 mt-10">
        {welcomeList?.map((item) => (
          <WelcomeList
            heading={item?.heading}
            text={item?.text}
            icon={item?.icon}
          />
        ))}
      </div>
      <div className="w-full text-end mt-20">
        <PrimaryButton
          className={
            "w-[20%] font-montserrat text-[14px] text-center font-[500] h-[45px] rounded-[5px]"
          }
          label="Get Started"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default MerchantWelcome;
