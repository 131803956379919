import React from "react";

const Input = ({
  type,
  label,
  placeholder,
  width,
  radius,
  InputDivClass,
  name,
  value,
  handleChange,
  handleBlur,
  error,
  touched,
  onClick,
  handleKeyDown,
  readonly,
  inputClass,
}) => {
  return (
    <div className={`${InputDivClass}`}>
      <p className={`${label !== "" ? "invitationLabels" : ""}`}>{label}</p>
      {readonly === true ? (
        <input
          type={type ? type : "text"}
          name={name}
          value={value}
          placeholder={placeholder}
          className={`invitationInputs ${inputClass}`}
          style={{ width: width, borderRadius: radius }}
          onChange={handleChange}
          onBlur={handleBlur}
          onClick={onClick}
          onKeyDown={handleKeyDown}
          readOnly
        />
      ) : (
        <input
          type={type ? type : "text"}
          name={name}
          value={value}
          placeholder={placeholder}
          className={`invitationInputs ${inputClass}`}
          style={{ width: width, borderRadius: radius }}
          onChange={handleChange}
          onBlur={handleBlur}
          onClick={onClick}
          onKeyDown={handleKeyDown}
        />
      )}
      {error && touched ? (
        <div className="ms-1">
          <span className="text-red-600 sm:text-[10px] md:text-[12px] font-[600] text-start">
            {error}
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default Input;
