import React, { useEffect, useState } from "react";
import "./submitInfo.scss";
import Checkbox from "../../../common/Checkbox";
import { useFormik } from "formik";
import { submitInfoSchema } from "../../../../utils/validationSchemas";
import { ReactComponent as CheckedBox } from "../../../../Assets/Icons/checkedBox.svg";
import { ReactComponent as UncheckedBox } from "../../../../Assets/Icons/uncheckedBox.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  createPotentialMerchant,
  getPotentialMerchant,
} from "../../../../services/onboardingService";
import Loader from "../../../common/Loader";
import SuccessPopup from "../../../common/SuccessPopup";
import successImage from "../../../../Assets/Images/successImage.svg";

const SubmitInformation = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.onboardingReducer?.loading);
  const [merchant, setMerchant] = useState();
  const [submitted, setSubmitted] = useState(false);
  const merchantData = useSelector(
    (state) => state?.onboardingReducer?.startedOnboarding
  );
  const onChecked = (name, value) => {
    setFieldValue(name, !value);
  };

  const getMerchantData = async () => {
    const merchantPromise = await dispatch(
      getPotentialMerchant({ id: merchantData?._id })
    )?.unwrap();
    if (merchantPromise?.success === true) {
      setMerchant(merchantPromise?.data);
    }
  };

  useEffect(() => {
    getMerchantData();
  }, []);

  const formik = useFormik({
    initialValues: {
      authCheckBox: false,
      termsCheckbox: false,
      privacyCheckbox: false,
    },
    validationSchema: submitInfoSchema,
    onSubmit: async (values) => {
      // Handle form submission
      console.log("values", values);
      const payload = {
        id: merchantData?._id,
        businessName: merchant?.businessName,
        abnNo: merchant?.abnNo,
        googlePlaceId: merchant?.location?.googlePlaceId,
        website: merchant?.website,
        merchantCategory: merchant?.merchantCategory,
        primaryContactDetails: merchant?.primaryContactDetails,
        marketingContactDetails: merchant?.marketingContactDetails,
        aboutUs: merchant?.aboutUs,
        hasPhysicalStore: merchant?.hasPhysicalStore,
        tradingHours: merchant?.tradingHours,
      };
      const createPromise = await dispatch(
        createPotentialMerchant(payload)
      )?.unwrap();
      if (createPromise?.success === true) {
        setSubmitted(true);
      }
    },
  });

  const { authCheckBox, termsCheckbox, privacyCheckbox } = formik.values;

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = formik;
  return (
    <div className="px-9 py-10 h-full w-full">
      {loading === true && <Loader />}
      {submitted == true ? (
        <SuccessPopup
          content={"You are successfully onboarded!"}
          image={successImage}
        />
      ) : null}
      <div className="">
        <p className="lg:text-[20px] sm:text-[18px] font-[500] text-[#303030]">
          Contract
        </p>
      </div>

      <div className="bg-[#D9D9D9] lg:h-[32%] sm:h-[40%] rounded-[10px] p-5 mt-5">
        <p className="lg:text-[14px] sm:text-[12px] font-[500] text-[#535353]">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum. <br /> <br /> Lorem Ipsum is simply
          dummy text of the printing and typesetting industry. Lorem Ipsum has
          been the industry's standard dummy text ever since the 1500s, when an
          unknown printer took a galley of type and scrambled it to make a type
          specimen book.{" "}
        </p>
      </div>

      <Checkbox
        divClassName="mt-[2rem] flex items-start"
        text="I, hereby represent and warrant that I have obtained all necessary authorization to enter into this Agreement on behalf of the Merchant, and have authority to bind the Merchant to the Terms and Conditions set forth in this Agreement."
        checked={authCheckBox}
        onChecked={() => {
          setFieldValue("authCheckBox", !authCheckBox);
        }}
        textClass="lg:text-[14px] sm:text-[12px] font-[500] text-[#303030] ml-4"
      />

      <div className="mt-3">
        <p className="lg:text-[12px] sm:text-[12px] font-[400] text-[#E34E4B]">
          You must accept this agreement
        </p>
      </div>

      <div className="mt-[2rem] flex items-baseline">
        {termsCheckbox == true ? (
          <CheckedBox
            onClick={() => onChecked("termsCheckbox", termsCheckbox)}
            className="contactCheckbox"
          />
        ) : (
          <UncheckedBox
            onClick={() => onChecked("termsCheckbox", termsCheckbox)}
            className="contactCheckbox"
          />
        )}
        <label className="lg:text-[14px] sm:text-[12px] font-[500] text-[#303030] ml-4">
          I, have read and agreed to swoodle’s{" "}
          <span className="text-[#00ADEF] cursor-pointer">
            Terms and Conditions.
          </span>
        </label>
      </div>

      <div className="mt-3">
        <p className="lg:text-[12px] sm:text-[12px] font-[400] text-[#E34E4B]">
          You must accept this agreement
        </p>
      </div>

      <Checkbox
        divClassName="mt-[2rem] flex items-start"
        text="I, have read and accepted SWOODLE’S Privacy Policy. I consent to Swoodle contracting me via electronic communication services for the purpose of the Agreement. I also consent to the use of my personal data by SWOODLE in accordance with the Privacy Policy."
        checked={privacyCheckbox}
        onChecked={() => {
          setFieldValue("privacyCheckbox", !privacyCheckbox);
        }}
        textClass="lg:text-[14px] sm:text-[12px] font-[500] text-[#303030] ml-4"
      />
      <div className="mt-3">
        <p className="lg:text-[12px] sm:text-[12px] font-[400] text-[#E34E4B]">
          You must accept privacy policy
        </p>
      </div>

      <div className="text-end mt-8 w-full">
        <button
          type="submit"
          className="lg:w-[30%] 2xl:w-[25%] sm:w-[35%] lg:h-[45px] sm:h-[45px] rounded-[5px] bg-[#00ADEF] lg:text-[16px] sm:text-[14px] font-[500] text-[#FFFFFF] font-poppins"
          onClick={handleSubmit}
        >
          Submit Application
        </button>
      </div>
    </div>
  );
};

export default SubmitInformation;
