import React from "react";
import RadioButton from "./RadioButton";
import Checkbox from "./Checkbox";

const CheckboxSelection = ({
  label,
  divClassName,
  internalDivClass,
  secondRadioClass,
  textClass,
  checkClass,
  name,
  value,
  error,
  touched,
  setFieldValue,
  options,
  onChecked,
  postIcon
}) => {
  
  const handleChecked = (op) => {
    const isSelected = value.find((v) => v === op);
    if (isSelected) {
      const updatedValues = value?.filter((rule) => rule !== op);
      setFieldValue(name, updatedValues);
    } else {
      setFieldValue(name, [...value, op]);
    }
  };

  return (
    <div className={divClassName}>
      <p className="lg:text-[16px] sm:text-[14px] font-[400] text-[#303030] mb-3 font-poppins">
        {label}
      </p>
      {options?.map((op, index) => (
        <div className={internalDivClass}>
          <div class={`flex items-center justify-between ${index > 0 ? secondRadioClass : ""}`}>
            <Checkbox
              divClassName={checkClass}
              text={op}
              checked={value?.includes(op)}
              onChecked={onChecked ? () => onChecked(op) : () => handleChecked(op)}
              textClass={textClass}
            />
            {postIcon !== null && <img src={postIcon} className="ml-8 w-[80px]" />}
          </div>
        </div>
      ))}
      {error && touched ? (
        <span className="text-red-600 sm:text-[10px] md:text-[12px] font-[600] ms-1">
          {error}
        </span>
      ) : null}
    </div>
  );
};

export default CheckboxSelection;
