import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/common/Dashboard";
import InvitationForm from "../pages/Admin/InvitationForm";
import InvitationList from "../pages/Admin/InvitationList";
import MerchantList from "../pages/Admin/MerchantList";
import AddAssets from "../pages/Admin/AddAssets";
import PromotionList from "../pages/Admin/PromotionList";
import ReferralList from "../pages/Admin/ReferralList";
import FundraiserList from "../pages/Admin/FundraiserList";
import Inquiries from "../pages/Admin/Inquiries";
import FeaturedForm from "../pages/common/FeaturedForm";
import NewOffer from "../pages/common/NewOffer";
import OfferList from "../pages/common/OfferList";

const AdminRoutes = () => {
  return (
    <>
      <Routes>
        <Route
          path="/dashboard"
          element={<FeaturedForm element={<Dashboard />} />}
        />
        <Route
          path="/invitation-form"
          element={<FeaturedForm element={<InvitationForm />} />}
        />
        <Route
          path="/offer-form"
          element={<FeaturedForm element={<NewOffer merchant={false} />} />}
        />
        <Route
          path="/offer-list"
          element={<FeaturedForm element={<OfferList />} />}
        />
        <Route
          path="/invitation-list"
          element={<FeaturedForm element={<InvitationList />} />}
        />
        <Route
          path="/merchant-list"
          element={<FeaturedForm element={<MerchantList />} />}
        />
        <Route
          path="/add-assets"
          element={<FeaturedForm element={<AddAssets />} />}
        />
        <Route
          path="/promotions-list"
          element={<FeaturedForm element={<PromotionList />} />}
        />
        <Route
          path="/referral-list"
          element={<FeaturedForm element={<ReferralList />} />}
        />
        <Route
          path="/fundraiser-list"
          element={<FeaturedForm element={<FundraiserList />} />}
        />
        <Route
          path="/merchant-inquiries"
          element={<FeaturedForm element={<Inquiries />} />}
        />
        <Route
          path="/fundraiser-inquiries"
          element={<FeaturedForm element={<Inquiries />} />}
        />
      </Routes>
    </>
  );
};

export default AdminRoutes;
