import React from "react";
import "./common.scss";
import { ReactComponent as CheckedRadio } from "../../Assets/Icons/checkedRadio.svg";
import { ReactComponent as UncheckedRadio } from "../../Assets/Icons/uncheckedRadio.svg";

const RadioButton = ({ divClassName, text, checked, onChecked, textClass }) => {
  return (
    <>
      <div className={divClassName}>
        {checked == true ? (
          <CheckedRadio onClick={onChecked} className="radio" />
        ) : (
          <UncheckedRadio onClick={onChecked} className="radio" />
        )}
        <label className={`cursor-pointer ${textClass}`} onClick={onChecked}>
          {text}
        </label>
      </div>
    </>
  );
};

export default RadioButton;
