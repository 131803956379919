import React from "react";
import { useDispatch, useSelector } from "react-redux";
import WelcomeList from "./WelcomeList";
import PrimaryButton from "../PrimaryButton";
import { createConnectedAccount } from "../../../services/fundraiserService";
import { ReactComponent as DollarIcon } from "../../../Assets/Icons/dollarIcon.svg";
import { ReactComponent as TimeIcon } from "../../../Assets/Icons/timeIcon.svg";
import { ReactComponent as ListIcon } from "../../../Assets/Icons/listIcon.svg";

const FundraiserWelcome = () => {
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const linkPromise = await dispatch(createConnectedAccount()).unwrap();
    window.location.href = linkPromise?.data;
  };

  const welcomeList = [
    {
      heading: "How long does it take?",
      text: "Building a new offer typically takes 10 minutes. After you submit your offer, we’ll review it within 1 business day.",
      icon: <TimeIcon />,
    },
    {
      heading: "What does it cost?",
      text: "There’s zero upfront cost to build an offer.",
      icon: <DollarIcon />,
    },
    {
      heading: "Do I need anything before I get started?",
      text: "Have the following documents ready, you’ll need this information to complete the next steps:",
      icon: <ListIcon />,
    },
  ];
  return (
    <div className="sm:w-[80%] md:w-[70%] lg:w-[60%] py-20">
      <p className="font-poppins font-[500] text-[28px] text-center text-[#303030]">
        Welcome to Swoodle Fundraiser!
      </p>
      <p className="font-poppins font-[400] text-[14px] text-center text-[#767676]">
        Select get started to build your fundraising campaign.
      </p>
      <p className="font-poppins font-[500] text-[18px] text-start text-[#303030] mt-10">
        Account Setup
      </p>
      <p className="font-poppins font-[400] text-[14px] text-start text-[#767676] mt-2">
        Initiate your Swoodle fundraising campaign effortlessly. To facilitate
        fund reception, we'll guide you through the quick setup of your Stripe
        account. Simply click on "Get Started" to commence the process.
      </p>
      <div className="flex flex-col gap-5 mt-10">
        {welcomeList?.map((item) => (
          <WelcomeList
            heading={item?.heading}
            text={item?.text}
            icon={item?.icon}
          />
        ))}
      </div>
      <div className="w-full text-end mt-20">
        <PrimaryButton
          className={
            "w-[20%] font-montserrat text-[14px] text-center font-[500] h-[45px] rounded-[5px]"
          }
          label="Get Started"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default FundraiserWelcome;
