import React from "react";
import eyeShow from "../../../Assets/Icons/eyeShow.svg";
import eyeHide from "../../../Assets/Icons/eyeHide.svg";

const TextInput = ({
  Icon,
  type,
  name,
  value,
  placeholder,
  inputClass,
  iconAreaClass,
  handleChange,
  handleBlur,
  error,
  touched,
  inputDivClass,
  innerDivClass,
  showPassword,
  togglePassword,
  autoComplete,
  onClick
}) => {
  return (
    <div
      className={`w-full flex flex-col ${inputDivClass ? inputDivClass : ""}`}
    >
      <div
        className="relative flex h-[35px] sm:h-[45px] md:h-[50px]"
      >
        <div
          className={`flex justify-center items-center ${
            iconAreaClass && iconAreaClass
          }`}
        >
          <img src={Icon} className="w-[30%]" alt="icon"></img>
        </div>

        <input
          type={type ? type : "text"}
          className={`w-[85%] outline-none ps-2 text-[12px] md:text-[14px] font-[400] font-poppins ${inputClass}`}
          placeholder={placeholder}
          name={name}
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
          autoComplete={autoComplete ? autoComplete : "off"}
          onClick={onClick}
        />
        {name === "password" || name === "confirmPassword" ? (
          <div className="absolute right-[2%] top-0 h-full flex flex-col justify-center">
            {showPassword ? (
              <img
                src={eyeShow}
                className="cursor-pointer w-[25px]"
                onClick={togglePassword}
                alt="eye show"
              />
            ) : (
              <img
                src={eyeHide}
                className="cursor-pointer w-[25px]"
                onClick={togglePassword}
                alt="eye hide"
              />
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      {error && touched ? (
        <span className="text-red-600 text-[10px] sm:text-[10px] md:text-[12px] font-[600] font-poppins ms-1 mt-1">
          {error}
        </span>
      ) : null}
    </div>
  );
};

export default TextInput;
