import axios from "../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getNotificationsByUser = createAsyncThunk(
  "notification",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.get("notification");
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);