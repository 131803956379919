import React, { useEffect, useState } from "react";
import BreadCrumsHeader from "../../common/BreadCrums";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import Pagination from "../../common/TablePagination";
import "./banksList.scss";
import SearchBar from "../../common/SearchBar";
import CreateNewButton from "../../common/CreateNewButton";
import { useDispatch, useSelector } from "react-redux";
import { getBanks } from "../../../services/fundraiserService";
import Loader from "../../common/Loader";

const BanksListTable = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.fundraiserReducer?.loading);
  const [allBanks, setAllBanks] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);

  const getAllBanks = async () => {
    const banksPromise = await dispatch(getBanks())?.unwrap();
    if (banksPromise?.success === true) {
      setAllBanks(banksPromise?.data);
      setCurrentPage(banksPromise?.currentPage);
      setTotalPages(banksPromise?.totalPages);
      setCount(banksPromise?.count);
    }
  };

  useEffect(() => {
    getAllBanks();
  }, []);

  const items = [
    {
      title: "Connect with the Bank",
    },
    {
      title: "List of Banks",
    },
  ];

  const columns = [
    {
      name: "No.",
    },
    {
      name: "Account Title",
    },
    {
      name: "Bank Name",
    },
    {
      name: "Branch Name",
    },
    {
      name: "Branch Code",
    },
    {
      name: "Account Last 4",
    },
    {
      name: "Status",
    }
  ];

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      {loading === true && <Loader />}
      <div className="flex flex-col justify-between col-span-12">
        <div className="flex justify-between col-span-12">
          <BreadCrumsHeader heading="List of Banks" items={items} />
          <CreateNewButton
            text={"Add New Bank"}
            navigateTo={"/fundraiser/add-bank"}
            iconClassName="sm:left-[8%] md:left-[15%] bottom-[30%]"
          />
        </div>
        <SearchBar
          divClassName="relative"
          inputClassName="searchBar mt-[4%] p-3 sm:w-[50%] lg:w-[33%] text-[14px] font-poppins font-[400] outline-none"
          iconClassName="absolute sm:left-[43%] sm:bottom-[20%] lg:left-[29%] xl:left-[30.5%] lg:bottom-[15%]"
          placeholder="Search"
        />
      </div>

      <div className="col-span-12">
        <TableContainer className="w-[100%]">
          <Table
            sx={{ borderCollapse: "separate", borderSpacing: "0px 8px" }}
            aria-label="simple table"
          >
            <TableHead className="bg-[#00ADEF]">
              <TableRow
                className="h-[50px]"
                sx={{ boxShadow: "0px 4px 4px 0px #00000040" }}
              >
                {columns.map((column) => {
                  return <TableCell>{column.name}</TableCell>;
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {allBanks?.map((bank, index) => {
                return (
                  <TableRow
                    className="h-[50px]"
                    sx={{
                      boxShadow: "0px 4px 4px 0px #00000040",
                      backgroundColor:
                        (index + 1) % 2 !== 0 ? "#F5F5F5" : "#FFFFFF",
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{bank?.accountHolderName}</TableCell>
                    <TableCell>{bank?.bankName}</TableCell>
                    <TableCell>{bank?.branchName}</TableCell>
                    <TableCell>{bank?.branchCode}</TableCell>
                    <TableCell>{bank?.last4}</TableCell>
                    <TableCell>{bank?.status}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          totalPages={totalPages}
          handlePageChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default BanksListTable;
