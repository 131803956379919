import React, { useState } from "react";
import "./newMember.scss";
import { useNavigate } from "react-router-dom";
import Profile from "../../../Assets/Images/Profile.svg";
import person from "../../../Assets/Images/Person.svg";
import emailIcon from "../../../Assets/Icons/emailIcon.svg";
import passwordIcon from "../../../Assets/Icons/passwordIcon.svg";
import AgeGroup from "../../../Assets/Icons/age-group.svg";
import Location from "../../../Assets/Icons/Location.svg";
import clubIcon from "../../../Assets/Icons/clubIcon.svg";
import TextInput from "../../common/SignUp/TextInput";
import FundraiserHeader from "../common/FundraiserHeader";
import FundraiserBodyHeader from "../common/FundraiserBodyHeader";
import PrimaryButton from "../../common/PrimaryButton";
// import MobileNewMemberFormBg from "../../../Assets/Images/Fundraiser/memberSetupMobile.svg";
import MobileNewMemberFormBg from "../../../Assets/Images/bgImgMobile.svg";
import NewMemberFormBg from "../../../Assets/Images/bgImgDesktop.svg";
import NewMemberFormBackground from "../../../Assets/Images/Fundraiser/newMemberSetup.svg";
import PhoneFormInput from "../../common/PhoneInput";
import { useFormik } from "formik";
import { memberSetUpSchema } from "../../../utils/validationSchemas";
import { useDispatch, useSelector } from "react-redux";
import { setSignUpData } from "../../../redux/slices/authSlice";
import { signUp } from "../../../services/authService";
import Checkbox from "../../common/Checkbox";
import GoogleMaps from "../../common/GoogleMaps";
import Selector from "../../common/Selector";
import { ReactComponent as DownArrow } from "../../../Assets/Icons/Down Arrow.svg";
import { getFcmToken } from "../../../utils/connectStore";

const MemberSetup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const emailData = useSelector((state) => state.authReducer.email);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const [consentCheckbox, setConsentCheckbox] = useState(false);
  const [locationDetails, setLocationDetails] = useState(null);
  const [showMap, setShowMap] = useState(false);
  const content = useSelector(
    (state) => state.fundraiserReducer.fundraiserContent
  );

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPassword = () => {
    setConfirmShowPassword(!showConfirmPassword);
  };

  const navigation = () => {
    navigate("/existing-login", {
      state: {
        email: email,
      },
    });
  };

  const handleLocationSelect = (selectedLocation) => {
    setFieldValue("location", selectedLocation?.placeName);
    setLocationDetails(selectedLocation);
  };

  const handleLocationClick = () => {
    setShowMap(true);
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      gender: "",
      email: emailData,
      phone: "",
      age: undefined,
      location: "",
      password: "",
      confirmPassword: "",
      club: "",
      presidentEmail: "",
    },
    validationSchema: memberSetUpSchema,
    onSubmit: async (values) => {
      // Handle form submission
      console.log(values);
      try {
        const payload =
          content !== null
            ? {
                email: values?.email,
                name: values?.firstName + " " + values?.lastName,
                gender: values?.gender,
                password: values?.password,
                phone: values?.phone,
                age: values?.age,
                lat: locationDetails !== null ? locationDetails?.lat : null,
                lng: locationDetails !== null ? locationDetails?.lng : null,
                role: "user",
                club: values?.club,
                presidentEmail: values?.presidentEmail,
                referredBy: content?._id,
                fcmToken: getFcmToken(),
              }
            : {
                email: values?.email,
                name: values?.firstName + " " + values?.lastName,
                gender: values?.gender,
                password: values?.password,
                phone: values?.phone,
                age: values?.age,
                lat: locationDetails !== null ? locationDetails?.lat : null,
                lng: locationDetails !== null ? locationDetails?.lng : null,
                role: "user",
                club: values?.club,
                presidentEmail: values?.presidentEmail,
                fcmToken: getFcmToken(),
              };
        const signUpPromise = await dispatch(signUp(payload)).unwrap();
        if (signUpPromise?.success === true) {
          navigation();
          dispatch(setSignUpData(payload));
        }
      } catch (error) {
        throw error;
      }
    },
  });

  const {
    firstName,
    lastName,
    gender,
    email,
    phone,
    age,
    location,
    password,
    confirmPassword,
    club,
    presidentEmail,
  } = formik.values;

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = formik;

  const genderOptions = [
    {
      value: "male",
      label: "Male",
    },
    {
      value: "female",
      label: "Female",
    },
    {
      value: "other",
      label: "Other",
    },
  ];

  const handleGenderChange = (e) => {
    setFieldValue("gender", e);
  };

  return (
    <form onSubmit={handleSubmit}>
      <FundraiserHeader />

      <div className="flex flex-col items-center">
        <FundraiserBodyHeader
          heading={"New Member Setup"}
          content={"Please enter your details"}
          contentClass={"w-[100%] sm:w-[80%] md:w-[90%] text-[#8C8C8C]"}
        />
        <div className="relative mt-12 sm:mt-24 mb-14">
          {
            <img
              src={MobileNewMemberFormBg}
              className={`${
                showMap ? "mobileBgWithMap" : "w-[280px]"
              } sm:hidden block`}
            />
          }
          {showMap ? (
            <img
              src={NewMemberFormBg}
              className={`hidden sm:block sm:w-[560px] md:w-[580px]`}
            />
          ) : (
            <>
              <img
                src={NewMemberFormBg}
                className={`hidden sm:block md:hidden sm:w-[410px]`}
              />
              <img
                src={NewMemberFormBackground}
                className="hidden md:block md:w-[740px]"
              />
            </>
          )}

          <div
            className={`absolute  ${
              showMap
                ? "top-[-2px] sm:top-[22px] md:top-[12px]"
                : "top-[-5px] sm:top-[5px] md:top-[22px]"
            } w-full mt-[-10%]`}
          >
            <div className="flex justify-center">
              <img
                src={Profile}
                className="w-[70px] sm:w-[80px] md:w-[104px]"
              ></img>
            </div>
            <div className="px-[6%] mt-5 newMember inputFieldsDiv">
              <div className="sm:flex justify-between sm:mb-2">
                <div className="w-full sm:w-[47%]">
                  <TextInput
                    Icon={person}
                    name={"firstName"}
                    value={firstName}
                    placeholder={"First Name"}
                    inputClass={
                      "w-full rounded-tr-[10px] rounded-br-[10px] border-none"
                    }
                    iconAreaClass={
                      "w-[15%] sm:w-[29%] bg-[#E1E1E1] rounded-tl-[10px] rounded-bl-[10px] border-none"
                    }
                    inputDivClass={"mb-2 sm:mb-0 h-[45px] sm:h-[65px]"}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    error={errors.firstName}
                    touched={touched.firstName}
                  />
                </div>

                <div className="w-full sm:w-[47%]">
                  <TextInput
                    Icon={person}
                    name={"lastName"}
                    value={lastName}
                    placeholder={"Last Name"}
                    inputClass={
                      "w-full rounded-tr-[10px] rounded-br-[10px] border-none"
                    }
                    iconAreaClass={
                      "w-[15%] sm:w-[29%] bg-[#E1E1E1] rounded-tl-[10px] rounded-bl-[10px] border-none"
                    }
                    inputDivClass={"mb-2 sm:mb-0 h-[45px] sm:h-[65px]"}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    error={errors.lastName}
                    touched={touched.lastName}
                  />
                </div>
              </div>
              <Selector
                label={""}
                labelClassName="lg:text-[14px] sm:text-[12px] font-[400] text-[#303030] mb-2 font-poppins lg:leading-[27px] sm:leading-[21px]"
                divClassName="w-[100%] mb-4 sm:mb-6"
                selectClassName="w-full h-[35px] sm:h-[45px] rounded-[8px] bg-[#FFFFFF] text-[#303030] text-[14px] font-[400] outline-none !font-poppins !focus:outline-none !border-none shadow-[0_4px_4px_0px_rgba(0,0,0,0.06)]"
                flexClassName="gap-1"
                options={genderOptions}
                suffixIcon={<DownArrow />}
                placeholder={"Please Select Gender"}
                selectFundraiser={false}
                onChange={handleGenderChange}
                handleBlur={handleBlur}
                error={errors.gender}
                touched={touched.gender}
              />
              <TextInput
                Icon={emailIcon}
                name={"email"}
                value={email}
                placeholder={"Email ID"}
                inputClass={
                  "w-full rounded-tr-[10px] rounded-br-[10px] border-none"
                }
                iconAreaClass={
                  "w-[15%] sm:w-[12%] bg-[#E1E1E1] rounded-tl-[10px] rounded-bl-[10px] border-none"
                }
                inputDivClass={"mb-2 sm:mb-2 h-[45px] sm:h-[65px]"}
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={errors.email}
                touched={touched.email}
                autoComplete={"email"}
              />
              <PhoneFormInput
                divClassName="mb-4 sm:mb-2 h-[45px] sm:h-[65px]"
                name="phone"
                value={phone}
                handleBlur={handleBlur}
                error={errors.phone}
                touched={touched.phone}
                setFieldValue={setFieldValue}
                inputStyle={{
                  width: "100%",
                  border: "none",
                }}
                buttonStyle={{ border: "none" }}
                buttonClass="!rounded-l-[10px] !bg-[#E1E1E1] !w-[15%] sm:!w-[10.5%]"
                containerClass="!rounded-[10px]"
                inputClass="!h-[35px] sm:!h-[45px] md:!h-[50px] !rounded-[10px] !bg-[#FFFFFF] !text-[#303030] lg:!text-[16px] sm:!text-[14px] !font-[400] !outline-none !font-poppins !pl-[17%] md:!pl-[12%] sm:!pl-[12%] lg:!pl-[12%]"
                labelClass="text-[14px] font-[400] text-[#303030] font-poppins"
                errorClass={
                  "text-red-600 text-[10px] sm:text-[10px] md:text-[12px] font-[600] font-poppins ms-1"
                }
              />

              <TextInput
                Icon={AgeGroup}
                type="number"
                name={"age"}
                value={age}
                placeholder={"Age"}
                inputClass={
                  "w-full rounded-tr-[10px] rounded-br-[10px] border-none"
                }
                iconAreaClass={
                  "w-[15%] sm:w-[12%] bg-[#E1E1E1] rounded-tl-[10px] rounded-bl-[10px] border-none"
                }
                inputDivClass={"mb-2 sm:mb-2 h-[45px] sm:h-[65px]"}
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={errors.age}
                touched={touched.age}
              />

              <TextInput
                Icon={Location}
                name={"location"}
                value={location}
                placeholder={"Location"}
                inputClass={
                  "w-full rounded-tr-[10px] rounded-br-[10px] border-none"
                }
                iconAreaClass={
                  "w-[15%] sm:w-[12%] bg-[#E1E1E1] rounded-tl-[10px] rounded-bl-[10px] border-none"
                }
                inputDivClass={"mb-2 sm:mb-2 h-[45px] sm:h-[65px]"}
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={errors.location}
                touched={touched.location}
                autoComplete={"new-password"}
                onClick={handleLocationClick}
              />
              {showMap && (
                <GoogleMaps
                  onLocationSelect={handleLocationSelect}
                  searchContainerClass="w-[57%] sm:w-[51%] md:w-[50%] lg:w-[45%] 2xl:w-[45%] absolute top-[60px] sm:top-[10px] left-[8px] sm:left-[37%] md:left-[38%] lg:left-[43%] 2xl:left-[43%]"
                />
              )}

              <TextInput
                Icon={passwordIcon}
                type={showPassword ? "text" : "password"}
                name={"password"}
                value={password}
                placeholder={"Enter Password "}
                inputClass={
                  "w-full rounded-tr-[10px] rounded-br-[10px] border-none"
                }
                iconAreaClass={
                  "w-[15%] sm:w-[12%] bg-[#E1E1E1] rounded-tl-[10px] rounded-bl-[10px] border-none"
                }
                inputDivClass={`${
                  showMap ? "mt-2" : ""
                } mb-2 sm:mb-2 h-[45px] sm:h-[65px]`}
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={errors.password}
                touched={touched.password}
                showPassword={showPassword}
                togglePassword={togglePassword}
                autoComplete={"new-password"}
              />

              <TextInput
                Icon={passwordIcon}
                type={showConfirmPassword ? "text" : "password"}
                name={"confirmPassword"}
                value={confirmPassword}
                placeholder={"Confirm Password"}
                inputClass={
                  "w-full rounded-tr-[10px] rounded-br-[10px] border-none"
                }
                iconAreaClass={
                  "w-[15%] sm:w-[12%] bg-[#E1E1E1] rounded-tl-[10px] rounded-bl-[10px] border-none"
                }
                inputDivClass={"mb-2 sm:mb-2 h-[45px] sm:h-[65px]"}
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={errors.confirmPassword}
                touched={touched.confirmPassword}
                showPassword={showConfirmPassword}
                togglePassword={toggleConfirmPassword}
              />

              <p className="text-[12px] md:text-[14px] font-[400] font-poppins text-[#303030] mb-3">
                For which organization you belong{" "}
                <span className="text-[#8C8C8C]">(Optional)</span>
              </p>
              <TextInput
                Icon={clubIcon}
                name={"club"}
                value={club}
                placeholder={"Fundraiser club"}
                inputClass={
                  "w-full rounded-tr-[10px] rounded-br-[10px] border-none"
                }
                iconAreaClass={
                  "w-[15%] sm:w-[12%] bg-[#E1E1E1] rounded-tl-[10px] rounded-bl-[10px] border-none"
                }
                inputDivClass={"mb-2 sm:mb-2 h-[45px] sm:h-[65px]"}
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={errors.club}
                touched={touched.club}
                autoComplete={"new-password"}
              />

              <TextInput
                Icon={emailIcon}
                name={"presidentEmail"}
                value={presidentEmail}
                placeholder={"Email of president of the club"}
                inputClass={
                  "w-full rounded-tr-[10px] rounded-br-[10px] border-none"
                }
                iconAreaClass={
                  "w-[15%] sm:w-[12%] bg-[#E1E1E1] rounded-tl-[10px] rounded-bl-[10px] border-none"
                }
                inputDivClass={"mb-2 sm:mb-2 h-[45px] sm:h-[65px]"}
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={errors.presidentEmail}
                touched={touched.presidentEmail}
              />

              <div className="customize">
                <Checkbox
                  text="I have agreed to the user's consent to contact the club president."
                  divClassName="flex items-start justify-start"
                  checked={consentCheckbox}
                  onChecked={() => {
                    setConsentCheckbox(!consentCheckbox);
                  }}
                  textClass="text-[12px] md:text-[14px] font-[400] font-poppins text-[#303030] ml-4"
                  // UncheckedIcon={UncheckedIcon}
                />
              </div>
              <div className="text-center">
                <PrimaryButton
                  type="submit"
                  className={
                    "mt-5 sm:mt-7 w-[100%] sm:w-[60%] text-[18px] font-[600] h-[50px] sm:h-[45px] md:h-[50px] continueButton rounded-[10px]"
                  }
                  label="Continue"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default MemberSetup;
