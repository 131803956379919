import React, { useState, useEffect } from "react";
import AdminSwoodle from "../../../Assets/Icons/adminDashboardLogo.svg";
import MerchantSwoodle from "../../../Assets/Icons/merchantDashboardLogo.svg";
import FundraiserSwoodle from "../../../Assets/Icons/fundraiserDahboardLogo.svg";
import DashboardActive from "../../../Assets/Icons/DashboardActive.svg";
import DashboardInActive from "../../../Assets/Icons/DashboardInActive.svg";
import MerchantsActive from "../../../Assets/Icons/MerchantsActive.svg";
import MerchantsInActive from "../../../Assets/Icons/MerchantsInActive.svg";
import FundraisersActive from "../../../Assets/Icons/FundraiserActive.svg";
import FundraisersInActive from "../../../Assets/Icons/FundraiserInActive.svg";
import OffersActive from "../../../Assets/Icons/OffersActive.svg";
import OffersInActive from "../../../Assets/Icons/OffersInActive.svg";
import PromotionsActive from "../../../Assets/Icons/PromotionsActive.svg";
import PromotionsInActive from "../../../Assets/Icons/PromotionsInActive.svg";
import TransactionsInActive from "../../../Assets/Icons/TransactionsInActive.svg";
import TransactionsActive from "../../../Assets/Icons/TransactionsActive.svg";
import BankActive from "../../../Assets/Icons/BankActive.svg";
import BankInActive from "../../../Assets/Icons/BankInActive.svg";
import ReferActive from "../../../Assets/Icons/ReferActive.svg";
import ReferInActive from "../../../Assets/Icons/ReferInActive.svg";
import MarketActive from "../../../Assets/Icons/marketingActive.svg";
import MarketInactive from "../../../Assets/Icons/marketingInactive.svg";
import RedemptionActive from "../../../Assets/Icons/redemptionIconActive.svg";
import RedemptionInActive from "../../../Assets/Icons/redemptionInactive.svg";
import { ExpandMoreOutlined } from "@mui/icons-material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./sidebar.scss";
import { roleEnum } from "../../../utils/utilFunctions";

const CustomSidebar = ({ isSideBarOpen, setisSideBarOpen }) => {
  const location = useLocation();
  const [activeRoute, setActiveRoute] = useState("");

  useEffect(() => {
    setActiveRoute(location.pathname);
  }, [location]);

  useEffect(() => {
    if (activeRoute == "/dashboard" || activeRoute == "/merchant/dashboard") {
      setSelected("");
      setSelectedList("Dashboard");
    }
  }, [activeRoute]);

  const [isMerchantListOpen, setIsMerchantListOpen] = useState(false);
  const [isFundraiserListOpen, setIsFundraiserListOpen] = useState(false);
  const [isOfferListOpen, setIsOfferListOpen] = useState(false);
  const [isAssetsOpen, setIsAssetsOpen] = useState(false);
  const [isFranchiseOpen, setIsFranchiseOpen] = useState(false);
  const [isTransactionsOpen, setIsTransactionsOpen] = useState(false);
  const [isBankOpen, setIsBankOpen] = useState(false);
  const [isReferOpen, setIsReferOpen] = useState(false);

  const [selected, setSelected] = useState("");
  const [selectedList, setSelectedList] = useState("Dashboard");

  const navigate = useNavigate();

  return isSideBarOpen ? (
    <div className="sm:w-[40%] md:w-[30%] lg:w-[25%] xl:w-[20%] z-10 shadow-[4px_4px_10px_0px_rgba(0,0,0,0.15)]">
      {activeRoute.includes(roleEnum.merchant) ? (
        <div className="h-[100vh] overflow-y-scroll" style={{scrollbarWidth: 'none'}}>
          <div className=" px-[10%] py-[8%] mb-3">
            <img
              className="w-[82%] xl:w-[60%]"
              src={MerchantSwoodle}
              alt="merchant"
            ></img>
          </div>
          <div
            className={`px-[10%] flex py-[8%] items-center cursor-pointer ${
              activeRoute === "/merchant/dashboard"
                ? "listItemDiv selectedListItem"
                : "listItemDiv"
            }`}
            onClick={() => {
              setSelected("");
              setSelectedList("Dashboard");
              navigate("/merchant/dashboard");
            }}
          >
            <img
              src={
                activeRoute === "/merchant/dashboard"
                  ? DashboardActive
                  : DashboardInActive
              }
              alt="Dashboard"
            ></img>
            <p className="ListItemText ms-6">Dashboard</p>
          </div>
          <div
            className={` px-[10%] flex py-[8%] items-center ${
              selectedList === "Offers"
                ? "listItemDiv selectedListItem"
                : "listItemDiv"
            }`}
          >
            <img
              src={selectedList === "Offers" ? OffersActive : OffersInActive}
            ></img>
            <p className="ListItemText ms-6">Offers</p>
            <ExpandMoreOutlined
              className={`ms-auto cursor-pointer ${
                isOfferListOpen ? "transform rotate-180" : ""
              }`}
              onClick={() => setIsOfferListOpen(!isOfferListOpen)}
            />
          </div>
          {isOfferListOpen && (
            <div className="gap-3 flex flex-col justify-between ps-[71px]">
              <Link
                to={"/merchant/offer-form"}
                className={
                  activeRoute === "/merchant/offer-form"
                    ? "subListItemText selectedSubListItem"
                    : "subListItemText"
                }
                onClick={() => {
                  setSelected("New Offer");
                  setSelectedList("Offers");
                }}
              >
                New Offer
              </Link>
              <Link
                to={"/merchant/offer-list"}
                className={
                  activeRoute === "/merchant/offer-list"
                    ? "subListItemText selectedSubListItem"
                    : "subListItemText"
                }
                onClick={() => {
                  setSelected("Offers List");
                  setSelectedList("Offers");
                }}
              >
                Offers List
              </Link>
            </div>
          )}
          <div
            className={` px-[10%] flex py-[8%] items-center ${
              selectedList === "Franchise"
                ? "listItemDiv selectedListItem"
                : "listItemDiv"
            }`}
          >
            <img
              src={
                selectedList === "Franchise"
                  ? MerchantsActive
                  : MerchantsInActive
              }
            ></img>
            <p className="ListItemText ms-6">Franchise</p>
            <ExpandMoreOutlined
              className={`ms-auto cursor-pointer ${
                isFranchiseOpen ? "transform rotate-180" : ""
              }`}
              onClick={() => setIsFranchiseOpen(!isFranchiseOpen)}
            />
          </div>
          {isFranchiseOpen && (
            <div className="gap-3 flex flex-col justify-between ps-[71px]">
              <Link
                to={"/merchant/franchise-form"}
                className={
                  activeRoute === "/merchant/franchise-form"
                    ? "subListItemText selectedSubListItem"
                    : "subListItemText"
                }
                onClick={() => {
                  setSelected("Add a Franchise");
                  setSelectedList("Franchise");
                }}
              >
                Add a Franchise
              </Link>
              <Link
                to={"/merchant/franchise-list"}
                className={
                  activeRoute === "/merchant/franchise-list"
                    ? "subListItemText selectedSubListItem"
                    : "subListItemText"
                }
                onClick={() => {
                  setSelected("Franchise List");
                  setSelectedList("Franchise");
                }}
              >
                Franchise List
              </Link>
            </div>
          )}
          <div
            className={`px-[10%] flex py-[8%] items-center cursor-pointer ${
              activeRoute === "/merchant/redemption"
                ? "listItemDiv selectedListItem"
                : "listItemDiv"
            }`}
            onClick={() => {
              setSelected("");
              setSelectedList("Redemption");
              navigate("/merchant/redemption");
            }}
          >
            <img
              src={
                activeRoute === "/merchant/redemption"
                  ? RedemptionActive
                  : RedemptionInActive
              }
              alt="Redemption"
            ></img>
            <p className="ListItemText ms-6">Redemption</p>
          </div>
        </div>
      ) : activeRoute.includes(roleEnum.fundraiser) ? (
        <div className="h-[100vh] overflow-y-scroll" style={{scrollbarWidth: 'none'}}>
          <div className=" px-[10%] py-[8%] mb-3">
            <img
              className="w-[82%] xl:w-[60%]"
              src={FundraiserSwoodle}
              alt="fundraiser"
            ></img>
          </div>
          <div
            className={`px-[10%] flex py-[8%] items-center cursor-pointer ${
              activeRoute === "/fundraiser/dashboard"
                ? "listItemDiv selectedListItem"
                : "listItemDiv"
            }`}
            onClick={() => {
              setSelected("");
              setSelectedList("Dashboard");
              navigate("/fundraiser/dashboard");
            }}
          >
            <img
              src={
                activeRoute === "/fundraiser/dashboard"
                  ? DashboardActive
                  : DashboardInActive
              }
              alt="Dashboard"
            ></img>
            <p className="ListItemText ms-6">Fundraiser Dashboard</p>
          </div>
          <div
            className={`px-[10%] flex py-[8%] items-center ${
              selectedList === "Transactions"
                ? "listItemDiv selectedListItem"
                : "listItemDiv"
            }`}
          >
            <img
              src={
                selectedList === "Transactions"
                  ? TransactionsActive
                  : TransactionsInActive
              }
            ></img>
            <p className="ListItemText ms-6">Transactions</p>
            <ExpandMoreOutlined
              className={`ms-auto cursor-pointer ${
                isTransactionsOpen ? "transform rotate-180" : ""
              }`}
              onClick={() => setIsTransactionsOpen(!isTransactionsOpen)}
            />
          </div>
          {isTransactionsOpen && (
            <div className="gap-3 flex flex-col justify-between ps-[71px]">
              <Link
                to={"/fundraiser/deposited-funds"}
                className={
                  activeRoute === "/fundraiser/deposited-funds"
                    ? "subListItemText selectedSubListItem"
                    : "subListItemText"
                }
                onClick={() => {
                  setSelected("Funds Deposited");
                  setSelectedList("Transactions");
                }}
              >
                Funds Deposited
              </Link>
              <Link
                to={"/fundraiser/withdrawals"}
                className={
                  activeRoute === "/fundraiser/withdrawals"
                    ? "subListItemText selectedSubListItem"
                    : "subListItemText"
                }
                onClick={() => {
                  setSelected("Withdrawals");
                  setSelectedList("Transactions");
                }}
              >
                Withdrawals
              </Link>
              <Link
                to={"/fundraiser/withdraw"}
                className={
                  activeRoute === "/fundraiser/withdraw"
                    ? "subListItemText selectedSubListItem"
                    : "subListItemText"
                }
                onClick={() => {
                  setSelected("Withdraw");
                  setSelectedList("Transactions");
                }}
              >
                Withdraw
              </Link>
            </div>
          )}
          <div
            className={` px-[10%] flex py-[8%] items-center ${
              selectedList === "Bank"
                ? "listItemDiv selectedListItem"
                : "listItemDiv"
            }`}
          >
            <img
              src={selectedList === "Bank" ? BankActive : BankInActive}
            ></img>
            <p className="ListItemText ms-6">Connect with the Bank</p>
            <ExpandMoreOutlined
              className={`ms-auto cursor-pointer ${
                isBankOpen ? "transform rotate-180" : ""
              }`}
              onClick={() => setIsBankOpen(!isBankOpen)}
            />
          </div>
          {isBankOpen && (
            <div className="gap-3 flex flex-col justify-between ps-[71px]">
              <Link
                to={"/fundraiser/add-bank"}
                className={
                  activeRoute === "/fundraiser/add-bank"
                    ? "subListItemText selectedSubListItem"
                    : "subListItemText"
                }
                onClick={() => {
                  setSelected("Add a Bank");
                  setSelectedList("Bank");
                }}
              >
                Add a Bank
              </Link>
              <Link
                to={"/fundraiser/banks-list"}
                className={
                  activeRoute === "/fundraiser/banks-list"
                    ? "subListItemText selectedSubListItem"
                    : "subListItemText"
                }
                onClick={() => {
                  setSelected("List of Banks");
                  setSelectedList("Bank");
                }}
              >
                List of Banks
              </Link>
            </div>
          )}
          <div
            className={` px-[10%] flex py-[8%] items-center ${
              selectedList === "Refer"
                ? "listItemDiv selectedListItem"
                : "listItemDiv"
            }`}
          >
            <img
              src={selectedList === "Refer" ? ReferActive : ReferInActive}
            ></img>
            <p className="ListItemText ms-6">Refer a Merchant</p>
            <ExpandMoreOutlined
              className={`ms-auto cursor-pointer ${
                isReferOpen ? "transform rotate-180" : ""
              }`}
              onClick={() => setIsReferOpen(!isReferOpen)}
            />
          </div>
          {isReferOpen && (
            <div className="gap-3 flex flex-col justify-between ps-[71px]">
              <Link
                to={"/fundraiser/refer-merchant"}
                className={
                  activeRoute === "/fundraiser/refer-merchant"
                    ? "subListItemText selectedSubListItem"
                    : "subListItemText"
                }
                onClick={() => {
                  setSelected("Refer New Merchant");
                  setSelectedList("Refer");
                }}
              >
                Refer New Merchant
              </Link>
              <Link
                to={"/fundraiser/referral-list"}
                className={
                  activeRoute === "/fundraiser/referral-list"
                    ? "subListItemText selectedSubListItem"
                    : "subListItemText"
                }
                onClick={() => {
                  setSelected("Referral List");
                  setSelectedList("Refer");
                }}
              >
                Referral List
              </Link>
            </div>
          )}
          <div
            className={` px-[10%] flex py-[8%] items-center cursor-pointer ${
              activeRoute === "/fundraiser/assets"
                ? "listItemDiv selectedListItem"
                : "listItemDiv"
            }`}
            onClick={() => {
              setSelected("");
              setSelectedList("Assets");
              navigate("/fundraiser/assets");
            }}
          >
            <img
              src={
                selectedList === "Assets"
                  ? PromotionsActive
                  : PromotionsInActive
              }
            ></img>
            <p className="ListItemText ms-6">Assets and Promotions</p>
          </div>
          <div
            className={` px-[10%] flex py-[8%] items-center cursor-pointer ${
              activeRoute === "/fundraiser/marketing"
                ? "listItemDiv selectedListItem"
                : "listItemDiv"
            }`}
            onClick={() => {
              setSelected("");
              setSelectedList("Marketing");
              navigate("/fundraiser/marketing");
            }}
          >
            <img
              src={selectedList === "Marketing" ? MarketActive : MarketInactive}
            ></img>
            <p className="ListItemText ms-6">Marketing Page</p>
          </div>
        </div>
      ) : (
        <div className="h-[100vh] overflow-y-scroll" style={{scrollbarWidth: 'none'}}>
          <div className=" px-[10%] py-[8%] mb-3">
            <img
              className="w-[82%] xl:w-[60%]"
              src={AdminSwoodle}
              alt="admin"
            ></img>
          </div>
          <div
            className={`px-[10%] flex py-[8%] items-center cursor-pointer ${
              activeRoute === "/dashboard"
                ? "listItemDiv selectedListItem"
                : "listItemDiv"
            }`}
            onClick={() => {
              setSelected("");
              setSelectedList("Dashboard");
              navigate("/dashboard");
            }}
          >
            <img
              src={
                activeRoute === "/dashboard"
                  ? DashboardActive
                  : DashboardInActive
              }
              alt="Dashboard"
            ></img>
            <p className="ListItemText ms-6">Dashboard</p>
          </div>
          <div
            className={`px-[10%] flex py-[8%] items-center ${
              selectedList === "Merchants"
                ? "listItemDiv selectedListItem"
                : "listItemDiv"
            }`}
          >
            <img
              src={
                selectedList === "Merchants"
                  ? MerchantsActive
                  : MerchantsInActive
              }
            ></img>
            <p className="ListItemText ms-6">Merchants</p>
            <ExpandMoreOutlined
              className={`ms-auto cursor-pointer ${
                isMerchantListOpen ? "transform rotate-180" : ""
              }`}
              onClick={() => setIsMerchantListOpen(!isMerchantListOpen)}
            />
          </div>
          {isMerchantListOpen && (
            <div className="gap-3 flex flex-col justify-between ps-[71px]">
              {/* <Link
                to={"/invitation-form"}
                className={
                  activeRoute === "/invitation-form"
                    ? "subListItemText selectedSubListItem"
                    : "subListItemText"
                }
                onClick={() => {
                  setSelected("Invitation");
                  setSelectedList("Merchants");
                }}
              >
                Invitation
              </Link>
              <Link
                to={"/invitation-list"}
                className={
                  activeRoute === "/invitation-list"
                    ? "subListItemText selectedSubListItem"
                    : "subListItemText"
                }
                onClick={() => {
                  setSelected("Invitation list");
                  setSelectedList("Merchants");
                }}
              >
                Invitation list
              </Link> */}
              <Link
                to={"/merchant-list"}
                className={
                  activeRoute === "/merchant-list"
                    ? "subListItemText selectedSubListItem"
                    : "subListItemText"
                }
                onClick={() => {
                  setSelected("Merchant list");
                  setSelectedList("Merchants");
                }}
              >
                Merchant list
              </Link>

              {/* <Link
                to={"/referral-list"}
                className={
                  activeRoute === "/referral-list"
                    ? "subListItemText selectedSubListItem"
                    : "subListItemText"
                }
                onClick={() => {
                  setSelected("Referral List");
                  setSelectedList("Merchants");
                }}
              >
                Referral List
              </Link> */}
              <Link
                to={"/merchant-inquiries"}
                className={
                  activeRoute === "/merchant-inquiries"
                    ? "subListItemText selectedSubListItem"
                    : "subListItemText"
                }
                onClick={() => {
                  setSelected("Inquiries");
                  setSelectedList("Merchants");
                }}
              >
                Inquiries
              </Link>
            </div>
          )}
          <div
            className={`px-[10%] flex py-[8%] items-center ${
              selectedList === "Fundraisers"
                ? "listItemDiv selectedListItem"
                : "listItemDiv"
            }`}
          >
            <img
              src={
                selectedList === "Fundraisers"
                  ? FundraisersActive
                  : FundraisersInActive
              }
            ></img>
            <p className="ListItemText ms-6">Fundraisers</p>
            <ExpandMoreOutlined
              className={`ms-auto cursor-pointer ${
                isFundraiserListOpen ? "transform rotate-180" : ""
              }`}
              onClick={() => setIsFundraiserListOpen(!isFundraiserListOpen)}
            />
          </div>
          {isFundraiserListOpen && (
            <div className="gap-3 flex flex-col justify-between ps-[71px]">
              <Link
                to={"/fundraiser-list"}
                className={
                  activeRoute === "/fundraiser-list"
                    ? "subListItemText selectedSubListItem"
                    : "subListItemText"
                }
                onClick={() => {
                  setSelected("Fundraiser list");
                  setSelectedList("Fundraisers");
                }}
              >
                Fundraiser list
              </Link>
              <Link
                to={"/fundraiser-inquiries"}
                className={
                  activeRoute === "/fundraiser-inquiries"
                    ? "subListItemText selectedSubListItem"
                    : "subListItemText"
                }
                onClick={() => {
                  setSelected("Inquiries");
                  setSelectedList("Fundraisers");
                }}
              >
                Inquiries
              </Link>
            </div>
          )}
          <div
            className={` px-[10%] flex py-[8%] items-center ${
              selectedList === "Offers"
                ? "listItemDiv selectedListItem"
                : "listItemDiv"
            }`}
          >
            <img
              src={selectedList === "Offers" ? OffersActive : OffersInActive}
            ></img>
            <p className="ListItemText ms-6">Offers</p>
            <ExpandMoreOutlined
              className={`ms-auto cursor-pointer ${
                isOfferListOpen ? "transform rotate-180" : ""
              }`}
              onClick={() => setIsOfferListOpen(!isOfferListOpen)}
            />
          </div>
          {isOfferListOpen && (
            <div className="gap-3 flex flex-col justify-between ps-[71px]">
              {/* <Link
                to={"/offer-form"}
                className={
                  activeRoute === "/offer-form"
                    ? "subListItemText selectedSubListItem"
                    : "subListItemText"
                }
                onClick={() => {
                  setSelected("New Offer");
                  setSelectedList("Offers");
                }}
              >
                New Offer
              </Link> */}
              <Link
                to={"/offer-list"}
                className={
                  activeRoute === "/offer-list"
                    ? "subListItemText selectedSubListItem"
                    : "subListItemText"
                }
                onClick={() => {
                  setSelected("Offers List");
                  setSelectedList("Offers");
                }}
              >
                Offers List
              </Link>
            </div>
          )}
          <div
            className={` px-[10%] flex py-[8%] items-center ${
              selectedList === "Assets"
                ? "listItemDiv selectedListItem"
                : "listItemDiv"
            }`}
          >
            <img
              src={
                selectedList === "Assets"
                  ? PromotionsActive
                  : PromotionsInActive
              }
            ></img>
            <p className="ListItemText ms-6">Assets and Promotions</p>
            <ExpandMoreOutlined
              className={`ms-auto cursor-pointer ${
                isAssetsOpen ? "transform rotate-180" : ""
              }`}
              onClick={() => setIsAssetsOpen(!isAssetsOpen)}
            />
          </div>
          {isAssetsOpen && (
            <div className="gap-3 flex flex-col justify-between ps-[71px]">
              <Link
                to={"/add-assets"}
                className={
                  activeRoute === "/add-assets"
                    ? "subListItemText selectedSubListItem"
                    : "subListItemText"
                }
                onClick={() => {
                  setSelected("Add Assets");
                  setSelectedList("Assets");
                }}
              >
                Add assets and Promotions
              </Link>
              <Link
                to={"/promotions-list"}
                className={
                  activeRoute === "/promotions-list"
                    ? "subListItemText selectedSubListItem"
                    : "subListItemText"
                }
                onClick={() => {
                  setSelected("Promotions List");
                  setSelectedList("Assets");
                }}
              >
                List of Promotions
              </Link>
            </div>
          )}
        </div>
      )}
    </div>
  ) : null;
};

export default CustomSidebar;
