import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  onMessage,
  isSupported,
} from "firebase/messaging";
import toast from "./utils/toast";
import { isUserRoute } from "./utils/utilFunctions";

const firebaseConfig = {
  apiKey: "AIzaSyATJQEizr0Siag8bNpfCvKhKEr3FzYBEkA",
  authDomain: "swoodle-dev-6973a.firebaseapp.com",
  projectId: "swoodle-dev-6973a",
  storageBucket: "swoodle-dev-6973a.appspot.com",
  messagingSenderId: "523563620333",
  appId: "1:523563620333:web:f18d014671626ef867b9e4",
  measurementId: "G-YX8ZGEM58H",
};
let app = null;
let messaging = null;
let sw = null;

if (isUserRoute === false) {
  app = initializeApp(firebaseConfig);
  messaging = isSupported() ? getMessaging(app) : null;

  sw =
    messaging !== null
      ? await navigator.serviceWorker.register("/firebase-messaging-sw.js", {
          scope: "/firebase-cloud-messaging-push-scope",
        })
      : null;
}
export { app, messaging };
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export const fetchToken = async () => {
  if (!messaging) return toast.error("Browser not supported!");
  return await getToken(messaging, {
    serviceWorkerRegistration: sw,
    vapidKey: process.env.REACT_APP_VAPID_KEY,
  });
};
