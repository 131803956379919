import React, { useEffect, useState } from "react";
import "./info.scss";
import InputTextArea from "../Common/InputTextArea";
import RadioSelection from "../../../common/RadioSelection";
import TimeTable from "../Common/TimeTable";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { moreInfoSchema } from "../../../../utils/validationSchemas";
import {
  getPotentialMerchant,
  saveMerchantData,
} from "../../../../services/onboardingService";
import { useDispatch, useSelector } from "react-redux";
import PrimaryButton from "../../../common/PrimaryButton";
import PrimaryOutlineButton from "../../../common/PrimaryOutlineButton";
import dayjs from "dayjs";
import Loader from "../../../common/Loader";

const MoreInformation = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.onboardingReducer?.loading);
  const navigate = useNavigate();
  const [merchant, setMerchant] = useState();
  const merchantData = useSelector(
    (state) => state?.onboardingReducer?.startedOnboarding
  );
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const format = "hh:mm A";

  const getMerchantData = async () => {
    const merchantPromise = await dispatch(
      getPotentialMerchant({ id: merchantData?._id })
    )?.unwrap();
    if (merchantPromise?.success === true) {
      setMerchant(merchantPromise?.data);
    }
  };

  useEffect(() => {
    getMerchantData();
  }, []);

  const formik = useFormik({
    initialValues: {
      aboutBusiness: "",
      physicalStore: "",
      timeSet: false,
      timeData: days?.map((d) => ({
        day: d,
        openTime: undefined,
        closeTime: undefined,
        isSelected: false,
      })),
    },
    validationSchema: moreInfoSchema,
    onSubmit: async (values) => {
      // Handle form submission
      console.log("values", values);
      const payload = {
        id: merchantData?._id,
        businessName: merchant?.businessName,
        abnNo: merchant?.abnNo,
        googlePlaceId: merchant?.location?.googlePlaceId,
        website: merchant?.website,
        merchantCategory: merchant?.merchantCategory,
        primaryContactDetails: merchant?.primaryContactDetails,
        marketingContactDetails: merchant?.marketingContactDetails,
        aboutUs: values?.aboutBusiness,
        hasPhysicalStore: values?.physicalStore == "Yes" ? true : false,
        tradingHours: timeData.map((day) => {
          if (day?.isSelected === true) {
            return {
              day: day?.name?.toLowerCase(),
              openTime: day?.openTime ? day?.openTime.format(format) : null,
              closeTime: day?.closeTime ? day?.closeTime.format(format) : null,
            };
          }
        }),
      };
      const savePromise = await dispatch(saveMerchantData(payload))?.unwrap();
      if (savePromise?.success === true) {
        navigate("/submit-information");
      }
    },
  });

  const { aboutBusiness, physicalStore, timeSet, timeData } = formik.values;

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
    setValues,
  } = formik;

  const handleTimeChange = (dayIndex, field, time) => {
    const updatedTimeData = [...timeData];
    updatedTimeData[dayIndex][field] = time;
    setFieldValue("timeData", updatedTimeData);
  };

  useEffect(() => {
    const allTimeSet = timeData?.every((day) => day.isSelected === false);
    setFieldValue("timeSet", allTimeSet);
  }, [timeData]);

  useEffect(() => {
    if (merchant !== undefined && merchant !== null) {
      merchant?.tradingHours?.map((th, index) => {
        handleTimeChange(index, "openTime", dayjs(th?.openTime, format));
        handleTimeChange(index, "closeTime", dayjs(th?.closeTime, format));
      });
      setValues({
        aboutBusiness: merchant?.aboutUs,
        physicalStore: merchant?.hasPhysicalStore === true ? "Yes" : "No",
      });
    }
  }, [merchant]);

  const saveClick = async () => {
    const payload = {
      id: merchantData?._id,
      businessName: merchant?.businessName,
      abnNo: merchant?.abnNo,
      googlePlaceId: merchant?.location?.googlePlaceId,
      website: merchant?.website,
      merchantCategory: merchant?.merchantCategory,
      primaryContactDetails: merchant?.primaryContactDetails,
      marketingContactDetails: merchant?.marketingContactDetails,
      aboutUs: aboutBusiness,
      hasPhysicalStore: physicalStore == "Yes" ? true : false,
      tradingHours: timeData.map((day) => {
        if (day?.isSelected === true) {
          return {
            day: day?.name?.toLowerCase(),
            openTime: day?.openTime ? day?.openTime.format(format) : null,
            closeTime: day?.closeTime ? day?.closeTime.format(format) : null,
          };
        }
      }),
    };
    const savePromise = await dispatch(saveMerchantData(payload))?.unwrap();
    if (savePromise?.success === true) {
      window.history.pushState(null, null, window.location.href);
      window.location.href = "https://swoodle.wpenginepowered.com/";
    }
  };
  const radioOptions = ["Yes", "No"];
  return (
    <div className="px-9 py-10">
      {loading === true && <Loader />}
      <div className="">
        <p className="lg:text-[20px] sm:text-[18px] font-[500] text-[#303030] font-poppins">
          More Information
        </p>
      </div>

      <InputTextArea
        label={"Tell us a bit more about your Business"}
        width={"100%"}
        placeholder={"Let us know why they should visit or buy from you"}
        name="aboutBusiness"
        value={aboutBusiness}
        handleBlur={handleBlur}
        error={errors.aboutBusiness}
        touched={touched.aboutBusiness}
        setFieldValue={setFieldValue}
      />

      <div className="">
        <p className="lg:text-[20px] sm:text-[18px] font-[500] text-[#303030] font-poppins">
          Type of Business
        </p>
      </div>
      <div className="w-full mt-5">
        <RadioSelection
          optionClass="lg:text-[16px] sm:text-[14px] font-[400] text-[#000000] ml-1 font-poppins"
          options={radioOptions}
          mapClass="flex"
          internalDivClass="flex"
          secondRadioClass="ml-5"
          textClass="lg:text-[14px] sm:text-[12px] font-[500] text-[#303030] ml-4"
          label={"Does your business have a physical store?"}
          width={"100%"}
          name="physicalStore"
          value={physicalStore}
          handleChange={handleChange}
          handleBlur={handleBlur}
          error={errors.physicalStore}
          touched={touched.physicalStore}
          setFieldValue={setFieldValue}
        />
      </div>

      <div className="mt-5">
        <p className="lg:text-[20px] sm:text-[18px] font-[500] text-[#303030] font-poppins">
          Trading Hours
        </p>
        <TimeTable
          label={"What are your current trading hours?"}
          labelClass="lg:text-[16px] sm:text-[14px] font-[400] text-[#303030] mb-3 font-poppins"
          openClass="lg:text-[16px] sm:text-[14px] font-[400] text-[#8C8C8C] sm:ml-[5rem] md:ml-[5rem] lg:ml-[3rem] xl:ml-[8rem] font-poppins"
          closeClass="lg:text-[16px] sm:text-[14px] font-[400] text-[#8C8C8C] sm:ml-[3rem] md:ml-[3rem] lg:ml-[6rem] xl:mr-[3rem] font-poppins"
          dayClass="lg:text-[16px] sm:text-[14px] font-[400] text-[#8C8C8C] mb-2 sm:mr-[25px] md:mr-[50px] lg:mr-[70px] xl:mr-[100px] sm:w-[20%] lg:w-[10%] xl:w-[10%] font-poppins"
          timeDivClass="flex sm:w-[70%] md:w-[70%] lg:w-[70%] xl:w-[70%] items-baseline"
          toClass="text-center sm:mx-[0.25rem] md:mx-[0.5rem] lg:mx-[1rem] lg:text-[16px] sm:text-[14px] sm:w-[10%] md:w-[10%] lg:w-[10%] xl:w-[10%] font-poppins"
          timePickerClass="sm:w-[95%] md:w-full lg:h-[40px] sm:h-[30px] rounded-[5px] border border-[#E4E3E4] cursor-pointer"
          width={"70%"}
          format={format}
          timeData={timeData}
          handleTimeChange={handleTimeChange}
          error={errors?.timeData}
          touched={touched?.timeData}
          setFieldValue={setFieldValue}
        />
        {errors?.timeSet && touched?.timeSet ? (
          <span className="text-red-600 sm:text-[10px] md:text-[12px] font-[600] ms-1">
            {errors?.timeSet}
          </span>
        ) : null}
      </div>

      <div className=" flex justify-between text-end mt-8 w-full">
        <PrimaryOutlineButton
          label={"Save And Exit"}
          className={
            "lg:w-[30%] 2xl:w-[25%] sm:w-[35%] lg:h-[50px] sm:h-[50px] rounded-[5px] lg:text-[18px] sm:text-[16px]"
          }
          onClick={saveClick}
        />
        <PrimaryButton
          className={
            "lg:w-[30%] 2xl:w-[25%] sm:w-[35%] lg:h-[50px] sm:h-[50px] rounded-[5px] lg:text-[18px] sm:text-[16px] font-[500]"
          }
          label="Continue"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default MoreInformation;
