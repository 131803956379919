import React, { useEffect, useState } from "react";
import SelectOption from "./SelectOption";
import { useFormik } from "formik";
import PrimaryButton from "../PrimaryButton";
import { categorySchema } from "../../../utils/validationSchemas";
import { useDispatch, useSelector } from "react-redux";
import { createOffer } from "../../../services/offerService";
import Loader from "../Loader";
import GreyButton from "../GreyButton";
import { useNavigate } from "react-router-dom";

const CategorySelection = ({
  setSubCategories,
  stepIndex,
  setStepIndex,
  categoryId,
  setCategoryId,
  setPayloadData,
  categories,
  editOfferData,
  isEdit,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState(undefined);
  const loading = useSelector((state) => state?.offerReducer?.loading);
  const offerData = useSelector((state) => state?.offerReducer?.createdOffer);
  const [payload, setPayload] = useState(null);
  
  const handleGoBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (categoryId == undefined && editOfferData !== null) {
      handleClick(editOfferData?.category?._id);
    } else if (offerData !== null) {
      handleClick(offerData?.category?._id);
    }
  }, [categories, offerData, editOfferData]);

  const initialValues = {
    category: "",
  };

  useEffect(() => {
    setPayloadData(payload);
  }, [payload]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: categorySchema,

    onSubmit: async (values) => {
      console.log("create values", values);
      if (payload !== null) {
        const createPromise = await dispatch(createOffer(payload))?.unwrap();
        if (createPromise?.success === true) {
          setStepIndex(stepIndex + 1);
        }
      }
    },
  });

  const { category } = formik.values;
  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    setValues,
  } = formik;

  const handleClick = async (id) => {
    if (id !== undefined) {
      const cate = categories?.find((cat) => cat?._id === id);
      if (cate) {
        setSelectedCategory(cate);
        const approvedSubCategories = cate?.subCategory;
        setSubCategories(approvedSubCategories);
        setFieldValue("category", cate?.title);
        setCategoryId(cate?._id);

        if (categoryId !== undefined && categoryId !== id) {
          if (editOfferData?._id !== undefined) {
            setPayload({
              category: id,
              subCategory: [],
              id: editOfferData?._id,
              isCreated: false,
            });
          } else if (offerData?._id !== undefined) {
            setPayload({
              category: id,
              subCategory: [],
              id: offerData?._id,
              isCreated: false,
            });
          } else {
            setPayload({
              category: id,
              isCreated: false,
            });
          }
        } else if (categoryId !== undefined && categoryId === id) {
          if (editOfferData?._id !== undefined) {
            setPayload({
              category: id,
              id: editOfferData?._id,
              isCreated: false,
            });
          } else if (offerData?._id !== undefined) {
            setPayload({
              category: id,
              id: offerData?._id,
              isCreated: false,
            });
          } else {
            setPayload({
              category: id,
              isCreated: false,
            });
          }
        } else if (categoryId == undefined && editOfferData !== null) {
          if (editOfferData?.category?._id === id) {
            setPayload({
              category: id,
              id: editOfferData?._id,
              isCreated: false,
            });
          } else if (editOfferData?.category?._id !== id) {
            setPayload({
              category: id,
              subCategory: [],
              id: editOfferData?._id,
              isCreated: false,
            });
          }
        } else if (categoryId == undefined && editOfferData === null) {
          setPayload({
            category: id,
            isCreated: false,
          });
        }
      }
    }
  };

  const isCategoryClicked = (cat) => {
    return selectedCategory?._id === cat?._id;
  };

  return (
    <div className="w-full flex flex-col items-center justify-center mt-20">
      {loading === true && <Loader />}
      <div className="sm:w-[100%] md:w-[80%] lg:w-[70%] xl:w-[55%]">
        <p className="font-poppins font-[500] sm:text-[12px] md:text-[14px] lg:text-[16px] text-[#303030]">
          Which Category Best Describes your offer?
        </p>
        <p className="font-poppins font-[400] sm:text-[10px] md:text-[12px] text-[#767676]">
          Select One
        </p>
        <div className="mt-5">
          {categories?.length > 0 &&
            categories?.map((cat) => (
              <SelectOption
                clicked={isCategoryClicked(cat)}
                icon={process.env.REACT_APP_IMG_URL + cat?.image}
                title={cat?.title}
                handleClick={() => handleClick(cat?._id)}
                postIcon={null}
              />
            ))}
        </div>
        {errors?.category && touched?.category ? (
          <span className="text-red-600 sm:text-[10px] md:text-[12px] font-[600] ms-1 mt-1">
            Category is required.
          </span>
        ) : null}
        <div className="w-full text-end mt-20">
          <GreyButton
            className="w-[40%] lg:w-[25%] h-[45px] rounded-[5px] text-[16px] font-[500] font-montserrat"
            label="Cancel"
            onClick={handleGoBack}
          />
          <PrimaryButton
            className={
              "w-[40%] lg:w-[25%] h-[45px] rounded-[5px] text-[16px] font-[500] font-montserrat ms-2"
            }
            label="Next"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default CategorySelection;
