import axios from "../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getOfferMerchants = createAsyncThunk(
  "offer/merchants",
  async (thunkAPI) => {
    try {
      const response = await axios.get(`merchant?limit=${100}`);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const getMerchants = createAsyncThunk(
  "merchants",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.get(`merchant?page=${payload?.page}`);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const getMerchantInquiries = createAsyncThunk(
  "inquiry/merchant",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.get(
        `inquiry/merchant?page=${payload?.page}`
      );
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const toggleMerchantStatus = createAsyncThunk(
  "merchant/status",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post(`merchant/status/${payload?.id}`, {
        reason: payload?.reason,
      });
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const redeemOffer = createAsyncThunk(
  "offerRedeem/redeemOffer",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post("offerRedeem/redeemOffer", {
        code: payload?.code,
      });
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);
