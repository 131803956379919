import React, { useRef, useState } from "react";
import toast from "../../../utils/toast";
import addPhoto from "../../../Assets/Icons/imgIcon.svg";
import ImgDropzone from "../ImgDropzone";
import closeIcon from "../../../Assets/Icons/Red Cross no border.svg";
import AddPhoto from "./AddPhoto";
import { uploadFile } from "../../../utils/s3";

const UploadDocuments = ({
  previewImgs,
  setFieldValue,
  errors,
  touched,
  s3Urls,
  setS3Urls,
  logoFileImage1,
  setLogoFileImage1,
  logoFileImage2,
  setLogoFileImage2,
  logoFileImage3,
  setLogoFileImage3,
  logoFileImage4,
  setLogoFileImage4,
  offerId
}) => {
  const [logoFileData1, setLogoFileData1] = useState(null);
  const [logoFileData2, setLogoFileData2] = useState(null);
  const [logoFileData3, setLogoFileData3] = useState(null);
  const [logoFileData4, setLogoFileData4] = useState(null);
  const [logoFileName1, setLogoFileName1] = useState("");
  const [logoFileName2, setLogoFileName2] = useState("");
  const [logoFileName3, setLogoFileName3] = useState("");
  const [logoFileName4, setLogoFileName4] = useState("");
  const [loadingImg1, setLoadingImg1] = useState(false);
  const [loadingImg2, setLoadingImg2] = useState(false);
  const [loadingImg3, setLoadingImg3] = useState(false);
  const [loadingImg4, setLoadingImg4] = useState(false);

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const validateImage = (data) => {
    var idxDot = data.lastIndexOf(".") + 1;
    var extFile = data.substr(idxDot, data.length).toLowerCase();
    if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
      return true;
    } else {
      return false;
    }
  };

  const img1ChangeHandler = async (file) => {
    setLoadingImg1(true);
    let logoBase64 = "";
    let logoImageUrl1 = null;
    logoImageUrl1 = URL.createObjectURL(file[0]);
    setLogoFileName1(file[0].name);
    let checkValidImage = validateImage(file[0].name);
    if (checkValidImage) {
      const s3Url = await uploadFile(file[0], `${offerId}-previewImg1`);
      if (s3Url) {
        setS3Urls((prevS3Urls) => [...prevS3Urls, s3Url]);
        setLoadingImg1(false);
      }
      setLogoFileImage1(logoImageUrl1);
      getBase64(file[0], (result) => {
        logoBase64 = result.split(",")[1];
        logoBase64 = /,(.+)/.exec(result)[1];
        setLogoFileData1(logoBase64);
      });
    } else {
      setLoadingImg1(false);
      toast.error("Only jpg/jpeg and png files are allowed!");
    }
    setFieldValue("previewImgs", [...previewImgs, file[0].name]);
  };

  const img2ChangeHandler = async (file) => {
    setLoadingImg2(true);
    let logoBase64 = "";
    let logoImageUrl2 = null;
    logoImageUrl2 = URL.createObjectURL(file[0]);
    setLogoFileName2(file[0].name);
    let checkValidImage = validateImage(file[0].name);
    if (checkValidImage) {
      const s3Url = await uploadFile(file[0], `${offerId}-previewImg2`);
      if (s3Url) {
        setS3Urls((prevS3Urls) => [...prevS3Urls, s3Url]);
        setLoadingImg2(false);
      }
      setLogoFileImage2(logoImageUrl2);
      getBase64(file[0], (result) => {
        logoBase64 = result.split(",")[1];
        logoBase64 = /,(.+)/.exec(result)[1];
        setLogoFileData2(logoBase64);
      });
    } else {
      setLoadingImg2(false);
      toast.error("Only jpg/jpeg and png files are allowed!");
    }
    setFieldValue("previewImgs", [...previewImgs, file[0].name]);
  };

  const img3ChangeHandler = async (file) => {
    setLoadingImg3(true);
    let logoBase64 = "";
    let logoImageUrl3 = null;
    logoImageUrl3 = URL.createObjectURL(file[0]);
    setLogoFileName3(file[0].name);
    let checkValidImage = validateImage(file[0].name);
    if (checkValidImage) {
      const s3Url = await uploadFile(file[0], `${offerId}-previewImg3`);
      if(s3Url) {
        setS3Urls((prevS3Urls) => [...prevS3Urls, s3Url]);
        setLoadingImg3(false);
      }
      setLogoFileImage3(logoImageUrl3);
      getBase64(file[0], (result) => {
        logoBase64 = result.split(",")[1];
        logoBase64 = /,(.+)/.exec(result)[1];
        setLogoFileData3(logoBase64);
      });
    } else {
      setLoadingImg3(false);
      toast.error("Only jpg/jpeg and png files are allowed!");
    }
    setFieldValue("previewImgs", [...previewImgs, file[0].name]);
  };

  const img4ChangeHandler = async (file) => {
    setLoadingImg4(true);
    let logoBase64 = "";
    let logoImageUrl4 = null;
    logoImageUrl4 = URL.createObjectURL(file[0]);
    setLogoFileName4(file[0].name);
    let checkValidImage = validateImage(file[0].name);
    if (checkValidImage) {
      const s3Url = await uploadFile(file[0], `${offerId}-previewImg4`);
      if(s3Url) {
        setS3Urls((prevS3Urls) => [...prevS3Urls, s3Url]);
        setLoadingImg4(false);
      }
      setLogoFileImage4(logoImageUrl4);
      getBase64(file[0], (result) => {
        logoBase64 = result.split(",")[1];
        logoBase64 = /,(.+)/.exec(result)[1];
        setLogoFileData4(logoBase64);
      });
    } else {
      setLoadingImg4(false);
      toast.error("Only jpg/jpeg and png files are allowed!");
    }
    setFieldValue("previewImgs", [...previewImgs, file[0].name]);
  };

  const rejectedProofFilesHandler = (rejectedFiles) => {
    if (rejectedFiles?.length > 0) {
      rejectedFiles?.forEach((rejectedFile, index) => {
        rejectedFile.errors?.forEach((error) => {
          toast.error(error.code + " - " + error.message);
        });
      });
    }
  };

  const clearLogoImage1 = (fileName) => {
    const updatedPreviewImgs = previewImgs?.filter((p) => p !== fileName);
    setFieldValue("previewImgs", updatedPreviewImgs);
    setLogoFileData1();
    setLogoFileImage1(null);

    if (s3Urls.length > 0) {
      const updatedS3Urls = s3Urls.filter(
        (url) => !url.includes("previewImg1")
      );
      setS3Urls(updatedS3Urls);
    }
  };

  const clearLogoImage2 = (fileName) => {
    const updatedPreviewImgs = previewImgs?.filter((p) => p !== fileName);
    setFieldValue("previewImgs", updatedPreviewImgs);
    setLogoFileData2();
    setLogoFileImage2(null);

    if (s3Urls.length > 0) {
      const updatedS3Urls = s3Urls.filter(
        (url) => !url.includes("previewImg2")
      );
      setS3Urls(updatedS3Urls);
    }
  };

  const clearLogoImage3 = (fileName) => {
    const updatedPreviewImgs = previewImgs?.filter((p) => p !== fileName);
    setFieldValue("previewImgs", updatedPreviewImgs);
    setLogoFileData3();
    setLogoFileImage3(null);

    if (s3Urls.length > 0) {
      const updatedS3Urls = s3Urls.filter(
        (url) => !url.includes("previewImg3")
      );
      setS3Urls(updatedS3Urls);
    }
  };

  const clearLogoImage4 = (fileName) => {
    const updatedPreviewImgs = previewImgs?.filter((p) => p !== fileName);
    setFieldValue("previewImgs", updatedPreviewImgs);
    setLogoFileData4();
    setLogoFileImage4(null);

    if (s3Urls.length > 0) {
      const updatedS3Urls = s3Urls.filter(
        (url) => !url.includes("previewImg4")
      );
      setS3Urls(updatedS3Urls);
    }
  };

  const clearLogoImages = () => {
    setFieldValue("previewImgs", []);
    setS3Urls([]);
    setLogoFileData1(null);
    setLogoFileData2(null);
    setLogoFileData3(null);
    setLogoFileData4(null);
    setLogoFileImage1(null);
    setLogoFileImage2(null);
    setLogoFileImage3(null);
    setLogoFileImage4(null);
  };

  return (
    <div
      className="h-[406px] w-[100%] mx-auto rounded-[20px] mt-5"
      style={{
        border: "2px dashed #00ADEF",
        boxShadow: "0px 4px 20px 10px #79797926",
      }}
    >
      <div className="h-full mt-2 flex flex-col justify-center items-center w-full">
        <div className="flex flex-col sm:gap-2 md:gap-4 w-[90%] mb-4">
          <div className="flex sm:gap-2 md:gap-4 w-full justify-center">
            {logoFileImage1 ? (
              <div className="w-[40%] relative">
                <img
                  src={closeIcon}
                  className="absolute right-2 top-2 h-[13px] w-[13px] cursor-pointer bg-white rounded-[50%]"
                  onClick={() => clearLogoImage1(logoFileName1)}
                />
                <img
                  src={logoFileImage1}
                  className="h-[111px] rounded-[10px] w-full"
                ></img>
              </div>
            ) : (
              <div className="w-[40%]">
                <ImgDropzone
                  acceptedFiles={img1ChangeHandler}
                  rejectedFiles={rejectedProofFilesHandler}
                  maxLength={1}
                  supportText={""}
                  multiple={false}
                  img={
                    <AddPhoto
                      divClassName="w-full h-[111px]"
                      icon={addPhoto}
                      text={"Add Photo"}
                      index={1}
                      loading={loadingImg1}
                    />
                  }
                  accept="image/png, image/jpeg"
                ></ImgDropzone>
              </div>
            )}
            {logoFileImage2 ? (
              <div className="w-[40%] relative">
                <img
                  src={closeIcon}
                  className="absolute right-2 top-2 h-[13px] w-[13px] cursor-pointer bg-white rounded-[50%]"
                  onClick={() => clearLogoImage2(logoFileName2)}
                />
                <img
                  src={logoFileImage2}
                  className="h-[111px] rounded-[10px] w-full"
                ></img>
              </div>
            ) : (
              <div className="w-[40%]">
                <ImgDropzone
                  acceptedFiles={img2ChangeHandler}
                  rejectedFiles={rejectedProofFilesHandler}
                  maxLength={1}
                  supportText={""}
                  multiple={false}
                  img={
                    <AddPhoto
                      divClassName="w-full h-[111px]"
                      icon={addPhoto}
                      text={"Add Photo"}
                      index={2}
                      loading={loadingImg2}
                    />
                  }
                  accept="image/png, image/jpeg"
                ></ImgDropzone>
              </div>
            )}
          </div>
          <div className="flex sm:gap-2 md:gap-4 w-full justify-center">
            {logoFileImage3 ? (
              <div className="w-[40%] relative">
                <img
                  src={closeIcon}
                  className="absolute right-2 top-2 h-[13px] w-[13px] cursor-pointer bg-white rounded-[50%]"
                  onClick={() => clearLogoImage3(logoFileName3)}
                />
                <img
                  src={logoFileImage3}
                  className="h-[111px] rounded-[10px] w-full"
                ></img>
              </div>
            ) : (
              <div className="w-[40%]">
                <ImgDropzone
                  acceptedFiles={img3ChangeHandler}
                  rejectedFiles={rejectedProofFilesHandler}
                  maxLength={1}
                  supportText={""}
                  multiple={false}
                  img={
                    <AddPhoto
                      divClassName="w-full h-[111px]"
                      icon={addPhoto}
                      text={"Add Photo"}
                      index={3}
                      loading={loadingImg3}
                    />
                  }
                  accept="image/png, image/jpeg"
                ></ImgDropzone>
              </div>
            )}
            {logoFileImage4 ? (
              <div className="w-[40%] relative">
                <img
                  src={closeIcon}
                  className="absolute right-2 top-2 h-[13px] w-[13px] cursor-pointer bg-white rounded-[50%]"
                  onClick={() => clearLogoImage4(logoFileName4)}
                />
                <img
                  src={logoFileImage4}
                  className="h-[111px] rounded-[10px] w-full"
                ></img>
              </div>
            ) : (
              <div className="w-[40%] relative">
                <ImgDropzone
                  acceptedFiles={img4ChangeHandler}
                  rejectedFiles={rejectedProofFilesHandler}
                  maxLength={1}
                  supportText={""}
                  multiple={false}
                  img={
                    <AddPhoto
                      divClassName="w-full h-[111px]"
                      icon={addPhoto}
                      text={"Add Photo"}
                      index={4}
                      loading={loadingImg4}
                    />
                  }
                  accept="image/png, image/jpeg"
                ></ImgDropzone>
              </div>
            )}
          </div>
        </div>
        {!logoFileImage1 &&
          !logoFileImage2 &&
          !logoFileImage3 &&
          !logoFileImage4 && (
            <>
              <span className="sm:text-[16px] md:text-[20px] font-[400] text-[#8C8C8C] mb-1 font-nunito">
                Drag & Drop
              </span>
              <span className="sm:text-[16px] md:text-[20px] font-[400] text-[#8C8C8C] font-nunito">
                Your files here Or{" "}
                <span className="text-[#00ADEF]">Browse</span> to upload
              </span>
            </>
          )}
        {(logoFileImage1 ||
          logoFileImage2 ||
          logoFileImage3 ||
          logoFileImage4) && (
          <button
            type="button"
            className="my-5 shadow-none sm:w-[30%] h-[40px] rounded-[5px] bg-[#00ADEF] text-[16px] font-[500] text-[#FFFFFF]"
            onClick={clearLogoImages}
          >
            Clear
          </button>
        )}
      </div>
      <div className="ms-1">
        <span className="text-red-600 sm:text-[10px] md:text-[12px] font-[600] text-start">
          {errors.previewImgs && touched.previewImgs
            ? errors.previewImgs
            : null}
        </span>
      </div>
    </div>
  );
};

export default UploadDocuments;
