import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumsHeader from "../BreadCrums";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Delete from "../Delete";
import Confirmation from "./Confirmation";
import Pagination from "../TablePagination";
import { ReactComponent as Image } from "../../../Assets/Icons/Image.svg";
import { ReactComponent as LocationIcon } from "../../../Assets/Icons/Location.svg";
import { ReactComponent as ApprovedSubCategoryIcon } from "../../../Assets/Icons/approvedsubCategoryIcon.svg";
import { ReactComponent as SubCategoryIcon } from "../../../Assets/Icons/subCategoryIcon.svg";
import { ReactComponent as UnapprovedSubCatIcon } from "../../../Assets/Icons/unapprovedSubCatIcon.svg";
import { ReactComponent as ApprovedRulesIcon } from "../../../Assets/Icons/approvedRulesIcon.svg";
import { ReactComponent as RulesIcon } from "../../../Assets/Icons/rulesIcon.svg";
import { ReactComponent as UnapprovedRulesIcon } from "../../../Assets/Icons/unapprovedRulesIcon.svg";
import descriptionIcon from "../../../Assets/Icons/descriptionIcon.svg";
import infoIcon from "../../../Assets/Icons/infoIcon.svg";
import crossIcon from "../../../Assets/Icons/cross.svg";
import cautionIcon from "../../../Assets/Icons/cautionIcon.svg";
import {
  approveOrRejectResources,
  deleteOffer,
  getNonDraftedOffers,
  toggleOfferStatus,
} from "../../../services/offerService";
import ImagePopup from "../ImagePopup";
import LocationPopup from "../LocationPopup";
import CancelPopup from "../CancelPopup";
import deleteImg from "../../../Assets/Images/deleteImg.svg";
import { separateDateTimeAndConvertToLocale } from "../../../utils/utilFunctions";
import Loader from "../../common/Loader";
import DetailsPopup from "../DetailsPopup";
import SubCategoryPopup from "../SubCategoryPopup";
import GenericPopup from "../GenericPopup";
import PrimaryButton from "../PrimaryButton";
import PrimaryOutlineButton from "../PrimaryOutlineButton";
import RejectOfferPopup from "../RejectOfferPopup";
import Status from "../Status";

const AdminOfferlist = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.offerReducer?.loading);
  const [allOffers, setAllOffers] = useState();
  const [isImageOpen, setIsImageOpen] = useState(false);
  const [isLocationOpen, setIsLocationOpen] = useState(false);
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [count, setCount] = useState(0);
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
  const [isSubCategoryOpen, setIsSubCategoryOpen] = useState(false);
  const [isRulesOpen, setIsRulesOpen] = useState(false);
  const [isApprovalSubmitted, setIsApprovalSubmitted] = useState(false);
  const [pendingSuggestionsOpen, setPendingSuggestionsOpen] = useState(false);
  const [clickedOffer, setClickedOffer] = useState(null);
  const [selectedSubCategoriesIds, setSelectedSubCategoriesIds] = useState([]);
  const [nonSelectedSubCatIds, setNonSelectedSubCatIds] = useState([]);
  const [reason, setReason] = useState("");
  const [recallOffers, setRecallOffers] = useState(false);
  const [isRejectClicked, setIsRejectClicked] = useState(false);
  const [infoClicked, setInfoClicked] = useState(false);
  const [offerRejected, setOfferRejected] = useState(false);
  const [selectedOfferRejected, setSelectedOfferRejected] = useState(null);

  const items = [
    {
      title: "Offers",
    },
    {
      title: "Offers List",
    },
  ];

  const columns = [
    {
      name: "No.",
    },
    {
      name: "Title",
    },
    {
      name: "Category",
    },
    {
      name: "Sub Category",
    },
    {
      name: "Rules & Regulations",
    },
    {
      name: "Start Date",
    },
    {
      name: "Start Time",
    },
    {
      name: "End Date",
    },
    {
      name: "End Time",
    },
    {
      name: "Offer Percentage",
    },
    {
      name: "Merchant Name",
    },
    {
      name: "Locations",
    },
    {
      name: "Description",
    },
    {
      name: "Image",
    },
    {
      name: "Confirmation",
    },
    // {
    //   name: "Actions",
    // },
    {
      name: "Status"
    }
  ];

  const getOffersData = async () => {
    const offersPromise = await dispatch(
      getNonDraftedOffers({ page: currentPage })
    )?.unwrap();
    if (offersPromise?.success === true) {
      setAllOffers(offersPromise?.data);
      setCurrentPage(offersPromise?.currentPage);
      setTotalPages(offersPromise?.totalPages);
      setCount(offersPromise?.count);
    }
  };

  useEffect(() => {
    getOffersData();
  }, [deleteSuccess, currentPage]);

  useEffect(() => {
    if (recallOffers == true) {
      getOffersData();
    }
  }, [recallOffers]);

  useEffect(() => {
    if (offerRejected == true) {
      findNewClickedOffer(allOffers, clickedOffer);
      setOfferRejected(false);
    }
  }, [allOffers]);

  useEffect(() => {
    checkPendingStatuses(selectedOfferRejected);
  }, [selectedOfferRejected]);

  const findNewClickedOffer = (offers, clickedOffer) => {
    const newClickedOffer = offers?.find((o) => o?._id === clickedOffer?._id);
    setSelectedOfferRejected(newClickedOffer);
  };

  const checkPendingStatuses = (offer) => {
    const pendingSub = offer?.subCategory?.some(
      (sub) => sub?.status === "pending"
    );
    const pendingRule = offer?.ruleAndRegulation?.some(
      (rule) => rule?.status === "pending"
    );
    if (pendingSub) {
      setIsSubCategoryOpen(true);
    } else if (pendingRule) {
      setIsRulesOpen(true);
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleImageIconClick = (offer) => {
    setClickedOffer(offer);
    setIsImageOpen(true);
  };

  const handleCloseImage = () => {
    setIsImageOpen(false);
    setClickedOffer(null);
  };

  const handleLocationClick = (offer) => {
    setClickedOffer(offer);
    setIsLocationOpen(true);
  };

  const handleDescriptionClick = (offer) => {
    setClickedOffer(offer);
    setIsDescriptionOpen(true);
  };

  const handleSubCategoryClick = (offer) => {
    setClickedOffer(offer);
    setIsSubCategoryOpen(true);
  };

  const handleRulesClick = (offer) => {
    setClickedOffer(offer);
    setIsRulesOpen(true);
  };

  const handleCloseLocation = () => {
    setIsLocationOpen(false);
    setClickedOffer(null);
  };

  const handleCloseDescription = () => {
    setIsDescriptionOpen(false);
    setClickedOffer(null);
  };

  const handleCloseSubCategory = () => {
    setIsSubCategoryOpen(false);
    setClickedOffer(null);
  };

  const handleCloseRules = () => {
    setIsRulesOpen(false);
    setClickedOffer(null);
  };

  const handleDeleteClick = (offer) => {
    setDeleteClicked(true);
    setClickedOffer(offer);
  };

  const handleKeepOffer = () => {
    setDeleteClicked(false);
  };

  const handleDeleteOffer = async () => {
    setDeleteClicked(false);
    if (clickedOffer?._id !== null) {
      const deletePromise = await dispatch(
        deleteOffer({ id: clickedOffer?._id })
      )?.unwrap();
      if (deletePromise?.success === true) {
        setDeleteSuccess(!deleteSuccess);
        setClickedOffer(null);
      }
    }
  };

  const handleCloseIrreversivePopup = () => {
    setIsApprovalSubmitted(false);
  };

  const handleApproveClick = async (offer) => {
    setClickedOffer(offer);
    const pendingSub = offer?.subCategory?.some(
      (sub) => sub?.status === "pending"
    );
    const pendingRule = offer?.ruleAndRegulation?.some(
      (rule) => rule?.status === "pending"
    );
    if (pendingSub || pendingRule) {
      setPendingSuggestionsOpen(true);
    } else {
      setPendingSuggestionsOpen(false);
    }

    if (!pendingSub && !pendingRule) {
      const payload = {
        type: "offer",
        status: "approved",
        ids: [offer?._id],
        merchantId: offer?.merchant?._id,
        reason: "",
      };
      const statusPromise = await dispatch(
        approveOrRejectResources(payload)
      )?.unwrap();
      if (statusPromise?.success === true) {
        setClickedOffer(null);
        getOffersData();
      }
    }
  };

  const handleOfferRejection = async () => {
    setIsRejectClicked(false);
    const payload = {
      type: "offer",
      status: "rejected",
      ids: [clickedOffer?._id],
      merchantId: clickedOffer?.merchant?._id,
      reason: reason,
    };
    const offerStatusPromise = await dispatch(
      approveOrRejectResources(payload)
    )?.unwrap();
    if (offerStatusPromise?.success === true) {
      setOfferRejected(true);
      getOffersData();
    }
  };

  const handleIrreversiveContinue = async () => {
    let approve,
      reject = false;
    if (selectedSubCategoriesIds?.length > 0) {
      approve = true;
      if (isSubCategoryOpen == true) {
        setIsApprovalSubmitted(false);
        setIsSubCategoryOpen(false);
        const payload = {
          type: "subCategory",
          status: "approved",
          ids: selectedSubCategoriesIds,
          merchantId: clickedOffer?.merchant?._id,
          reason: "",
        };
        const statusPromise = await dispatch(
          approveOrRejectResources(payload)
        )?.unwrap();
        if (statusPromise?.success === true) {
          setSelectedSubCategoriesIds([]);
          getOffersData();
        }
      } else if (isRulesOpen == true) {
        setIsApprovalSubmitted(false);
        setIsRulesOpen(false);
        const payload = {
          type: "rulesAndRegulations",
          status: "approved",
          ids: selectedSubCategoriesIds,
          merchantId: clickedOffer?.merchant?._id,
          reason: "",
        };
        const statusPromise = await dispatch(
          approveOrRejectResources(payload)
        )?.unwrap();
        if (statusPromise?.success === true) {
          setSelectedSubCategoriesIds([]);
          getOffersData();
        }
      }
    }

    if (nonSelectedSubCatIds?.length > 0) {
      reject = true;
      if (isSubCategoryOpen == true) {
        setIsApprovalSubmitted(false);
        setIsSubCategoryOpen(false);
        const payload = {
          type: "subCategory",
          status: "rejected",
          ids: nonSelectedSubCatIds,
          merchantId: clickedOffer?.merchant?._id,
          reason: reason,
        };
        const statusPromise = await dispatch(
          approveOrRejectResources(payload)
        )?.unwrap();
        if (statusPromise?.success === true) {
          setNonSelectedSubCatIds([]);
          if (selectedOfferRejected?.status !== "rejected") {
            handleOfferRejection();
          } else if (clickedOffer?.status !== "rejected") {
            handleOfferRejection();
          } else {
            getOffersData();
          }
        }
      } else if (isRulesOpen == true) {
        setIsApprovalSubmitted(false);
        setIsRulesOpen(false);
        const payload = {
          type: "rulesAndRegulations",
          status: "rejected",
          ids: nonSelectedSubCatIds,
          merchantId: clickedOffer?.merchant?._id,
          reason: reason,
        };
        const statusPromise = await dispatch(
          approveOrRejectResources(payload)
        )?.unwrap();
        if (statusPromise?.success === true) {
          setNonSelectedSubCatIds([]);
          if (selectedOfferRejected?.status !== "rejected") {
            handleOfferRejection();
          } else if (clickedOffer?.status !== "rejected") {
            handleOfferRejection();
          } else {
            getOffersData();
          }
        }
      }
    }
    if (approve == true || reject == true) {
      getOffersData();
    }
  };

  const handleContinueWithPendingSuggestions = () => {
    const pendingSub = clickedOffer?.subCategory?.filter(
      (s) => s?.status == "pending"
    );
    const pendingRule = clickedOffer?.ruleAndRegulation?.filter(
      (s) => s?.status == "pending"
    );
    if (pendingSub?.length > 0) {
      setIsSubCategoryOpen(true);
    } else if (pendingRule?.length > 0) {
      setIsRulesOpen(true);
    } else if (pendingSub?.length > 0 && pendingRule?.length > 0) {
      setIsSubCategoryOpen(true);
      setIsRulesOpen(true);
    }
    setPendingSuggestionsOpen(false);
  };

  const handleRejectClick = (offer) => {
    setClickedOffer(offer);
    setIsRejectClicked(true);
  };

  const handleCloseReject = () => {
    setClickedOffer(null);
    setIsRejectClicked(false);
  };

  const handleCloseInfo = () => {
    setInfoClicked(false);
    setClickedOffer(null);
  };

  const handleInfoClick = (offer) => {
    setClickedOffer(offer);
    setInfoClicked(true);
  };

  const handleToggle = async (id) => {
    try {
      const togglePromise = await dispatch(toggleOfferStatus({ id: id })).unwrap();
      if(togglePromise?.success === true) {
        getOffersData();
      }
    } catch (error) {
      console.error("Error toggling location status:", error);
    }
  };

  return (
    <>
      {loading === true && <Loader />}
      {clickedOffer?.thumbnailImage !== null && isImageOpen === true && (
        <ImagePopup
          image={clickedOffer?.thumbnailImage}
          handleCloseImage={handleCloseImage}
        />
      )}
      {isLocationOpen === true && (
        <LocationPopup
          locations={clickedOffer?.tempLocations}
          handleCloseLocation={handleCloseLocation}
        />
      )}
      {clickedOffer?.description !== "" && isDescriptionOpen === true && (
        <DetailsPopup
          content={clickedOffer?.description}
          handleClose={handleCloseDescription}
          icon={descriptionIcon}
          popupClassName={"w-[335px] sm:w-[470px] pb-5"}
        />
      )}
      {clickedOffer?.subCategory?.length > 0 && isSubCategoryOpen === true && (
        <SubCategoryPopup
          subCategories={clickedOffer?.subCategory}
          handleCloseSubCategory={handleCloseSubCategory}
          icon={<SubCategoryIcon className="w-[10%]" />}
          heading="Subcategories"
          setIsApprovalSubmitted={setIsApprovalSubmitted}
          selectedSubCategoriesIds={selectedSubCategoriesIds}
          setSelectedSubCategoriesIds={setSelectedSubCategoriesIds}
          setNonSelectedSubCatIds={setNonSelectedSubCatIds}
          setReason={setReason}
        />
      )}
      {clickedOffer?.ruleAndRegulation?.length > 0 && isRulesOpen === true && (
        <SubCategoryPopup
          subCategories={clickedOffer?.ruleAndRegulation}
          handleCloseSubCategory={handleCloseRules}
          icon={<RulesIcon className="w-[10%]" />}
          heading="Rules and Regulations"
          setIsApprovalSubmitted={setIsApprovalSubmitted}
          selectedSubCategoriesIds={selectedSubCategoriesIds}
          setSelectedSubCategoriesIds={setSelectedSubCategoriesIds}
          setNonSelectedSubCatIds={setNonSelectedSubCatIds}
          setReason={setReason}
        />
      )}
      {isApprovalSubmitted === true && (
        <GenericPopup
          icon={crossIcon}
          content={`It’s an irreversible action, the information you selected can't be changed. ${
            selectedOfferRejected?.status !== "rejected" ||
            clickedOffer?.status !== "rejected"
              ? "Due to any unselected suggestion, your offer will be rejected."
              : ""
          } Are you sure you want to submit it?`}
          popupClassName={"w-[280px] sm:w-[400px] pb-4"}
          continueBtn={
            <PrimaryButton
              className={
                "sm:w-[30%] md:w-[35%] lg:w-[35%] font-poppins sm:text-[12px] md:text-[14px] text-center font-[500] sm:h-[35px] md:h-[40px] rounded-[10px] mt-5"
              }
              label="Continue"
              onClick={handleIrreversiveContinue}
            />
          }
          cancelBtn={
            <PrimaryOutlineButton
              label={"Cancel"}
              className={
                "sm:w-[30%] md:w-[35%] lg:w-[35%] font-poppins sm:text-[12px] md:text-[14px] text-center font-[500] sm:h-[35px] md:h-[40px] rounded-[10px] mt-5"
              }
              onClick={handleCloseIrreversivePopup}
            />
          }
        />
      )}
      {pendingSuggestionsOpen === true && (
        <GenericPopup
          icon={cautionIcon}
          content={
            "There are pending suggestions regarding rules and regulations, as well as on the sub-categories list."
          }
          popupClassName={"w-[335px] sm:w-[470px] pb-8"}
          continueBtn={
            <PrimaryButton
              className={
                "sm:w-[30%] md:w-[35%] lg:w-[35%] font-poppins sm:text-[12px] md:text-[14px] text-center font-[500] sm:h-[35px] md:h-[40px] rounded-[10px] mt-5"
              }
              label="Continue"
              onClick={handleContinueWithPendingSuggestions}
            />
          }
          cancelBtn={
            <PrimaryOutlineButton
              label={"Cancel"}
              className={
                "sm:w-[30%] md:w-[35%] lg:w-[35%] font-poppins sm:text-[12px] md:text-[14px] text-center font-[500] sm:h-[35px] md:h-[40px] rounded-[10px] mt-5"
              }
              onClick={() => setPendingSuggestionsOpen(false)}
            />
          }
        />
      )}
      {isRejectClicked === true && (
        <RejectOfferPopup
          icon={crossIcon}
          content={
            "Could you please provide the reason for the rejection of this offer?"
          }
          popupClassName={"w-[400px] sm:w-[500px] pb-4"}
          handleClose={handleCloseReject}
          setReason={setReason}
          handleOfferRejection={handleOfferRejection}
        />
      )}
      {infoClicked === true && (
        <DetailsPopup
          content={clickedOffer?.reason}
          handleClose={handleCloseInfo}
          icon={infoIcon}
          popupClassName={"w-[335px] sm:w-[470px] pb-5"}
        />
      )}
      <div className="flex justify-between col-span-12">
        <BreadCrumsHeader heading="Offers List" items={items} />
      </div>
      {deleteClicked == true ? (
        <CancelPopup
          handleNo={handleKeepOffer}
          handleYes={handleDeleteOffer}
          heading={"Delete Offer"}
          content={"Are you sure you want to delete this offer?"}
          image={deleteImg}
        />
      ) : null}
      <div className=" col-span-12 mt-8">
        <TableContainer style={{ width: "100%" }}>
          <Table
            sx={{
              minWidth: "1936px",
              borderCollapse: "separate",
              borderSpacing: "0px 8px",
            }}
            aria-label="simple table"
          >
            <TableHead className="bg-[#00ADEF]">
              <TableRow
                className="h-[50px]"
                sx={{ boxShadow: "0px 4px 4px 0px #00000040" }}
              >
                {columns.map((column) => {
                  return <TableCell>{column.name}</TableCell>;
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {allOffers?.map((offer, index) => {
                return (
                  <TableRow
                    className="h-[50px]"
                    sx={{
                      boxShadow: "0px 4px 4px 0px #00000040",
                      backgroundColor:
                        (index + 1) % 2 !== 0 ? "#F5F5F5" : "#FFFFFF",
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{offer?.title}</TableCell>
                    <TableCell>{offer?.category?.title}</TableCell>
                    {/* <TableCell>{offer?.subCategory[0]?.title}</TableCell> */}
                    <TableCell>
                      {offer?.subCategory?.length > 0 ? (
                        <div className="w-[80%] flex justify-center cursor-pointer">
                          {offer?.subCategory?.some(
                            (sub) =>
                              sub?.status === "pending" ||
                              sub?.status === "rejected"
                          ) ? (
                            <UnapprovedSubCatIcon
                              className="w-[30px] h-[30px]"
                              onClick={() => handleSubCategoryClick(offer)}
                            />
                          ) : (
                            <ApprovedSubCategoryIcon
                              className="w-[30px] h-[30px]"
                              onClick={() => handleSubCategoryClick(offer)}
                            />
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell>
                      {offer?.ruleAndRegulation?.length > 0 ? (
                        <div className="w-[80%] flex justify-center cursor-pointer">
                          {offer?.ruleAndRegulation?.some(
                            (sub) =>
                              sub?.status === "pending" ||
                              sub?.status === "rejected"
                          ) ? (
                            <UnapprovedRulesIcon
                              className="w-[30px] h-[30px]"
                              onClick={() => handleRulesClick(offer)}
                            />
                          ) : (
                            <ApprovedRulesIcon
                              className="w-[30px] h-[30px]"
                              onClick={() => handleRulesClick(offer)}
                            />
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell>
                      {
                        separateDateTimeAndConvertToLocale(offer?.startDate)
                          ?.localDate
                      }
                    </TableCell>
                    <TableCell>{offer?.tradingHours[0]?.startTime}</TableCell>
                    <TableCell>
                      {
                        separateDateTimeAndConvertToLocale(offer?.endDate)
                          ?.localDate
                      }
                    </TableCell>
                    <TableCell>{offer?.tradingHours[0]?.endTime}</TableCell>
                    <TableCell>{`${offer?.discountRange}%`}</TableCell>
                    <TableCell>{offer?.merchant?.businessName}</TableCell>
                    <TableCell>
                      <div className="w-[80%] flex justify-center cursor-pointer">
                        <LocationIcon
                          onClick={() => handleLocationClick(offer)}
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="w-[80%] flex justify-center cursor-pointer">
                        <img
                          src={descriptionIcon}
                          className="w-[30px] h-[30px] cursor-pointer"
                          onClick={() => handleDescriptionClick(offer)}
                        />
                      </div>
                    </TableCell>
                    <TableCell>
                      {offer?.thumbnailImage !== undefined ? (
                        <img
                          src={offer?.thumbnailImage}
                          className="h-[24px] w-[24px] cursor-pointer border border-[#00ADEF] rounded"
                          onClick={() => handleImageIconClick(offer)}
                        />
                      ) : (
                        <Image
                          className="cursor-pointer"
                          onClick={() => handleImageIconClick(offer)}
                        />
                      )}
                    </TableCell>
                    <TableCell>
                      <Confirmation
                        status={offer?.status}
                        handleApproveClick={() => handleApproveClick(offer)}
                        handleRejectionClick={() => handleRejectClick(offer)}
                        handleInfoClick={() => handleInfoClick(offer)}
                      />
                    </TableCell>
                    <TableCell>
                      <Status
                        isEnabled={offer?.isEnabled}
                        handleToggle={() => handleToggle(offer?._id)}
                      />
                    </TableCell>
                    {/* <TableCell>
                      <div className="flex justify-start gap-[4%]">
                        <Delete onClick={() => handleDeleteClick(offer)} />
                      </div>
                    </TableCell> */}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          totalPages={totalPages}
          handlePageChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default AdminOfferlist;
