import Swoodle from "../../../Assets/Images/Onboard Swoodle Logo.svg";
import Profile from "../../../Assets/Images/Profile.svg";
import emailIcon from "../../../Assets/Icons/emailIcon.svg";
import TextInput from "../SignUp/TextInput";
import { useNavigate } from "react-router-dom";
import GlassEffect from "../../../Assets/Images/blueGlass.svg";
import { useFormik } from "formik";
import { forgetPasswordSchema } from "../../../utils/validationSchemas";
import { useDispatch, useSelector } from "react-redux";
import { setForgetPasswordData } from "../../../redux/slices/authSlice";
import { forgetPassword } from "../../../services/authService";
import PrimaryButton from "../PrimaryButton";

const ForgetPasswordForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const forgetData = useSelector((state) => state?.forgetPassword?.data);

  const navigation = () => {
    navigate("/otp-verification");
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: forgetPasswordSchema,
    onSubmit: async (values) => {
      const forgetPromise = await dispatch(
        forgetPassword({ email: email })
      )?.unwrap();
      if (forgetPromise?.success === true) {
        navigation();
        dispatch(setForgetPasswordData({ email: email }));
      }
      // Handle form submission
      console.log(values);
    },
  });

  const { email } = formik.values;

  const { handleChange, handleBlur, handleSubmit, errors, touched } = formik;

  return (
    <form
      className="ForgetPassSection bg-[#00ADEF80] py-16 h-[100vh]"
      onSubmit={handleSubmit}
    >
      <div className="flex flex-col items-center">
        <div className="pb-[5%]">
          <img src={Swoodle}></img>
        </div>

        <div className="relative sm:w-[380px] md:w-[415px] lg:w-[415px] xl:w-[415px] sm:mt-14 md:mt-12 lg:mt-8 2xl:mt-3">
          <img src={GlassEffect}></img>
          <div className="w-full absolute z-[1] top-0">
            <div className="flex justify-center mt-[-12%]">
              <img src={Profile} className="w-[26%] h-[26%]"></img>
            </div>

            <div>
              <p className="sm:text-[18px] md:text-[20px] font-[600] text-center text-white font-poppins">
                Recover Account
              </p>
            </div>

            <div className="w-[90%] m-auto">
              <div className="sm:mt-10 lg:mt-[10%]">
                <p className="text-white text-[14px] font-[500] font-poppins">
                  Please enter your email address. You will receive a link to
                  create a new password via email.
                </p>
              </div>

              <div className="sm:mt-[5%] 2xl:mt-[6%]">
                <TextInput
                  Icon={emailIcon}
                  name={"email"}
                  value={email}
                  placeholder={"Email ID"}
                  inputClass={
                    "w-full rounded-tr-[10px] rounded-br-[10px] border-none"
                  }
                  iconAreaClass={
                    "w-[15%] bg-[#E6E6E6] rounded-tl-[10px] rounded-bl-[10px] border-none"
                  }
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  error={errors.email}
                  touched={touched.email}
                />
              </div>
              <PrimaryButton
                type="submit"
                className={
                  "mt-[5%] mb-[4%] w-[100%] sm:text-[16px] md:text-[18px] font-[500] h-[50px] sm:h-[45px] md:h-[50px] rounded-[10px]"
                }
                label="Recover"
              />

              <div>
                <p className="text-white text-center sm:text-[10px] md:text-[12px] font-[500] font-poppins">
                  Don’t have an account?{" "}
                  <span
                    onClick={() => navigate("/signup")}
                    className={
                      "font-[700] sm:text-[10px] md:text-[12px] ml-1 font-poppins cursor-pointer"
                    }
                  >
                    Register
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ForgetPasswordForm;
