import React, { useEffect, useState } from "react";
import "./offerform.scss";
import BreadCrumsHeader from "../BreadCrums";
import OfferFormLayout from "./OfferFormLayout";
import CategorySelection from "./CategorySelection";
import SubCategorySelection from "./SubCategorySelection";
import OfferCreation from "./OfferCreation";
import CustomizeOffer from "./CustomizeOffer";
import RulesRegulations from "./RulesRegulations";
import Location from "./Location";
import OfferReview from "./OfferReview";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  createOffer,
  getCategories,
  getOfferById,
} from "../../../services/offerService";
import { resetOfferState } from "../../../redux/slices/offerSlice";

const OfferForm = ({ merchant }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id = null } = location?.state || "";
  const [stepIndex, setStepIndex] = useState(1);
  const [subCategories, setSubCategories] = useState([]);
  const [categoryId, setCategoryId] = useState(undefined);
  const [payload, setPayload] = useState(null);
  const [categories, setCategories] = useState([]);
  const [editOfferData, setEditOfferData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const getOfferData = async () => {
    const getPromise = await dispatch(getOfferById({ id: id }))?.unwrap();
    if (getPromise?.success === true) {
      setEditOfferData(getPromise?.data);
    }
  };

  const getCategoriesData = async () => {
    const categoryPromise = await dispatch(getCategories())?.unwrap();
    if (categoryPromise?.success === true) {
      setCategories(categoryPromise?.data);
    }
  };

  useEffect(() => {
    getCategoriesData();
  }, []);

  useEffect(() => {
    if (id !== null) {
      setIsEdit(true);
      getOfferData();
    }
  }, [id]);

  const items = [
    {
      title: "Offers",
    },
    {
      title: "New Offer",
    },
  ];

  const handlePrevious = () => {
    setStepIndex(stepIndex - 1);
    setIsEdit(false);
  };

  const setPayloadData = (data) => {
    setPayload(data);
  };

  const handleSaveAndExit = async () => {
    if (payload !== null) {
      const createPromise = await dispatch(createOffer(payload))?.unwrap();
      if (createPromise?.success === true) {
        navigate("/merchant/offer-list");
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      dispatch(resetOfferState());
    };
  }, []);

  return (
    <>
      <BreadCrumsHeader heading="New Offer" items={items} form={true} />
      {stepIndex === 1 && (
        <OfferFormLayout
          title={"Offer Info"}
          content={
            <CategorySelection
              setSubCategories={setSubCategories}
              stepIndex={stepIndex}
              setStepIndex={setStepIndex}
              categoryId={categoryId}
              setCategoryId={setCategoryId}
              setPayloadData={setPayloadData}
              categories={categories}
              editOfferData={editOfferData}
              isEdit={isEdit}
            />
          }
          stepIndex={stepIndex}
          handleSaveAndExit={handleSaveAndExit}
        />
      )}
      {stepIndex === 2 && (
        <OfferFormLayout
          title={"Offer Info"}
          content={
            <SubCategorySelection
              categoryId={categoryId}
              subCategories={subCategories}
              setSubCategories={setSubCategories}
              stepIndex={stepIndex}
              setStepIndex={setStepIndex}
              handlePrevious={handlePrevious}
              setPayloadData={setPayloadData}
              editOfferData={editOfferData}
              isEdit={isEdit}
            />
          }
          stepIndex={stepIndex}
          handleSaveAndExit={handleSaveAndExit}
        />
      )}
      {stepIndex === 3 && (
        <OfferFormLayout
          title={"Offer Info"}
          content={
            <OfferCreation
              stepIndex={stepIndex}
              setStepIndex={setStepIndex}
              handlePrevious={handlePrevious}
              setPayloadData={setPayloadData}
              editOfferData={editOfferData}
              isEdit={isEdit}
            />
          }
          stepIndex={stepIndex}
          handleSaveAndExit={handleSaveAndExit}
        />
      )}
      {stepIndex === 4 && (
        <OfferFormLayout
          title={"Offer Info"}
          content={
            <CustomizeOffer
              stepIndex={stepIndex}
              setStepIndex={setStepIndex}
              handlePrevious={handlePrevious}
              setPayloadData={setPayloadData}
              editOfferData={editOfferData}
              isEdit={isEdit}
            />
          }
          stepIndex={stepIndex}
          handleSaveAndExit={handleSaveAndExit}
        />
      )}
      {stepIndex === 5 && (
        <OfferFormLayout
          title={"Offer Info"}
          content={
            <RulesRegulations
              stepIndex={stepIndex}
              setStepIndex={setStepIndex}
              handlePrevious={handlePrevious}
              setPayloadData={setPayloadData}
              editOfferData={editOfferData}
              isEdit={isEdit}
            />
          }
          stepIndex={stepIndex}
          handleSaveAndExit={handleSaveAndExit}
        />
      )}
      {stepIndex === 6 && (
        <OfferFormLayout
          title={"Offer Info"}
          content={
            <Location
              stepIndex={stepIndex}
              setStepIndex={setStepIndex}
              handlePrevious={handlePrevious}
              setPayloadData={setPayloadData}
              editOfferData={editOfferData}
              isEdit={isEdit}
            />
          }
          stepIndex={stepIndex}
          handleSaveAndExit={handleSaveAndExit}
        />
      )}
      {stepIndex === 7 && (
        <OfferFormLayout
          title={"Offer Info"}
          content={
            <OfferReview
              handlePrevious={handlePrevious}
              editOfferData={editOfferData}
              setPayloadData={setPayloadData}
            />
          }
          stepIndex={stepIndex}
          handleSaveAndExit={handleSaveAndExit}
        />
      )}
    </>
  );
};

export default OfferForm;
