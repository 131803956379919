import React, {useState} from 'react'

const InputTextArea = ({
  label,
  width,
  placeholder,
  name,
  value,
  setFieldValue,
  handleBlur,
  error,
  touched
}) => {
  const maxCharacters = 250;

  const handleTextChange = (event) => {
    const newText = event.target.value;
    if (newText.length <= maxCharacters) {
      setFieldValue(name, newText);
    }
  };

  return (
    <div className='relative my-5' style={{width: width}}>
        <p className='lg:text-[16px] sm:text-[14px] font-[400] text-[#303030] mb-2 lg:leading-[27px] sm:leading-[21px] font-poppins' >{label}</p>
        <textarea
          className="w-full h-[155px] ps-4 pt-2 rounded-[8px] bg-[#FFFFFF] text-[#303030] lg:text-[14px] sm:text-[12px] font-[400] outline-none resize-none font-poppins"
          style={{boxShadow: '0px 4px 4px 0px #0000000F'}}
          placeholder={placeholder}
          maxLength={maxCharacters}
          name={name}
          value={value}
          onChange={handleTextChange}
          onBlur={handleBlur}
        ></textarea>
        <div className="absolute bottom-4 right-3 text-[#D9D9D9] text-[10px] font-poppins">
          {value?.length}/{maxCharacters}
        </div>
        {error && touched ? (
        <span className="text-red-600 sm:text-[10px] md:text-[12px] font-[600] ms-1 mt-1">{error}</span>
        ) : null}
    </div>
  )
}

export default InputTextArea;