import React, { useState } from "react";
import "./dashboard.scss";
import { Progress } from 'antd';

const MerchantOffer = () => {
  const [progresses, setProgresses] = useState([
    {
      name: "McDonald’s",
      progress: 65
    },
    {
      name: "Grilled",
      progress: 40
    },
    {
      name: "Noodle Box",
      progress: 75
    },
    {
      name: "Village Cinemas",
      progress: 25
    },
    {
      name: "iFLY",
      progress: 20
    },
    {
      name: "NBL",
      progress: 60
    },
    {
      name: "Luna Park",
      progress: 70
    },
    {
      name: "Boost",
      progress: 40
    },
    {
      name: "Hunky Dory",
      progress: 90
    }
  ])
  return (
    <div className="summary w-full h-full p-4 offersProgress">
      <span className="text-[20px] font-poppins font-[600] text-[black] whitespace-nowrap">
        Merchant’s Offer
      </span>
      <div className="flex flex-col mt-[18%]">
        {progresses?.map((p) => 
          <div className="flex justify-between items-center mt-1">
            <span className="text-[14px] font-poppins font-[400] text-[black] sm:w-[40%] md:w-[50%] 2xl:w-[30%]">{p?.name}</span>
            <Progress percent={p?.progress} />
          </div>
        )}
      </div>
    </div>
  );
};

export default MerchantOffer;
