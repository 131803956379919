import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import FormBg from "../../../Assets/Images/Fundraiser/membershipActivation.svg";
import Profile from "../../../Assets/Images/Profile.svg";
import passwordIcon from "../../../Assets/Icons/passwordIcon.svg";
import editIcon from "../../../Assets/Icons/editIcon.svg";
import TextInput from "../../common/SignUp/TextInput";
import FundraiserHeader from "../common/FundraiserHeader";
import FundraiserBodyHeader from "../common/FundraiserBodyHeader";
import PrimaryButton from "../../common/PrimaryButton";
import { useFormik } from "formik";
import { existingLoginSchema } from "../../../utils/validationSchemas";
import { useDispatch, useSelector } from "react-redux";
import { setLoginData } from "../../../redux/slices/authSlice";
import { login } from "../../../services/authService";
import { getUserSubscription } from "../../../services/subscriptionService";
import Loader from "../../common/Loader";
import { getFcmToken } from "../../../utils/connectStore";

const ExistingLogin = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { email } = location?.state;
  const subLoading = useSelector((state) => state.subscriptionReducer.loading);
  const authLoading = useSelector((state) => state.authReducer.loading);

  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  const navigation = (subscriptionData) => {
    if (subscriptionData?.length > 0) {
      navigate("/existing-subscription");
    } else {
      navigate("/fundraiser-subscription");
    }
  };

  const handleEditClicked = () => {
    navigate("/membership-activation");
  };

  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: existingLoginSchema,
    onSubmit: async (values) => {
      console.log("values", values);
      try {
        const promiseResult = await dispatch(
          login({
            email: email,
            password: password,
            fcmToken: getFcmToken(),
          })
        ).unwrap();
        if (promiseResult?.success === true) {
          const result = await dispatch(getUserSubscription()).unwrap();
          if (result) {
            navigation(result?.data);
            dispatch(
              setLoginData({
                email: email,
                password: password,
                fcmToken: getFcmToken(),
              })
            );
          }
        }
      } catch (error) {
        throw error;
      }
    },
  });

  const { password } = formik.values;

  const { handleChange, handleBlur, handleSubmit, errors, touched } = formik;

  return (
    <form className="pb-24" onSubmit={handleSubmit}>
      <FundraiserHeader />

      {subLoading || authLoading ? <Loader /> : null}
      <div className="flex flex-col items-center">
        <FundraiserBodyHeader
          heading={"Login"}
          content={"Please enter your Password"}
          contentClass={"w-[100%] text-[#8C8C8C]"}
        />

        {/* {submitted == true ? (
          <>
            {passwordMatched == true ? (
              <div className="bg-[#D1E7DD] rounded-[10px] w-[85%] sm:w-[30%] h-[65px] mt-8 mb-6 sm:mb-0 flex items-center justify-center">
                <p className="text-[16px] font-[400] font-poppins text-[#14985C]">
                  Login Successful!
                </p>
              </div>
            ) : (
              <div className="bg-[#F8D7DA] rounded-[10px] w-[85%] sm:w-[30%] h-[65px] mt-8 mb-6 sm:mb-0 flex items-center justify-center">
                <p className="text-[16px] font-[400] font-poppins text-[#FF4152]">
                  Incorrect username or password
                </p>
              </div>
            )}
          </>
        ) : null} */}
        <div className="relative mt-12 sm:mt-24 mb-14">
          <img src={FormBg} className="w-[340px] sm:w-[390px]"></img>

          <div className="absolute top-2 sm:top-0 w-full mt-[-14%]">
            <div className="flex justify-center">
              <img src={Profile} className="w-[80px] sm:w-[104px]"></img>
            </div>

            <div className="px-[6%] sm:px-[8%] mt-8 sm:mt-10">
              <div className="flex justify-between items-center mr-2 ml-4 sm:ml-6 mb-5 sm:mb-9">
                <p className="text-[16px] font-[400] font-poppins">{email}</p>
                <img
                  src={editIcon}
                  className="cursor-pointer w-[20px]"
                  onClick={handleEditClicked}
                />
              </div>
              <TextInput
                Icon={passwordIcon}
                type={showPassword ? "text" : "password"}
                name={"password"}
                value={password}
                placeholder={"Enter Password "}
                inputClass={
                  "w-full rounded-tr-[10px] rounded-br-[10px] border-none"
                }
                iconAreaClass={
                  "w-[18%] bg-[#E1E1E1] rounded-tl-[10px] rounded-bl-[10px] border-none"
                }
                inputDivClass={"mb-3 sm:mb-4"}
                handleChange={handleChange}
                handleBlur={handleBlur}
                error={errors.password}
                touched={touched.password}
                showPassword={showPassword}
                togglePassword={togglePassword}
              />
              <div
                className="sm:text-[10px] md:text-[12px] font-[400] text-[#00ADEF] font-poppins text-end cursor-pointer"
                onClick={() => navigate("/user-forgetPassword")}
              >
                Forgot Password
              </div>

              <PrimaryButton
                type="submit"
                className={
                  "mt-3 sm:mt-7 w-[100%] text-[18px] font-[600] h-[50px] sm:h-[45px] md:h-[50px] rounded-[10px]"
                }
                label="Continue"
              />
              <div className="mt-3 text-[16px] font-[500] font-poppins text-[#00ADEF] text-center underline underline-offset-2">
                Need help
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ExistingLogin;
