import React, { useState, useRef, useEffect } from "react";
import "./addassets.scss";
import { DatePicker, TimePicker } from "antd";

import BreadCrumsHeader from "../../common/BreadCrums";
import Input from "../../common/FormInputs";
import FormLayout from "../../common/FormLayout";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";

import { useFormik } from "formik";
import { assetsSchema } from "../../../utils/validationSchemas";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { combineDateTimeAndConvertToUTC } from "../../../utils/utilFunctions";
import { createAsset, editAsset } from "../../../services/assetsService";
import InputTextArea from "../../common/InputTextArea";
import Loader from "../../common/Loader";
import { ReactComponent as DownArrow } from "../../../Assets/Icons/greyDownArrow.svg";
import Selector from "../../common/Selector";
import { getFundraisers } from "../../../services/fundraiserService";
import UploadExcel from "../../common/OfferForm/Location/UploadExcel";

const AddAssetsForm = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const loading = useSelector((state) => state?.assetsReducer?.loading);
  const allAssets = useSelector((state) => state.assetsReducer.allPromotions);
  const { id = null } = location?.state || "";
  const navigate = useNavigate();
  const [fileData, setFileData] = useState();
  const [fundraisers, setFundraisers] = useState([]);
  const [fundraiserIds, setFundraiserIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const fileInputRef = useRef(null);
  dayjs.extend(utc);
  dayjs.extend(tz);

  const timeZone = dayjs.tz.guess();

  const getFundarisersData = async () => {
    try {
      const result = await dispatch(getFundraisers()).unwrap();
      setFundraisers(result?.data);
    } catch (error) {
      console.error("Error fetching fundraisers", error);
      throw error;
    }
  };

  useEffect(() => {
    getFundarisersData();
  }, []);

  const initialValues = {
    title: "",
    assetDescription: "",
    selectedFundraisers: [],
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    document: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: assetsSchema,

    onSubmit: async (values) => {
      console.log("Offer values: ", values);
      const startDateTimeUTC = combineDateTimeAndConvertToUTC(
        values?.startDate,
        values?.startTime
      );
      const endDateTimeUTC = combineDateTimeAndConvertToUTC(
        values?.endDate,
        values?.endTime
      );
      if (id !== "" && id !== null) {
        // const formData = new FormData();
        // formData.append("id", id);
        // formData.append("title", values.title);
        // formData.append("description", values.assetDescription);
        // formData.append("startDateTime", startDateTimeUTC);
        // formData.append("endDateTime", endDateTimeUTC);
        // formData.append("file", fileUrl);
        // fundraiserIds.forEach((fundraiserId, index) => {
        //   formData.append("fundraisers", fundraiserId);
        // });

        const payload = {
          id: id,
          title: values?.title,
          description: values?.assetDescription,
          startDateTime: startDateTimeUTC,
          endDateTime: endDateTimeUTC,
          file: fileUrl,
          fundraisers: fundraiserIds
        }

        const editAssetPromise = await dispatch(editAsset(payload))?.unwrap();
        if (editAssetPromise?.success === true) {
          navigate("/promotions-list");
        }
      } else {
        // const formData = new FormData();
        // formData.append("title", values.title);
        // formData.append("description", values.assetDescription);
        // formData.append("startDateTime", startDateTimeUTC);
        // formData.append("endDateTime", endDateTimeUTC);
        // formData.append("file", fileUrl);
        // fundraiserIds.forEach((fundraiserId, index) => {
        //   formData.append("fundraiser", fundraiserId);
        // });

        const payload = {
          title: values?.title,
          description: values?.assetDescription,
          startDateTime: startDateTimeUTC,
          endDateTime: endDateTimeUTC,
          file: fileUrl,
          fundraiser: fundraiserIds
        }

        const createAssetPromise = await dispatch(
          createAsset(payload)
        )?.unwrap();
        if (createAssetPromise?.success === true) {
          navigate("/promotions-list");
        }
      }
    },
  });

  const {
    title,
    assetDescription,
    selectedFundraisers,
    startDate,
    startTime,
    endDate,
    endTime,
    document,
  } = formik.values;

  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    setValues,
  } = formik;

  const items = [
    {
      title: "Assets and  Promotions",
    },
    {
      title: "Add Assets and Promotions",
    },
  ];

  useEffect(() => {
    if (id !== "" && id !== null && allAssets !== null) {
      allAssets?.map((asset) => {
        if (asset?._id === id) {
          const selectIds = asset?.fundraisers?.map((f) => f?._id);
          setFundraiserIds(selectIds);
          setValues({
            title: asset?.title,
            assetDescription: asset?.description,
            startDate: dayjs.utc(asset?.startDateTime).tz(timeZone),
            startTime: dayjs.utc(asset?.startDateTime).tz(timeZone),
            endDate: dayjs.utc(asset?.endDateTime).tz(timeZone),
            endTime: dayjs.utc(asset?.endDateTime).tz(timeZone),
            document: asset?.file,
            selectedFundraisers: asset?.fundraisers?.map((f) => f?.user?.name),
          });
          setFileUrl(asset?.file !== undefined ? asset?.file : "");
        }
      });
    }
  }, [id, allAssets]);

  const handleMerchantChange = (selectedOptions) => {
    if (selectedOptions) {
      if (selectedOptions.includes("Select All")) {
        setSelectAll(true);
        setFieldValue("selectedFundraisers", ["Select All"]);
        const allIds = fundraisers.map((fundraiser) => fundraiser._id);
        setFundraiserIds(allIds);
      } else {
        setSelectAll(false);
        setFieldValue("selectedFundraisers", selectedOptions);
      }
    }
  };

  useEffect(() => {
    if (!selectedFundraisers?.includes("Select All")) {
      if (selectedFundraisers?.length !== 0 && fundraisers?.length !== 0) {
        const catIds = selectedFundraisers?.map((fundraiserName) => {
          const matchingFundraisers = fundraisers?.filter(
            (cat) => cat?.user?.name === fundraiserName
          );
          return matchingFundraisers[0]?._id;
        });
        setFundraiserIds(catIds?.flat());
      }
    }
  }, [selectedFundraisers, fundraisers]);

  const uniqueNames = new Set();

  const fundraiserOptions = [
    { value: "Select All", label: "Select All" },
    ...fundraisers
      ?.map((op) => {
        const name = op?.user?.name ?? "abc";
        if (!uniqueNames.has(name)) {
          uniqueNames.add(name);
          return {
            value: name,
            label: name,
            disabled: selectAll,
          };
        }
        return null;
      })
      .filter((option) => option !== null),
  ];

  const validateFile = (data) => {
    var idxDot = data.lastIndexOf(".") + 1;
    var extFile = data.substr(idxDot, data.length).toLowerCase();
    if (extFile == "zip" || extFile == "rar") {
      return true;
    } else {
      return false;
    }
  };

  const OfferInfoContent = (
    <div className="assetform">
      <Input
        type="text"
        label="Title"
        placeholder=""
        width="100%"
        radius="5px"
        InputDivClass="w-[100%] mt-8"
        name="title"
        value={title}
        handleChange={handleChange}
        handleBlur={handleBlur}
        error={errors.title}
        touched={touched.title}
      />
      <InputTextArea
        label={"Asset Description"}
        divClassName="relative mt-5 w-full"
        labelClassName="invitationLabels"
        textareaClassName="w-full h-[150px] px-[1%] pt-2 rounded-[5px] bg-[#FFFFFF] border border-[#D9D9D9] text-[#303030] lg:text-[14px] sm:text-[12px] font-[400] outline-none resize-none font-poppins"
        name="assetDescription"
        value={assetDescription}
        handleBlur={handleBlur}
        error={errors.assetDescription}
        touched={touched.assetDescription}
        setFieldValue={setFieldValue}
      />
      <Selector
        value={selectedFundraisers}
        defaultValue={
          id !== "" && id !== null ? selectedFundraisers : undefined
        }
        label={"Fundraisers"}
        labelClassName="lg:text-[14px] sm:text-[12px] font-[400] text-[#303030] mb-2 font-poppins lg:leading-[27px] sm:leading-[21px]"
        divClassName="mt-4 w-full multipleField"
        selectClassName="w-full rounded-[5px] bg-[#FFFFFF] text-[#303030] lg:text-[16px] sm:text-[10px] font-[400] outline-none !font-poppins !focus:outline-none"
        flexClassName="gap-1"
        options={fundraiserOptions}
        suffixIcon={<DownArrow />}
        placeholder={"Select Fundraisers"}
        onChange={handleMerchantChange}
        handleBlur={handleBlur}
        error={errors.selectedFundraisers}
        touched={touched.selectedFundraisers}
        multiple={true}
      />

      <div className="flex sm:flex-col md:flex-row justify-between mt-8 w-[100%]">
        <div className="md:w-[46%] sm:w-[100%]">
          <p className="invitationLabels">Start Date</p>
          <DatePicker
            className="invitationInputs w-[100%] outline-none"
            dateFormat="yyyy-MM-dd"
            placeholder=""
            name="startDate"
            defaultValue={startDate}
            value={startDate}
            onChange={(date, dateString) => {
              setFieldValue("startDate", date);
            }}
            onBlur={handleBlur}
          />
          <span className="text-red-600 text-[12px] font-[600] ms-1">
            {errors.startDate && touched.startDate ? errors.startDate : null}
          </span>
        </div>
        <div className="md:w-[46%] sm:w-[100%] sm:mt-10 md:mt-0">
          <p className="invitationLabels">Start Time</p>
          <TimePicker
            className="invitationInputs w-[100%]"
            placeholder=""
            format="HH:mm"
            name="startTime"
            defaultValue={startTime}
            value={startTime}
            onChange={(date, dateString) => {
              setFieldValue("startTime", date);
            }}
            onBlur={handleBlur}
          />
          <span className="text-red-600 text-[12px] font-[600] ms-1">
            {errors.startTime && touched.startTime ? errors.startTime : null}
          </span>
        </div>
      </div>

      <div className="flex sm:flex-col md:flex-row justify-between mt-8 w-[100%]">
        <div className="md:w-[46%] sm:w-[100%]">
          <p className="invitationLabels">End Date</p>
          <DatePicker
            className="invitationInputs w-[100%] outline-none"
            dateFormat="yyyy-MM-dd"
            placeholder=""
            name="endDate"
            defaultValue={endDate}
            value={endDate}
            onChange={(date, dateString) => {
              setFieldValue("endDate", date);
            }}
            onBlur={handleBlur}
          />
          <span className="text-red-600 text-[12px] font-[600] ms-1">
            {errors.endDate && touched.endDate ? errors.endDate : null}
          </span>
        </div>
        <div className="md:w-[46%] sm:w-[100%] sm:mt-10 md:mt-0">
          <p className="invitationLabels">End Time</p>
          <TimePicker
            className="invitationInputs w-[100%]"
            placeholder=""
            format="HH:mm"
            name="endTime"
            defaultValue={endTime}
            value={endTime}
            onChange={(date, dateString) => {
              setFieldValue("endTime", date);
            }}
            onBlur={handleBlur}
          />
          <span className="text-red-600 text-[12px] font-[600] ms-1">
            {errors.endTime && touched.endTime ? errors.endTime : null}
          </span>
        </div>
      </div>
    </div>
  );

  const MediaContent = (
    <>
      <div className=" my-9">
        <p className="text-[16px] font-[500] text-[#000000] text-center font-poppins">
          Upload zip or rar file
        </p>
      </div>
      <UploadExcel
        setFileData={setFileData}
        document={document}
        errors={errors}
        touched={touched}
        setFieldValue={setFieldValue}
        accept={".zip,.rar"}
        fileText={"Uplaod a zip or rar file"}
        validateFile={validateFile}
        setS3Url={setFileUrl}
      />
    </>
  );

  return (
    <div className="offerform">
      {loading === true && <Loader />}
      <BreadCrumsHeader
        heading="Add Assets and Promotions"
        items={items}
        form={true}
      />
      <FormLayout title={"Add Promotion"} content={OfferInfoContent} />
      <FormLayout title={"Add Assets"} content={MediaContent} />

      <div className="col-span-12 text-end mt-14">
        <button
          className="w-[30%] lg:w-[15%] h-[45px] rounded-[5px] bg-[#F0F0F0] text-[16px] font-[500] text-[#8C8C8C]"
          style={{ border: "1px solid #C4C4C4" }}
          onClick={() => {
            navigate("/promotions-list");
          }}
        >
          Cancel
        </button>
        <button
          type="submit"
          onClick={() => {
            handleSubmit();
          }}
          className="w-[30%] lg:w-[15%] h-[45px] rounded-[5px] bg-[#00ADEF] text-[16px] font-[500] text-[#FFFFFF] ms-2"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default AddAssetsForm;
