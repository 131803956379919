import axios from "../utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const sendInvite = createAsyncThunk(
  "merchant/invitation",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post("merchant/invitation", payload);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const getMerchantInvites = createAsyncThunk(
  "merchant/invitations",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.get(
        `merchant/invitations?page=${payload?.page}`
      );
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const startOnboarding = createAsyncThunk(
  "merchant/startProcessStatus",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post("merchant/startProcessStatus", payload);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const saveMerchantData = createAsyncThunk(
  "merchant/savePotentialMerchant",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post(
        `merchant/savePotentialMerchant/${payload?.id}`,
        payload
      );
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const getPotentialMerchant = createAsyncThunk(
  "merchant/get",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.get(`merchant/potential/${payload?.id}`);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const createPotentialMerchant = createAsyncThunk(
  "merchant/potential",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post(
        `merchant/potential/${payload?.id}`,
        payload
      );
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const merchantOnboard = createAsyncThunk(
  "merchant/onboard",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post("merchant/onboard", payload);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const sendFundraiserInvite = createAsyncThunk(
  "fundraiser/invitation",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post("fundraiser/invitation", payload);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const getReasons = createAsyncThunk(
  "reason",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.get(`reason?isApproved=${payload?.isApproved}`);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const createReason = createAsyncThunk(
  "reason/create",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post("reason", payload);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);

export const fundraiserOnboard = createAsyncThunk(
  "fundraiser/onboard",
  async (payload, thunkAPI) => {
    try {
      const response = await axios.post("fundraiser/onboard", payload);
      return response?.data;
    } catch (error) {
      return thunkAPI?.rejectWithValue(error);
    }
  }
);
