import React from "react";
import OfferCard from "./OfferCard";
import topBackground from "../../../../Assets/Images/topbgImg.svg";
import macIcon from "../../../../Assets/Icons/macIcon.svg";
import { ReactComponent as TopNav } from "../../../../Assets/Images/topNav.svg";
import { ReactComponent as BackWhiteIcon } from "../../../../Assets/Icons/backWhiteIcon.svg";
import StarRatings from "react-star-ratings";

const MerchantDetails = ({ merchantData, offerData }) => {
  return (
    <div className="h-[437px] sm:w-[210px] lg:w-[210px] 2xl:w-[210px] bg-white drop-shadow-[0_4px_4px_rgba(0,0,0,0.15)] relative overflow-y-scroll" style={{scrollbarWidth: 'none'}}>
      <img src={topBackground} className="w-full" />
      <BackWhiteIcon className="absolute top-[22px] left-[8px]" />
      <TopNav className="absolute top-[37px]" />
      <div className="p-3">
        <div className="flex justify-start items-center gap-2">
          <img src={macIcon} />
          <p className="text-[#303030] font-poppins font-[500] text-[10px] whitespace-nowrap">
            {merchantData?.location?.merchant?.user?.name}
          </p>
        </div>
        <div className="flex justify-start items-center gap-1">
          <div>
            <StarRatings
              rating={merchantData?.location?.googlePlaceId?.rating !== undefined
                ? merchantData?.location?.googlePlaceId?.rating
                : 3.0}
              starRatedColor="#FDD77D"
              starDimension="11px"
              starSpacing="0.2px"
              numberOfStars={5}
              name="rating"
            />
          </div>
          <p className="text-[#303030] font-poppins font-[500] text-[7px] mt-[1.5px]">
            {merchantData?.location?.googlePlaceId?.rating
              ? merchantData?.location?.googlePlaceId?.rating
              : "3.0"}{" "}
            (1)
          </p>
          <div className="flex justify-center items-center gap-1">
            <p className="text-[#303030] font-poppins font-[700] text-[11px] mb-1">
              .
            </p>
            <p className="text-[#303030] font-poppins font-[500] text-[7px] mt-[1.5px]">
              {merchantData?.location?.formattedDistance}
            </p>
          </div>
        </div>
        <p className="mt-2 text-[#303030] font-poppins font-[500] text-[9px] whitespace-nowrap">
          All Offers
        </p>
        <div className="grid grid-cols-2 mt-1 w-full gap-2">
          <OfferCard
            img={offerData?.thumbnailImage}
            title={offerData?.title}
            description={offerData?.description}
            discount={offerData?.discountRange}
          />
          {merchantData?.offers?.map(
            (offer, index) =>
              index > 0 &&
              index <= 3 && (
                <OfferCard
                  img={offer?.thumbnailImage}
                  title={offer?.title}
                  description={offer?.discountTitle}
                  discount={offer?.discountRange}
                />
              )
          )}
        </div>
      </div>
    </div>
  );
};

export default MerchantDetails;
