import React from 'react'

const ContentBody = ({element}) => {
  return (
    <div className='sm:w-[70%] md:w-[80%] lg:w-[80%] xl:w-[80%] bg-[#FAFAFA]'>
        {element}
    </div>
  )
}

export default ContentBody;