import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const PhoneFormInput = ({
  label,
  divClassName,
  name,
  value,
  handleBlur,
  error,
  touched,
  setFieldValue,
  inputStyle,
  containerStyle,
  buttonStyle,
  buttonClass,
  containerClass,
  inputClass,
  labelClass,
  errorClass
}) => {
  const handleInputChange = (phoneNumber) => {
    setFieldValue(name, phoneNumber);
  };
  return (
    <div className={divClassName}>
      <p className={labelClass}>{label}</p>
      <PhoneInput
        country={"au"}
        countryCodeEditable={true}
        inputStyle={inputStyle}
        containerStyle={containerStyle}
        buttonStyle={buttonStyle}
        buttonClass={buttonClass}
        containerClass={containerClass}
        inputClass={inputClass}
        value={value}
        enableAreaCodes={true}
        onBlur={handleBlur}
        inputProps={{ name: { name } }}
        onChange={handleInputChange}
      />
      {error && touched ? (
        <span className={errorClass}>
          {error}
        </span>
      ) : null}
    </div>
  );
};

export default PhoneFormInput;
