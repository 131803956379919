import React, { useEffect, useState } from "react";
import PrimaryButton from "../PrimaryButton";
import GreyButton from "../GreyButton";
import { useFormik } from "formik";
import { ReactComponent as AddIcon } from "../../../Assets/Icons/addNewIcon.svg";
import { rulesOfferSchema } from "../../../utils/validationSchemas";
import CheckboxSelection from "../CheckboxSelection";
import Input from "../FormInputs";
import Loader from "../Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  createOffer,
  createRule,
  getRules,
} from "../../../services/offerService";
import declinedIcon from "../../../Assets/Icons/declinedIcon.svg";
import pendingIcon from "../../../Assets/Icons/pendingIcon.svg";
import toast from "../../../utils/toast";

const RulesRegulations = ({
  stepIndex,
  setStepIndex,
  handlePrevious,
  setPayloadData,
  editOfferData,
  isEdit,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.offerReducer?.loading);
  const offerData = useSelector((state) => state?.offerReducer?.createdOffer);
  const [addClicked, setAddClicked] = useState(false);
  const [allRules, setAllRules] = useState([]);
  const [selectedRules, setSelectedRules] = useState([]);
  const [selectedRulesIds, setSelectedRulesIds] = useState([]);
  const [newRule, setNewRule] = useState(null);
  const [offerPendingRule, setOfferPendingRule] = useState([]);

  const fillUpForm = (data) => {
    if (data?.ruleAndRegulation) {
      const ruleObjects = [];
      const ruleIds = [];
      const ruleTitles = [];

      data?.ruleAndRegulation?.map((rule) => {
        ruleObjects.push(rule);
        ruleIds.push(rule?._id);
        ruleTitles.push(rule?.title);
        if (rule?.status === "pending" || rule?.status === "rejected") {
          setOfferPendingRule((prev) => [...prev, rule]);
        }
      });

      setSelectedRules(ruleObjects);
      setSelectedRulesIds(ruleIds);
      setFieldValue("rules", ruleTitles);
    }
  };

  useEffect(() => {
    if (editOfferData !== null && isEdit == true) {
      fillUpForm(editOfferData);
    } else {
      fillUpForm(offerData);
    }
  }, [offerData, editOfferData]);

  const getRulesData = async () => {
    const getPromise = await dispatch(getRules())?.unwrap();
    if (getPromise?.success === true) {
      const approvedRules = getPromise?.data?.filter(
        (rule) => rule?.status === "approved"
      );
      setAllRules(approvedRules);
    }
  };

  useEffect(() => {
    getRulesData();
  }, []);
  const checkOptions = allRules?.map((d) => d?.title);
  const pendingCheckOptions = offerPendingRule
    ?.filter((r) => r?.status === "pending")
    .map((r) => r?.title);
  const rejectedCheckOptions = offerPendingRule
    ?.filter((r) => r?.status === "rejected")
    .map((r) => r?.title);

  const initialValues = {
    rules: [],
  };

  const payload = {
    ruleAndRegulation: selectedRulesIds,
    id: offerData?._id,
    isCreated: false,
  };

  useEffect(() => {
    setPayloadData(payload);
  }, [selectedRulesIds, offerData?._id]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: rulesOfferSchema,

    onSubmit: async (values) => {
      console.log("create values", values);
      const hasRuleRejected = selectedRules?.some(
        (subCat) => subCat.status === "rejected"
      );
      if (hasRuleRejected) {
        toast?.error("You cannot proceed with rejected rule selected.");
        return;
      }
      const createPromise = await dispatch(createOffer(payload))?.unwrap();
      if (createPromise?.success === true) {
        setStepIndex(stepIndex + 1);
      }
    },
  });

  const { rules } = formik.values;

  const {
    handleChange,
    handleBlur,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    setValues,
  } = formik;

  const handleAddClick = () => {
    setAddClicked(true);
  };

  const handleNewRuleChange = (e) => {
    setNewRule(e.target.value);
  };

  const handleNewRuleSubmit = async () => {
    if (newRule !== "") {
      const payload = {
        title: newRule,
        offerId: offerData?._id,
      };
      const createPromise = await dispatch(createRule(payload))?.unwrap();
      if (createPromise?.success === true) {
        setOfferPendingRule((prev) => [...prev, createPromise?.data]);
        setNewRule("");
        setAddClicked(false);
        setSelectedRules((prev) => [...prev, createPromise?.data]);
        setFieldValue("rules", [...rules, createPromise?.data?.title]);
        setSelectedRulesIds((prev) => [...prev, createPromise?.data._id]);
      }
    }
  };
  const handleRuleChecked = (op) => {
    const isSelected = selectedRules.find((r) => r?.title === op);

    if (isSelected) {
      const updatedRules = selectedRules.filter((r) => r?.title !== op);
      setSelectedRules(updatedRules);
      setFieldValue(
        "rules",
        updatedRules?.map((u) => u?.title)
      );

      const updatedIds = selectedRulesIds.filter((ruleId) => {
        const rulefromAll = allRules.find((r) => r.title === op);
        const rulefromPending = offerPendingRule.find((r) => r.title === op);
        if (rulefromAll) {
          return rulefromAll && rulefromAll._id !== ruleId;
        } else if (rulefromPending) {
          return rulefromPending && rulefromPending._id !== ruleId;
        }
      });
      setSelectedRulesIds(updatedIds);
    } else {
      const rulefromAll = allRules.find((r) => r.title === op);

      if (rulefromAll) {
        setSelectedRules((prev) => [...prev, rulefromAll]);
        setFieldValue("rules", [...rules, rulefromAll?.title]);
        setSelectedRulesIds((prev) => [...prev, rulefromAll._id]);
      }

      const rulefromPending = offerPendingRule.find((r) => r.title === op);

      if (rulefromPending) {
        setSelectedRules((prev) => [...prev, rulefromPending]);
        setFieldValue("rules", [...rules, rulefromPending?.title]);
        setSelectedRulesIds((prev) => [...prev, rulefromPending._id]);
      }
    }
  };

  useEffect(() => {}, [checkOptions]);

  return (
    <div className="w-full flex flex-col items-center justify-center mt-20">
      {loading === true && <Loader />}
      <div className="sm:w-[100%] md:w-[80%] lg:w-[70%] xl:w-[55%] offerform customize">
        <p className="font-poppins font-[500] sm:text-[12px] md:text-[14px] lg:text-[16px] text-[#303030]">
          Rules and Regulations
        </p>
        <CheckboxSelection
          options={checkOptions}
          divClassName="mt-1 w-full"
          internalDivClass="flex flex-col items-start w-full"
          secondRadioClass="mt-3 w-full"
          textClass="sm:text-[10px] md:text-[12px] lg:text-[14px] font-[400] text-[#303030] ml-4 w-full"
          checkClass="flex items-start w-full"
          label={""}
          width={"100%"}
          name="rules"
          value={rules}
          error={errors.rules}
          touched={touched.rules}
          setFieldValue={setFieldValue}
          onChecked={handleRuleChecked}
          postIcon={null}
        />
        {offerPendingRule?.length > 0 && rejectedCheckOptions?.length > 0 && (
          <CheckboxSelection
            options={rejectedCheckOptions}
            divClassName="mt-1"
            internalDivClass="flex flex-col items-start"
            secondRadioClass="mt-3"
            textClass="sm:text-[10px] md:text-[12px] lg:text-[14px] font-[400] text-[#303030] ml-4"
            checkClass="flex items-start"
            label={""}
            width={"100%"}
            name="rules"
            value={rules}
            error={errors.rules}
            touched={touched.rules}
            setFieldValue={setFieldValue}
            onChecked={handleRuleChecked}
            postIcon={declinedIcon}
          />
        )}
        {offerPendingRule?.length > 0 && pendingCheckOptions?.length > 0 && (
          <CheckboxSelection
            options={pendingCheckOptions}
            divClassName="mt-1"
            internalDivClass="flex flex-col items-start"
            secondRadioClass="mt-3"
            textClass="sm:text-[10px] md:text-[12px] lg:text-[14px] font-[400] text-[#303030] ml-4"
            checkClass="flex items-start"
            label={""}
            width={"100%"}
            name="rules"
            value={rules}
            error={errors.rules}
            touched={touched.rules}
            setFieldValue={setFieldValue}
            onChecked={handleRuleChecked}
            postIcon={pendingIcon}
          />
        )}
        {addClicked && (
          <>
            <Input
              type="text"
              placeholder="Add Suggestion"
              width="100%"
              radius="5px"
              InputDivClass="w-[100%] mt-8"
              name="newRule"
              value={newRule}
              handleChange={handleNewRuleChange}
            />
            <p className="font-poppins font-[400] sm:text-[10px] md:text-[12px] text-[#818181] text-start">
              Request will be send to admin for approval.
            </p>
            <div className="w-full text-end">
              <PrimaryButton
                className={
                  "w-[40%] lg:w-[25%] h-[45px] rounded-[5px] text-[16px] font-[500] font-montserrat ms-2"
                }
                label="Submit"
                onClick={handleNewRuleSubmit}
              />
            </div>
          </>
        )}
        <div
          className="mt-5 flex items-center gap-2 cursor-pointer"
          onClick={handleAddClick}
        >
          <AddIcon />
          <p className="font-poppins font-[400] sm:text-[12px] md:text-[14px] text-[#00ADEF] text-center leading-[18px]">
            Suggest any Rules and Regulations.
          </p>
        </div>
        <div className="w-full text-end mt-20">
          <GreyButton
            className="w-[40%] lg:w-[25%] h-[45px] rounded-[5px] text-[16px] font-[500] font-montserrat"
            label="Previous"
            onClick={handlePrevious}
          />
          <PrimaryButton
            className={
              "w-[40%] lg:w-[25%] h-[45px] rounded-[5px] text-[16px] font-[500] font-montserrat ms-2"
            }
            label="Next"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
};

export default RulesRegulations;
