import React, { useState } from "react";
import DropZone from "../Dropzone";
import toast from "../../../utils/toast";
import addPhoto from "../../../Assets/Icons/imgIcon.svg";
import AddPhoto from "./AddPhoto";
import { uploadFile } from "../../../utils/s3";

const UploadDocument = ({
  thumbnail,
  name,
  setFieldValue,
  error,
  touched,
  iconText,
  parentDivClass,
  setS3Url,
  logoFileImage,
  setLogoFileImage,
}) => {
  const [logoFileData, setLogoFileData] = useState();
  const [loading, setLoading] = useState(false);

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  const validateImage = (data) => {
    var idxDot = data.lastIndexOf(".") + 1;
    var extFile = data.substr(idxDot, data.length).toLowerCase();
    if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
      return true;
    } else {
      return false;
    }
  };

  const changeHandler = async (files) => {
    let logoBase64 = "";
    let fileName = files[0].name;
    let checkValidImage = validateImage(fileName);

    setFieldValue(name, fileName);

    if (checkValidImage) {
      setLoading(true);
      const s3Url = await uploadFile(files[0], fileName);
      if (s3Url) {
        setS3Url(s3Url);
        setLoading(false);
      }
      let logoImageUrl = URL.createObjectURL(files[0]);
      setLogoFileImage(logoImageUrl);
      getBase64(files[0], (result) => {
        logoBase64 = result.split(",")[1];
        logoBase64 = /,(.+)/.exec(result)[1];
        setLogoFileData(logoBase64);
      });
    } else {
      toast.error("Only jpg/jpeg and png files are allowed!");
      handleRejectedFiles(files);
    }
  };

  const handleRejectedFiles = (files) => {
    files[0]?.errors?.forEach((error) => {
      toast.error(error.message);
    });
  };

  const clearLogoImage = () => {
    setFieldValue(name, null);
    setS3Url();
    setLogoFileData();
    setLogoFileImage();
  };
  return (
    <div
      className={`${parentDivClass} mx-auto rounded-[20px]`}
      style={{
        border: "2px dashed #00ADEF",
        boxShadow: "0px 4px 20px 10px #79797926",
      }}
    >
      {!logoFileImage ? (
        <DropZone
          acceptedFiles={changeHandler}
          rejectedFiles={handleRejectedFiles}
          maxLength={1}
          supportText={""}
          multiple={false}
          img={
            <AddPhoto
              divClassName="w-[200px] md:w-[250px] h-[111px] mb-4"
              icon={addPhoto}
              text={iconText}
              loading={loading}
            />
          }
          accept="image/png, image/jpeg"
        ></DropZone>
      ) : (
        <div className="w-full h-full mt-2 flex flex-col justify-center items-center">
          <img
            src={logoFileImage}
            className="w-[200px] md:w-[250px] h-[111px] rounded-[10px]"
          />
          <button
            type="button"
            className="my-5 shadow-none sm:w-[30%] xl:w-[10%] h-[40px] rounded-[5px] bg-[#00ADEF] text-[16px] font-[500] text-[#FFFFFF]"
            onClick={clearLogoImage}
          >
            Clear
          </button>
        </div>
      )}
      <div className="ms-1 mt-1">
        <span className="text-red-600 sm:text-[10px] md:text-[12px] font-[600] text-start">
          {error && touched ? error : null}
        </span>
      </div>
    </div>
  );
};

export default UploadDocument;
