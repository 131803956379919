import React, {useState} from 'react'
import './dashboard.scss'


const Frequency = ({frequencies, className}) => {
  const [selectedFrequency, setSelectedFrequency] = useState("Monthly");

  const handleFrequencyClick = (freq) => {
    setSelectedFrequency(freq);
  };

  return (
    <div className={className}>
      <div className='flex justify-between w-full'>
        {frequencies?.map((freq) => 
          <span className={`text-[12px] font-poppins font-[400] text-[black] cursor-pointer px-3 py-1 ${
            selectedFrequency === freq ? 'bg-white rounded-[5px]' : ''
          }`} onClick={() => handleFrequencyClick(freq)}>{freq}</span>
        )}
      </div>
    </div>
  )
}

export default Frequency
