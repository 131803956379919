import React from "react";
import { useNavigate } from "react-router-dom";
import FormBg from "../../../Assets/Images/Fundraiser/membershipActivation.svg";
import Profile from "../../../Assets/Images/Profile.svg";
import OTP from "../../common/OTP";
import PrimaryButton from "../../common/PrimaryButton";
import FundraiserHeader from "../common/FundraiserHeader";
import FundraiserBodyHeader from "../common/FundraiserBodyHeader";
import { useFormik } from "formik";
import { otpSchema } from "../../../utils/validationSchemas";
import { useDispatch, useSelector } from "react-redux";
import {
  forgetPassword,
  otpVerification,
} from "../../../services/authService.js";
import { setForgetPasswordData } from "../../../redux/slices/authSlice";

const OTPVerification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const forgetData = useSelector(
    (state) => state?.authReducer?.forgetPasswordData
  );

  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: otpSchema,
    onSubmit: async (values) => {
      try {
        const otpPromise = await dispatch(
          otpVerification({ otp: values?.otp, email: forgetData?.email })
        )?.unwrap();
        if (otpPromise?.success == true) {
          navigate("/user-resetPassword", {
            state: {
              email: forgetData?.email,
            },
          });
        }
      } catch (error) {
        console.log("---err---", error);
      }
      // Handle form submission
      console.log(values);
    },
  });

  const { otp } = formik.values;

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = formik;

  const onOtpChange = (otpValue) => {
    setFieldValue("otp", otpValue);
  };

  const handleResendClick = async () => {
    setFieldValue("otp", "");
    const forgetPromise = await dispatch(
      forgetPassword({ email: forgetData?.email })
    )?.unwrap();
    if (forgetPromise?.success === true) {
      dispatch(setForgetPasswordData({ email: forgetData?.email }));
    }
  };

  return (
    <form className="pb-24" onSubmit={handleSubmit}>
      <FundraiserHeader />

      <div className="flex flex-col items-center">
        <FundraiserBodyHeader
          heading={"OTP Verification"}
          content={"Enter the 6 digit numbers sent to your email."}
          contentClass={"w-[100%] sm:w-[80%] md:w-[90%] text-[#8C8C8C]"}
        />

        <div className="relative mt-12 sm:mt-24 mb-14">
          <img src={FormBg} className="w-[365px] sm:w-[380px]"></img>

          <div className="absolute top-2 sm:top-0 w-full mt-[-14%]">
            <div className="flex justify-center">
              <img src={Profile} className="w-[80px] sm:w-[104px]"></img>
            </div>

            <p className="text-[18px] md:text-[20px] font-[600] text-center text-[#00ADEF] font-poppins">
              OTP Verification
            </p>

            <div className="px-[6%] mt-5 sm:mt-8 md:mt-9">
              <OTP
                value={otp}
                handleChange={onOtpChange}
                inputStyle={{
                  border: "1.5px solid #8C8C8C",
                  borderRadius: "6px",
                  width: "50px",
                  height: "64px",
                  fontFamily: "Inter, sans-serif",
                  fontSize: "20px",
                  color: "#8C8C8C",
                  background: "transparent",
                  fontWeight: "400",
                  caretColor: "#8C8C8C",
                  outline: "none",
                }}
              />

              <p className="text-[#00ADEF] text-[14px] sm:text-[16px] font-[500] font-poppins mt-7 sm:mt-7 lg:mt-[7%] text-center">
                If you didn’t receive code,{" "}
                <span
                  className="underline font-[700] cursor-pointer"
                  onClick={handleResendClick}
                >
                  Resend
                </span>
              </p>

              <PrimaryButton
                type="submit"
                className={
                  "mt-7 sm:mt-7 w-[100%] text-[18px] font-[600] h-[50px] sm:h-[45px] md:h-[50px] rounded-[10px]"
                }
                label="Set New Password"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default OTPVerification;
