import React from "react";
import "./common.scss";
import { useNavigate } from "react-router-dom";

const NotificationPopup = ({ heading, content, image, setNotification }) => {
  const navigate = useNavigate();
  const handleNotificationClick = () => {
    setNotification(null);
    navigate("/merchant/offer-list");
  }

  return (
    <div className="notification-container cursor-pointer" onClick={handleNotificationClick}>
      <div className="relative flex flex-col justify-center gap-3 items-center w-[335px] sm:w-[400px] h-[125px] bg-white rounded-[10px] drop-shadow-[0_3px_3px_rgba(0,0,0,0.25)] popup">
        <img src={image} className="w-[40px] h-[40px] "/>
        <div className="flex flex-col justify-center items-center sm:gap-2">
          <p className="text-[16px] sm:text-[18px] font-[500] font-montserrat text-[#303030]">
            {heading}
          </p>
          <p className="text-[12px] sm:text-[14px] font-[400] font-montserrat text-[#8C8C8C]">
            {content}
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotificationPopup;
